import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { IssueProductActionModel, IssueProductModel, IssueProductViewModel, IssueRequestMultiBatchModel, IssueRequestSelectedBatchModel, SearchParamsProductIssueModel } from '../../Models/IssueModel';
import { ProductCategoryModel, ProductFirstSubCategoryModel, ProductSecSubCategoryModel, RackModel, StoreModel } from '../../Models/MasterModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Modules, Responsibility, ScannerMode } from '../../Models/Enums';
import { AuthService } from '../../Services/auth.service';
import { SaleOrderModel } from 'src/PmsUIApp/Models/SalesOrderModel';
import { LoadingService } from 'src/PmsUIApp/Services/loadingService';
import { MeasureUnitModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { ProductModel } from 'src/PmsUIApp/Models/ProductModel';
import { SupplierModel } from 'src/PmsUIApp/Models/SupplierModel';
import { UserModel } from 'src/PmsUIApp/Models/UserModel';
import { IssueSaleOrderProductsStockModel } from 'src/PmsUIApp/Models/StockProductModel';
import { SoDrawerService } from 'src/PmsUIApp/Services/SoDrawerService';
import { StockLabelModel, StockLabelResponseModel } from '../../Models/BarcodeLabelModel';
import { BarcodeScannerService } from '../../Features/BarcodeLabelManagement/services/BarcodeScannerService';
import { BarcodeLabelUpdateService } from '../../Features/BarcodeLabelManagement/services/BarcodeLabelUpdateService';
import { Subscription } from 'rxjs';

interface BatchSelection {
  SupplierId: number;
  SupplierName: string;
  BatchNo: string;
  StockProductId: number;
  StoreId: number;
  StoreName: string;
  RackId: number;
  RackName: string;
  AvailableQuantity: number;
  IssueQuantity: number;
}

interface IssueApprovalRequest {
  IssueId: number;
  Status: string;
  BatchAllocations: {
    BatchNo: string;
    StoreId: number;
    RackId: number;
    Quantity: number;
    SupplierId: number;
  }[];
}

@Component({
  selector: 'app-Issuelist',
  templateUrl: './Issuelist.component.html',
  styleUrls: ['./Issuelist.component.css']
})
export class IssueListComponent implements OnInit, OnDestroy {


  ApiUrl = environment.Api_Url;
  ProductionList: IssueProductModel[] = [];
  ProductionListView: IssueProductViewModel[] = [];
  ProductionListOriginal: IssueProductModel[] = [];
  IssuePrintList: any[] = [];
  Production: IssueProductActionModel = new IssueProductActionModel();
  isVisible = false;
  shouldShowApprovalModal: boolean = false;
  isSaleOrderViewVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  typeList: any[] = [];
  PopUpTitle: string = "Add New Issue";
  searchValue = '';
  myDateValue: Date = new Date(new Date(new Date().setHours(0, 0, 1)).setDate(new Date().getDate() - 7));
  toDate: Date = new Date(new Date().setHours(23, 59, 59));
  saleOrderNumber = '';
  isValidDate: any
  visible = false;
  RackList: RackModel[] = [];
  StoreRackList: RackModel[] = [];
  RackListOriginal: RackModel[] = [];
  fields: IssueProductViewModel = new IssueProductViewModel();
  totalItemsCount: number = 0;
  request: SearchParamsProductIssueModel = new SearchParamsProductIssueModel();
  exportoptions = {
    headers: [
      "Issue Serial No.",
      "Issue Slip No.",
      "Issue Type",
      "Product",
      "Sale Order No.",
      "Quantity",
      "Batch No.",
      "From Store",
      "To Store",
      "Remark",
      "Added Date",
      "Added By",
      "Action Date",
      "Action By",
      "Status"
    ]
  };
  exportfields: IssueProductViewModel[] = [];
  count: 0;
  permission = {
    View: false,
    Add: false
  }
  showPrintBtn: boolean;
  FilteredProductCategoryList: ProductCategoryModel[];
  FilteredProductFirstSubCategoryList: ProductFirstSubCategoryModel[];
  FilteredProductSecSubCategoryList: ProductSecSubCategoryModel[];
  ProductCategoryList: ProductCategoryModel[] = [];
  ProductFirstSubCategoryList: ProductFirstSubCategoryModel[];
  ProductSecSubCategoryList: ProductSecSubCategoryModel[];
  MeasureUnits: MeasureUnitModel[];
  ProductList: ProductModel[];
  SupplierList: SupplierModel[];
  ToStoreID: number = 0;
  FromStoreID: number = 0;
  StoreList: StoreModel[];
  CategoryID: number = 0;
  FirstCategoryID: number = 0;
  SecondCategoryID: number = 0;
  SelectedProductType: string = '';
  SelectedProductId: number = 0;
  SelectedUnit: string = '';
  FilteredProductList: ProductModel[];
  UserList: UserModel[];
  IsMsgShow: boolean = false;
  filterPanelTxt: string = 'Show Filters'
  IsAdminActionEnabled: boolean = false;

  FilteredSupplierList: IssueSaleOrderProductsStockModel[] = [];
  UniqueSupplierList: SupplierModel[] = [];
  FilteredBatchList: IssueSaleOrderProductsStockModel[] = [];
  FilteredRackList: IssueSaleOrderProductsStockModel[] = [];
  FilteredBarCodeList: IssueSaleOrderProductsStockModel[] = [];
  supplierList: IssueSaleOrderProductsStockModel[] = [];
  SelectedStockItem: IssueSaleOrderProductsStockModel;
  SelectedRequest: IssueProductModel;

  SelectedSupplier = 0;
  SelectedBatch: string = '';
  SelectedBarcode: string = '';
  availableQuantity: number = 0;
  SelectedRackId: number = 0;

  IsBatch = true;
  IsBarcode = true;
  IsStore = true;
  IsRack = true;
  isIssueQuantity = true;

  BatchList: string[] = [];
  BarcodeList: string[] = [];
  RequestType: string = '';

  approvalMode: 'Manual' | 'Manual - Multi Batch' | 'Scan' = 'Manual';
  scannedLabels: StockLabelResponseModel[] = [];
  scannedLabelsVisible: boolean = false;
  isApproveButtonVisible: boolean = false;
  private subscriptions: Subscription = new Subscription();
  ShowScannedBtntext: string = 'Show Scanned Labels';
  TotalScannedQty: number = 0;
  HasStockLabel: boolean = false;

  selectedBatches: IssueRequestSelectedBatchModel[] = [];
  totalIssueQuantity: number = 0;

  isBatchSelectionVisible: boolean = false;
  tempBatchSelection: IssueRequestSelectedBatchModel = {
    SupplierId: null,
    SupplierName: '',
    BatchNo: '',
    StockProductId: 0,
    StockId: 0,
    StoreId: null,
    StoreName: '',
    RackId: null,
    RackName: '',
    AvailableQuantity: 0,
    IssueQuantity: 0
  };
  filteredBatchList: string[] = [];
  filteredRackList: any[] = [];
  RawDetails: IssueSaleOrderProductsStockModel[] = [];

  constructor(private fb: UntypedFormBuilder, public http: HttpClient,
    private alertService: AlertMessageService, private modalService: NzModalService, private auth: AuthService, private router: Router, private loader: LoadingService,
    private soDrawer: SoDrawerService, private barcodeScannerService: BarcodeScannerService, private barcodeLabelUpdateService: BarcodeLabelUpdateService,
    private cdr: ChangeDetectorRef
  ) { }


  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.Issue, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.Issue, Responsibility.Add);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }
    const existingFilters = sessionStorage.getItem('issuerequestfilters');
    if (existingFilters) {
      this.request = JSON.parse(existingFilters);
    }
    this.IsAdminActionEnabled = UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase().includes('super admin')).length > 0 || UserInfo.UserRolesMaster.filter(x => x.UserRoleName.toLowerCase().includes('admin')).length > 0 ? true : false;
    this.GetAllProduction();
    this.GetAllRack();
    this.subscriptions.add(
      this.barcodeScannerService.scannerState$.subscribe(isOpen => {
        if (!isOpen && this.shouldShowApprovalModal) {
          setTimeout(() => {
            this.isVisible = true;
          }, 100);
        }
        var stockLabelIds = this.barcodeLabelUpdateService.getStockLabelIds();
        if (stockLabelIds.length > 0) {
          this.Production.StockLabelIds = stockLabelIds;
          console.log(`stockLabelIds`, stockLabelIds)
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onFilterChange() {
    sessionStorage.setItem('issuerequestfilters', JSON.stringify(this.request));
  }

  GetAllRack() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "rack/getallracks";
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.RackList = res;
      this.RackListOriginal = res;
      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllRack();
      }
    });
  }
  GetRackByStoreId(StoreId: number) {
    this.loader.show();
    let url = this.ApiUrl + "rack/getallracksbystoreid?storeid=" + StoreId;
    this.http.get<RackModel[]>(url).subscribe(res => {
      this.StoreRackList = res;
      this.loader.hide();
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetRackByStoreId(StoreId);
      }
    });
  }
  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  GetAllProduction() {
    var filterData = {
      DateFrom: this.myDateValue,
      DateTo: this.toDate,
      SaleOrderNumber: this.saleOrderNumber,
      Status: ''
    }
    this.request.ProductType = this.SelectedProductType ?? '';
    this.request.ProductCategoryId = this.CategoryID ?? 0;
    this.request.ProductFirstSubCategoryId = this.FirstCategoryID ?? 0;
    this.request.ProductSecSubCategoryId = this.SecondCategoryID ?? 0;
    this.request.ProductId = this.SelectedProductId ?? 0;
    this.request.Unit = this.SelectedUnit ?? '';
    this.request.ToStoreId = this.ToStoreID ?? 0;
    this.request.FromStoreId = this.FromStoreID ?? 0;
    this.ProductionList = [];
    this.isTableLoading = true;
    let count = this.totalItemsCount = 0;
    let url = this.ApiUrl + "issueproduct/GetIssueProductRequests";
    this.http.post<IssueProductModel[]>(url, this.request).subscribe(res => {
      console.log(`res`, res)
      this.ProductionList = res;
      this.ProductionListOriginal = res;
      this.ProductionListView = res
      this.isTableLoading = false;
      this.exportfields = [];
      this.ProductionListView.forEach((x) => {
        count++;
        this.fields = new IssueProductViewModel()
        this.fields.IssueId = x.IssueId
        this.fields.IssueSlipNumber = x.IssueSlipNumber
        this.fields.IssueType = x.SaleOrderNumber == null ? "Store" : "WorkPlan"
        this.fields.ProductName = x.ProductName
        this.fields.SaleOrderNumber = x.SaleOrderNumber == null ? "" : x.SaleOrderNumber
        this.fields.Quantity = x.Quantity
        this.fields.Batch = x.Batch
        this.fields.FromStoreName = x.FromStoreName
        this.fields.ToStoreName = x.ToStoreName
        this.fields.Remark = x.Remark == null ? "" : x.Remark
        this.fields.CreatedDate = x.CreatedDate
        this.fields.CreatedBy = x.CreatedBy
        this.fields.ActionDate = x.ActionDate == null ? "" : x.ActionDate
        this.fields.ActionBy = x.ActionBy == null ? "" : x.ActionBy
        this.fields.Status = x.Status
        this.exportfields.push(this.fields);
      })
      this.totalItemsCount = count;
      this.ProductionList.forEach((x) => {
        x.IsDisabled = x.IssueSlipId == null ? false : true;
      })


    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllProduction();
      }
    });
  }
  OpenViewPop(data: IssueProductModel) {
    this.approvalMode = 'Manual';
    this.scannedLabels = [];
    this.shouldShowApprovalModal = true;
    this.barcodeScannerService.setCurrentProductId(data.ProductId);
    this.barcodeScannerService.setCurrentFromStoreId(data.FromStore);
    this.SetApproveButtonVisibility();
    // this.isVisible = true;
    // this.Production.IssueId = data.IssueId;
    // this.Production.IssueQuantity = data.DemandQuantity;
    // this.Production.DemandQuantity = data.DemandQuantity;
    // this.RackList = this.RackListOriginal.filter(x => x.StoreId == data.ToStore)
    // this.Production.RackId = this.RackList[0].RackId;
    //this.Production = data;
    this.GetAllSupplier(data);
    // this.getStockProductLabelByStore(data);
  }
  GetAllSupplier(data: IssueProductModel) {
    this.loader.show();
    let url = this.ApiUrl + "supplier/getallsuppliers";
    this.http.get<SupplierModel[]>(url).subscribe(res => {
      this.SupplierList = res;
      this.GetAllDept(data)
    }, res => {
      this.loader.hide()
      this.count++;
      if (this.count < 2) {
        this.GetAllSupplier(data);
      }
    });
  }

  GetAllDept(data: IssueProductModel) {
    this.loader.show();
    // this.Production.FromRackId = data.FromRack;
    this.Production.IssueId = data.IssueId;
    this.Production.ProductId = data.ProductId;
    this.RequestType = data.RequestType;
    if (this.RequestType !== "Simple") {
      this.isIssueQuantity = false
    }
    this.Production.IssueQuantity = this.approvalMode == 'Manual' ? data.DemandQuantity : 0;
    this.Production.DemandQuantity = data.DemandQuantity;
    if (this.RequestType == 'Simple') {
      this.RackList = this.RackListOriginal.filter(
        (x) => x.StoreId == data.ToStore
      );
      // this.Production.RackId = this.RackList[0].RackId;
      let url =
        this.ApiUrl +
        `stock/getproductstockbystoreidbyproductid/?storeid=${data.FromStore}&productid=${data.ProductId}`;
      this.http.get<IssueSaleOrderProductsStockModel[]>(url).subscribe(
        (res) => {
          this.isVisible = true;
          this.supplierList = res
          this.RawDetails = res;
          let uniqueSupplier = [...new Set(this.supplierList.map(item => item.SupplierId))];
          this.UniqueSupplierList = this.SupplierList.filter(x => uniqueSupplier.includes(x.SupplierId));
          this.GetRackByStoreId(data.FromStore);
          this.loader.hide();
        },
        (res) => {
          this.loader.hide();
        }
      );
    }
    else {
      this.isVisible = true;
      this.loader.hide();
    }
  }

  OnSupplierDdlchange() {
    this.availableQuantity = 0;
    this.FilteredSupplierList = this.supplierList.filter(x => x.SupplierId == this.SelectedSupplier);
    this.BatchList = this.FilteredSupplierList.filter(x => x.SupplierId == this.SelectedSupplier).map(x => x.Batch);
    this.BatchList = [...new Set(this.BatchList.map(item => item))];

    this.SelectedBatch = '';
    this.SelectedRackId = 0;
    this.SelectedBarcode = '';
    this.IsBatch = false;
    // this.IsQuantity = true;
  }
  OnBatchDdlchange() {
    this.availableQuantity = 0;
    this.FilteredBatchList = this.FilteredSupplierList.filter(x => x.Batch == this.SelectedBatch);
    let isBarcodeLabelExist = false;
    this.FilteredBatchList.forEach((x) => {
      if (x.IsBarcodeLabelExist == true) {
        isBarcodeLabelExist = true;
      }
    })
    if (isBarcodeLabelExist) {
      this.IsRack = true;
      this.IsBarcode = true;
      this.SelectedBatch = '';
      this.SelectedRackId = 0;
      this.SelectedBarcode = '';
      this.approvalMode = 'Scan';
      this.handleBatchSelectionCancel();
      this.alertService.error("Barcode label already exist for selected batch product. Only through barcode scan is allowed.")
      return;
    }
    else {
      var rackList = this.FilteredBatchList.filter(x => x.Batch == this.SelectedBatch).map(x => x.RackId);
      rackList = [...new Set(rackList.map(item => item))];
      this.StoreRackList = this.StoreRackList.filter(x => rackList.includes(x.RackId));
      this.SelectedBarcode = '';
      this.SelectedRackId = 0;
      this.IsRack = false;
    }
  }

  OnRackDdlchange() {
    this.availableQuantity = 0;
    this.FilteredRackList = this.FilteredSupplierList.filter(x => x.RackId == this.SelectedRackId);
    this.BarcodeList = this.FilteredRackList.filter(x => x.RackId == this.SelectedRackId).map(x => x.Barcode);
    this.BarcodeList = [...new Set(this.BarcodeList.map(item => item))];
    this.SelectedBarcode = '';
    this.IsBarcode = false;
  }

  OnBarCodeDdlchange() {
    this.availableQuantity = 0;
    const FilteredBarCodeList = this.FilteredSupplierList.filter(x => x.SupplierId == this.SelectedSupplier && x.Batch == this.SelectedBatch && x.Barcode == this.SelectedBarcode && x.RackId == this.SelectedRackId);
    this.availableQuantity = FilteredBarCodeList[0].Quantity;
    this.SelectedStockItem = FilteredBarCodeList[0];
    this.isIssueQuantity = false;
  }

  handleOk(): void {

    //this.isLoading = true;
    //this.Save();

  }
  //calculateTotal() {
  //  return this.Production.ProductionProduct.reduce((accum, curr) => accum + curr.Amount, 0);
  //}
  handleCancel(): void {
    this.shouldShowApprovalModal = false;
    this.isVisible = false;
    this.resetThePopup();
    this.barcodeScannerService.resetValues();
  }
  reset(): void {
    this.searchValue = '';
    this.isLoading = false
    this.search();
    this.GetAllProduction();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }

  resetThePopup() {
    this.FilteredSupplierList = [];
    this.FilteredBatchList = [];
    this.FilteredBarCodeList = [];
    this.supplierList = [];

    this.SelectedSupplier = 0;
    this.SelectedBatch = '';
    this.SelectedBarcode = '';
    this.availableQuantity = 0;
    this.SelectedStockItem = null;

    this.IsBatch = true;
    this.IsBarcode = true;
    this.IsStore = true;
    this.IsRack = true;
    this.isIssueQuantity = true;

    this.BatchList = [];
    this.BarcodeList = [];
    this.selectedBatches = [];
  }



  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.ProductionListOriginal;
    var res2 = this.ProductionListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: IssueProductModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.ActionDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.ActionDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.ProductionList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.ProductionList = res.filter(
        (item: IssueProductModel) =>
          item?.ProductName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item.Quantity == +this.searchValue ||
          item?.FromStoreName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Status?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ToStoreName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.ActionBy?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.Remark?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }






  OnSubmit(type: string) {
    this.isLoading = true;
    if (type == 'Approved') {
      if (this.Production.IssueQuantity > this.availableQuantity && this.RequestType == 'Simple' && this.approvalMode == 'Manual') {
        this.alertService.error("QTY cannot be more than " + this.availableQuantity);
        return
      }

      if (this.RequestType == 'Simple' && this.approvalMode == 'Manual') {
        this.Production.FromRackId = this.SelectedRackId;
        this.Production.StockId = this.SelectedStockItem.StockId;
        this.Production.StockProductId = this.SelectedStockItem.StockProductId;
      }
      if (this.approvalMode === 'Manual - Multi Batch') {
        this.Production.IsMultiBatch = true;
        this.Production.SelectedBatches = this.selectedBatches.map(batch => ({
          BatchNo: batch.BatchNo,
          StoreId: batch.StoreId,
          RackId: batch.RackId,
          Quantity: batch.IssueQuantity,
          SupplierId: batch.SupplierId,
          StockProductId: batch.StockProductId,
          StockId: batch.StockId
        }))
      }
      if (this.approvalMode === 'Scan') {
        this.Production.StockLabelIds = this.scannedLabels.map(label => label.StockLabelId);
        // Validate scanned quantity matches requested quantity
        // const scannedQuantity = this.scannedLabels.reduce((sum, label) => sum + label.StockLabel.Quantity, 0);
        // if (scannedQuantity !== this.Production.IssueQuantity) {
        //   this.alertService.error('Scanned quantity does not match the requested quantity.');
        //   return;
        // }
      }
    }
    this.Production.ApprovalMode = this.approvalMode == 'Scan' ? 'Barcode' : this.approvalMode;
    this.Production.Status = type;
    this.Production.ActionBy = UserInfo.EmailID;
    //this.Production.ActionDate = "08/03/2022"//new Date().toString();
    var requestdata: IssueProductActionModel[] = [];
    requestdata.push(this.Production);
    let url = this.ApiUrl + "issueproduct/actionissueproductrequest";
    this.http.post<any>(url, this.Production).subscribe({
      next: res => { this.alertService.success(res); this.isLoading = false; this.handleCancel(); this.GetAllProduction() },
      error: res => {
        console.log(res.error);
        this.alertService.error(res.error); this.isLoading = false;
      },
    });

  }

  // OnSubmitNew(status: string) {
  //   if (this.approvalMode === 'Manual') {
  //     if (this.totalIssueQuantity !== this.Production.DemandQuantity) {
  //       this.alertService.error("Total issue quantity must match demand quantity");
  //       return;
  //     }

  //     const request: IssueApprovalRequest = {
  //       IssueId: this.Production.IssueId,
  //       Status: status,
  //       BatchAllocations: this.selectedBatches.map(batch => ({
  //         BatchNo: batch.BatchNo,
  //         StoreId: batch.StoreId,
  //         RackId: batch.RackId,
  //         Quantity: batch.IssueQuantity,
  //         SupplierId: batch.SupplierId,
  //         StockProductId: batch.StockProductId
  //       }))
  //     };

  //     // Make API call with the new request format
  //     this.http.post(`${this.ApiUrl}issue/approve`, request)
  //       .subscribe(
  //         response => {
  //           this.alertService.success("Issue request processed successfully");
  //           this.handleCancel();
  //           this.GetAllProduction();
  //         },
  //         error => {
  //           this.alertService.error("Error processing issue request");
  //         }
  //       );
  //   }
  //   // ... rest of your existing OnSubmit logic for other modes
  // }
  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        'issue-export' + exportdate,
        this.exportoptions
      );


  }
  onUp(key: any, data: any) {
    data = data.sort((a: any, b: any) =>
      a[key] > b[key] ? 1 : -1
    );
  }
  onDown(key: any, data: any) {
    data = data.sort((a: any, b: any) =>
      a[key] < b[key] ? 1 : -1
    );
  }
  OpenSaleOrderViewPop(data: any) {
    this.soDrawer.SaleOrderId = data.SaleOrderId;
    this.soDrawer.show();
  }
  handleSaleOrderCancel(): void {
    this.isSaleOrderViewVisible = false;
  }
  add() {
    var selectedIssueRequest = this.ProductionList.filter(item => item.IsChecked).map(
      finalResult => {
        return {
          IssueId: finalResult.IssueId
        };
      });
    this.IssuePrintList = selectedIssueRequest;

    if (this.IssuePrintList.length > 0) {
      this.showPrintBtn = true;

      // Find the 'ToStore' value of the first selected item
      const toStoreValue = this.ProductionList.find(item => item.IsChecked)?.ToStore;

      // Disable all items that don't have the same 'ToStore' value
      this.ProductionList.forEach(item => {
        if (item.ToStore !== toStoreValue) {
          item.IsDisabled = true;
        }
      });

    } else {
      this.showPrintBtn = false;
      // If no items are selected, ensure all items are enabled
      this.ProductionList.forEach((item) => {
        if (item.IssueSlipId == null) {
          item.IsDisabled = false
        }
      });
    }
  }
  GenerateAndPrintIssueSlip(): void {
    this.loader.show();

    let url = this.ApiUrl + 'issueproduct/addissuesliprequest';
    this.http.post<any>(url, this.IssuePrintList).subscribe({
      next: (res) => {
        this.PrintSlip(res);
        this.isLoading = false;
        this.showPrintBtn = false;
        this.GetAllProduction();
        this.loader.hide();
      },
      error: (res) => {
        this.alertService.error(res.error);
        this.isLoading = false;
        this.GetAllProduction();
        this.loader.hide();
      },
    });
  }
  PrintSlip(IssueSlipId: number) {
    window.open(`${window.location.origin}/issueslipprint/` + IssueSlipId);
  }
  onFilterPanelOpen(data: any) {
    if (data == true) {
      this.loader.show();
      this.GetAllUnits();
      this.GetAllProducts();
      this.GetAllProductCategory();
      this.GetAllStore();
      this.GetAllUsers();
      this.filterPanelTxt = 'Hide Filters';
    }
    else {
      this.filterPanelTxt = 'Show Filters';
    }
  }
  GetAllUnits() {
    this.loader.show();
    let url = this.ApiUrl + "data/GetMeasureUnits";
    this.http.get<MeasureUnitModel[]>(url).subscribe(res => {
      this.MeasureUnits = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetAllUnits()
      }
    });
  }
  GetAllProducts() {
    this.loader.show();
    let url = this.ApiUrl + "product/getallproducts";
    this.http.get<ProductModel[]>(url).subscribe(res => {
      this.ProductList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) { this.GetAllProducts() }
    });
  }

  GetAllProductCategory() {
    this.loader.show();
    let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
    this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
      this.ProductCategoryList = res;
      this.loader.hide();
    }, res => {
      this.loader.hide();
      this.count++
      if (this.count < 2) {
        this.GetAllProductCategory()
      }
    });
  }
  onSelectedProductTypeChange() {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType);
    this.loader.hide();
  }

  GetAllFirstCategory(data: any, id: number = 0) {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductCategoryId == this.CategoryID);

    let url = this.ApiUrl + "productcategory/getallproductfirstsubcategories";
    this.http.get<ProductFirstSubCategoryModel[]>(url).subscribe(res => {
      this.ProductFirstSubCategoryList = res.filter(x => x.ProductCategoryId == data);
      this.loader.hide();
    }, res => { });
  }
  GetAllSecondCategory(data: any, id: number = 0) {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductFirstSubCategoryId == this.FirstCategoryID);

    let url = this.ApiUrl + "productcategory/getallproductsecsubcategories";
    this.http.get<ProductSecSubCategoryModel[]>(url).subscribe(res => {
      this.ProductSecSubCategoryList = res.filter(x => x.ProductFirstSubCategoryId == data);
      this.loader.hide();
    }, res => { });
  }
  GetSecondCategoryFilteredProduct() {
    this.loader.show();
    this.FilteredProductList = this.ProductList.filter(x => x.ProductType == this.SelectedProductType && x.ProductSecSubCategoryId == this.SecondCategoryID);
    this.loader.hide();
  }
  GetAllStore() {
    this.loader.show();
    this.isTableLoading = true;
    let url = this.ApiUrl + 'store/getallstores';
    this.http.get<StoreModel[]>(url).subscribe(
      (res) => {
        this.StoreList = res;
        this.isTableLoading = false;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.GetAllStore();
        }
      }
    );
  }
  GetAllUsers() {
    this.loader.show();
    let url = this.ApiUrl + 'userrolesresponsibility/getalluserdata';
    this.http.get<UserModel[]>(url).subscribe(
      (res) => {
        this.UserList = res;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.count++
        if (this.count < 2) {
          this.GetAllUsers();
        }
      }
    );
  }
  space(el: any) {
    if (el.target.selectionStart === 0 && el.code === "Space") {
      el.preventDefault();
    }
  }
  enableIssueSelect(data: IssueProductModel) {
    if (data.Status == 'Pending' && (data.CreatedBy == UserInfo.EmailID || this.IsAdminActionEnabled))
      return true;
    else
      return false;
  }

  onApprovalModeChange() {
    this.isIssueQuantity = true;
    this.SetApproveButtonVisibility();
    if (this.approvalMode === 'Scan') {
      this.Production.IssueQuantity = 0;
    }
  }

  openBarcodeScanner() {
    this.isVisible = false;
    this.shouldShowApprovalModal = true;
    this.isApproveButtonVisible = false;
    this.barcodeScannerService.setScannerMode(ScannerMode.OverlayScanner, 'IssueRequestScan');
  }
  openExternalDeviceInput() {
    this.barcodeScannerService.setScannerMode(ScannerMode.ExternalDevice, 'IssueRequestScan');
    this.isApproveButtonVisible = false;
  }

  openManualInput() {
    this.barcodeScannerService.setScannerMode(ScannerMode.ManualEntry, 'IssueRequestScan');
    this.isApproveButtonVisible = false;
  }

  showScannedLabels() {
    this.ShowScannedBtntext = 'Refresh Scanned Labels';
    this.scannedLabels = this.barcodeScannerService.getScannedLabels();
    const stockLabelIds = [...new Set(this.scannedLabels.map(label => label.StockLabelId))];
    this.Production.StockLabelIds = stockLabelIds;
    this.Production.StockProductId = this.scannedLabels[0]?.StockProductId;
    // this.barcodeLabelUpdateService.openModal('', 'IssueRequestScan');
    this.barcodeLabelUpdateService.setStockLabelIds(stockLabelIds);
    this.TotalScannedQty = this.scannedLabels.reduce((sum, label) => sum + label.Quantity, 0);
    this.Production.IssueQuantity = this.TotalScannedQty;
    this.scannedLabelsVisible = true;
    this.SetApproveButtonVisibility();
  }
  SetApproveButtonVisibility() {
    this.isApproveButtonVisible = false;
    if (this.approvalMode == 'Scan' && this.scannedLabels.length > 0 && this.scannedLabels.length == this.Production.StockLabelIds.length) {
      this.isApproveButtonVisible = true;
    }
    else if (this.approvalMode == 'Manual') {
      this.isApproveButtonVisible = true;
    }
    else if (this.approvalMode == 'Manual - Multi Batch') {
      this.isApproveButtonVisible = true;
    }
    else {
      this.isApproveButtonVisible = false;
    }
  }
  getStockProductLabelByStore(data: IssueProductModel) {
    this.loader.show();
    const StoreBarcodeRequest = {
      ProductId: data.ProductId,
      CurrentStoreId: data.FromStore
    } as StockLabelModel;
    let url = this.ApiUrl + "stock/getstocklabelsbyproductstock";
    this.http.post(url, StoreBarcodeRequest).subscribe({
      next: (res: any) => {
        this.HasStockLabel = res.filter(x => x.Quantity > 0).length > 0;
        if (this.HasStockLabel) {
          this.approvalMode = 'Scan';
          // this.isVisible = true;
        }
        // This will be enabled once all products have barcode in factory.
        // else {
        //   this.GetAllSupplier(data);
        // }
        this.loader.hide();
      }, error: (err: any) => {
        this.loader.hide();
      }
    })
  }

  showBatchSelectionModal() {
    this.resetTempBatchSelection();
    this.isBatchSelectionVisible = true;
  }

  handleBatchSelectionCancel() {
    this.isBatchSelectionVisible = false;
    this.resetTempBatchSelection();
  }

  resetTempBatchSelection() {
    this.tempBatchSelection = {
      SupplierId: null,
      SupplierName: '',
      BatchNo: '',
      StockProductId: 0,
      StockId: 0,
      StoreId: null,
      StoreName: '',
      RackId: null,
      RackName: '',
      AvailableQuantity: 0,
      IssueQuantity: 0
    };
    this.filteredBatchList = [];
    this.filteredRackList = [];
  }

  onTempSupplierChange(supplierId: number) {
    try {
      if (!supplierId) {
        this.filteredBatchList = [];
        return;
      }

      const supplier = this.UniqueSupplierList.find(s => s.SupplierId === supplierId);
      this.tempBatchSelection.SupplierName = supplier?.SupplierName || '';

      // Get unique batches from RawDetails for this supplier and product
      this.filteredBatchList = [...new Set(
        this.RawDetails
          .filter(raw =>
            raw.SupplierId === supplierId &&
            raw.ProductId === this.Production.ProductId &&
            raw.Quantity > 0
          )
          .map(raw => raw.Batch)
      )].filter(batch => !!batch); // Remove any null/empty batches

      console.log('Filtered Batch List:', this.filteredBatchList); // For debugging

      // Reset other selections
      this.tempBatchSelection.BatchNo = '';
      this.tempBatchSelection.RackId = null;
      this.tempBatchSelection.AvailableQuantity = 0;
    } catch (error) {
      console.error('Error in onTempSupplierChange:', error);
      this.alertService.error('Error filtering batch list');
    }
  }

  onTempBatchChange(batchNo: string) {
    if (!batchNo) return;
    let isBarcodeLabelExist = false;
    this.RawDetails.forEach((x) => {
      if (x.Batch == batchNo && x.IsBarcodeLabelExist == true) {
        isBarcodeLabelExist = true;
      }
    })
    if (isBarcodeLabelExist) {
      this.IsRack = true;
      this.IsBarcode = true;
      this.SelectedBatch = '';
      this.SelectedRackId = 0;
      this.SelectedBarcode = '';
      this.approvalMode = 'Scan';
      this.alertService.error("Barcode label already exist for selected batch product. Only through barcode scan is allowed.")
      return;
    }
    else {
      // Filter rack list based on supplier and batch
      this.filteredRackList = this.StoreRackList.filter(rack =>
        this.RawDetails.some(raw =>
          raw.SupplierId === this.tempBatchSelection.SupplierId &&
          raw.Batch === batchNo &&
          raw.RackId === rack.RackId
        )
      );

      // Reset rack selection
      this.tempBatchSelection.RackId = null;
      this.tempBatchSelection.AvailableQuantity = 0;
    }
  }

  onTempRackChange(rackId: number) {
    if (!rackId) return;

    const rack = this.StoreRackList.find(r => r.RackId === rackId);
    if (rack) {
      this.tempBatchSelection.StoreName = rack.StoreName;
      this.tempBatchSelection.RackName = rack.RackName;
      this.tempBatchSelection.StoreId = rack.StoreId;
    }

    // Calculate available quantity
    const availableStock = this.RawDetails.find(raw =>
      raw.SupplierId === this.tempBatchSelection.SupplierId &&
      raw.Batch === this.tempBatchSelection.BatchNo &&
      raw.RackId === rackId
    );

    this.tempBatchSelection.AvailableQuantity = availableStock?.Quantity || 0;
    this.tempBatchSelection.StockProductId = availableStock?.StockProductId || 0;
    this.tempBatchSelection.StockId = availableStock?.StockId || 0;
    this.tempBatchSelection.IssueQuantity = 0;
  }

  isValidBatchSelection(): boolean {
    return !!(
      this.tempBatchSelection.SupplierId &&
      this.tempBatchSelection.BatchNo &&
      this.tempBatchSelection.RackId &&
      this.tempBatchSelection.IssueQuantity > 0 &&
      this.tempBatchSelection.IssueQuantity <= this.tempBatchSelection.AvailableQuantity
    );
  }

  addBatchSelection() {
    try {
      if (!this.isValidBatchSelection()) return;
      // Check if batch already exists
      const existingIndex = this.selectedBatches.findIndex(b =>
        b.SupplierId === this.tempBatchSelection.SupplierId &&
        b.BatchNo === this.tempBatchSelection.BatchNo &&
        b.RackId === this.tempBatchSelection.RackId
      );

      if (existingIndex >= 0) {
        this.alertService.error('This batch and rack combination is already selected');
        return;
      }

      // Create a new array to trigger change detection
      this.selectedBatches = [...this.selectedBatches, { ...this.tempBatchSelection }];
      console.log('Selected Batches:', this.selectedBatches);
      this.calculateTotalIssueQuantity();

      // Close modal and reset
      this.handleBatchSelectionCancel();

      // Force change detection
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Error in addBatchSelection:', error);
      this.alertService.error('Error adding batch selection');
    }
  }

  removeBatch(batch: BatchSelection) {
    try {
      // Create new array without the removed batch
      this.selectedBatches = this.selectedBatches.filter(b =>
        !(b.SupplierId === batch.SupplierId &&
          b.BatchNo === batch.BatchNo &&
          b.RackId === batch.RackId &&
          b.StockProductId === batch.StockProductId)
      );

      this.calculateTotalIssueQuantity();

      // Force change detection
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Error in removeBatch:', error);
      this.alertService.error('Error removing batch');
    }
  }

  calculateTotalIssueQuantity() {
    try {
      this.totalIssueQuantity = this.selectedBatches.reduce((sum, batch) =>
        sum + (Number(batch.IssueQuantity) || 0), 0);
      this.Production.IssueQuantity = this.totalIssueQuantity;

      // Force change detection
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Error in calculateTotalIssueQuantity:', error);
      this.alertService.error('Error calculating total quantity');
    }
  }
}
