import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import moment from "moment";
import { NzModalService } from "ng-zorro-antd/modal";
import { Modules, Responsibility } from "src/PmsUIApp/Models/Enums";
import {
  AddStageModal,
  EditStageModal,
  NotificationStageModelExport,
  StagesTableModel,
  WhatsAppTemplateList,
} from "src/PmsUIApp/Models/Notification";
import { AlertMessageService } from "src/PmsUIApp/Services/AlertMessageService";
import { AuthService } from "src/PmsUIApp/Services/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
})
export class NotificationComponent implements OnInit {
  WhatsAppTemplateList: WhatsAppTemplateList[];
  constructor(
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private auth: AuthService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) { }

  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false,
  };

  ApiUrl = environment.Api_Url;

  PopUpTitle: string = "Add New Product";
  searchValue = "";
  IsSearching = false;
  isTableLoading: boolean = false;
  isVisible = false;
  isLoading: boolean = false;
  isShownEdit: boolean = false;
  NotificationTitle: string = "";

  NotificationList: StagesTableModel[] = [];
  NotificationListOriginal: StagesTableModel[] = [];
  NotificationListExport: NotificationStageModelExport[] = [];
  exportfields: NotificationStageModelExport[] = [];
  exportoptions = {
    headers: [
      "Stage Name",
      "Only Internal",
      "Only Customer",
      "Added By",
      "Added Date",
    ],
  };

  NewNotificationStage: AddStageModal = new AddStageModal();

  fields: NotificationStageModelExport = new NotificationStageModelExport();
  count: number;

  OrderStages: any[] = [
    { id: 1, name: "Order Received", value: "OrderReceived" },
    { id: 2, name: "In Production Planning", value: "InProductionPlanning" },
    { id: 3, name: "Removed Planning", value: "RemovedPlanning" },
    { id: 4, name: "Starting Production", value: "StartingProduction" },
    { id: 5, name: "Production Completed", value: "ProductionCompleted" },
    {
      id: 6,
      name: "Final Inspection Completed",
      value: "FinalInspectionCompleted",
    },
    { id: 7, name: "Ready To Dispacth", value: "ReadyToDispacth" },
    { id: 8, name: "Order Dispatched", value: "OrderDispatched" },
  ];
  selectedOrderStage: "";
  isOnlyInternal: false;
  isOnlyCustomer: false;

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(
      Modules.NotificationStages,
      Responsibility.View
    );
    this.permission.Add = this.auth.CheckResponsibility(
      Modules.NotificationStages,
      Responsibility.Add
    );
    this.permission.Edit = this.auth.CheckResponsibility(
      Modules.NotificationStages,
      Responsibility.Edit
    );
    this.permission.Delete = this.auth.CheckResponsibility(
      Modules.NotificationStages,
      Responsibility.Delete
    );

    if (this.permission.View != true) {
      this.router.navigate(["/home/unauthorized"]);
    }
    this.GetAllNotifications();
  }

  GetAllNotifications() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "notificationconfig/getstages";

    this.http.get<StagesTableModel[]>(url).subscribe(
      (res) => {
        res.forEach((item, index) => {
          item.SerialNumber = index + 1;
        });

        this.NotificationList = res;

        this.NotificationListOriginal = res;
        this.isTableLoading = false;
        this.NotificationListExport = res;
        this.exportfields = [];

        this.NotificationListExport.forEach((x) => {
          this.fields = new NotificationStageModelExport();

          this.fields.AddedBy = x.AddedBy;
          this.fields.AddedDate = x.AddedDate;
          this.fields.OnlyCustomer = x.OnlyCustomer;
          this.fields.OnlyInternal = x.OnlyInternal;
          this.fields.SaleOrderStages = x.SaleOrderStages;

          this.exportfields.push(this.fields);
        });
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllNotifications();
        }
      }
    );
  }

  onKeydown(event: any) {
    if (
      (event.target.selectionStart === 0 && event.code === "Space") ||
      (event.key === "Enter" && event.keyCode === 13)
    ) {
      event.preventDefault();
      event = this.search();
    }
  }
  ValidateText() {
    this.searchValue.trim();
    this.searchValue = this.searchValue.trim();
  }

  search() {
    var res = this.NotificationListOriginal;
    this.NotificationList = res.filter((item: StagesTableModel) => {
      if (
        item?.SaleOrderStages?.toLowerCase().includes(
          this.searchValue?.toLowerCase()
        )
      ) {
        return true;
      } else if (
        item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  }

  reset(): void {
    this.searchValue = "";
    this.search();
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        "notification-export" + exportdate,
        this.exportoptions
      );
  }

  handleDelete(data: StagesTableModel) {
    const modal = this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Are you sure to delete this stage?",
      nzOkDanger: true,
      nzOkText: "Yes",
      nzOnOk: () => this.DeleteNotification(data),
    });
    setTimeout(() => modal.destroy(), 5000);
  }

  DeleteNotification(data: StagesTableModel) {
    let url = this.ApiUrl + "notificationconfig/stagesedit";
    data.Disabled = true;
    this.http.post<any>(url, data).subscribe({
      next: res => {
        this.alertService.success("Deleted Sucessfully"); this.isLoading = false;
        this.GetAllNotifications();
      },
      error: res => {
        if (res.StatusCode == 400) {
          this.alertService.warning(res.error.ResponseBody)
        }
        else {
          this.alertService.error(res.error.ResponseBody); this.isLoading = false;
        }
      },
    });
  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveNotificationStage();
  }

  setInternal(event) {
    if (event.target.checked) {
      this.NewNotificationStage.OnlyInternal = true;
    } else {
      this.NewNotificationStage.OnlyInternal = false;
    }
  }

  setCustomer(event) {
    if (event.target.checked) {
      this.NewNotificationStage.OnlyCustomer = true;
    } else {
      this.NewNotificationStage.OnlyCustomer = false;
    }
  }

  SaveNotificationStage() {
    if (!this.Validate(this.NewNotificationStage)) {
      this.isLoading = false;
      return;
    }

    if (this.isShownEdit) {
      let url = this.ApiUrl + "notificationconfig/stagesedit";
      this.http.post<EditStageModal>(url, this.NewNotificationStage).subscribe({
        next: (res) => {
          this.alertService.success("Stage Updated Successfully");
          this.isLoading = this.isVisible = false;
          this.GetAllNotifications();
        },
        error: (res) => {
          this.alertService.error(
            "An error has been occured. Please try again"
          );
          this.isLoading = this.isVisible = false;
        },
      });
    } else {
      let url = this.ApiUrl + "notificationconfig/addstages";
      this.http.post<AddStageModal>(url, this.NewNotificationStage).subscribe({
        next: (res) => {
          this.alertService.success("Stage Added Successfully");
          this.isLoading = this.isVisible = false;
          this.GetAllNotifications();
        },
        error: (res) => {
          this.alertService.error(
            "An error has been occured. Please try again"
          );
          this.isLoading = this.isVisible = false;
        },
      });
    }
  }

  Validate(model: AddStageModal) {
    var Isvalid: boolean = true;
    if (model.SaleOrderStages == "0" || model.SaleOrderStages == "") {
      this.alertService.error("Select Stage Name");
      Isvalid = false;
    }
    return Isvalid;
  }

  showModal(): void {
    this.NotificationTitle = "Add New Notification";
    this.isVisible = true;
    this.isShownEdit = false;
    this.GetAllWhatsAppTemplateList();
  }

  editNotification(value) {
    if (value.StageId) {
      this.NotificationTitle = "Edit Notification";
      this.isVisible = true;
      this.NewNotificationStage = value;
      this.isShownEdit = true;
    }
  }

  handleCancel(): void {
    // this.AddProductDescription.reset();
    this.isVisible = false;
  }
  GetAllWhatsAppTemplateList() {
    let url = this.ApiUrl + "notificationconfig/getwhatsapptemplate";
    this.http.get<WhatsAppTemplateList[]>(url).subscribe(
      (res) => {
        this.WhatsAppTemplateList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllWhatsAppTemplateList();
        }
      }
    );
  }
}
