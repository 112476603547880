<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>WhatsApp Config List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your WhatsApp Config here</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button nz-button nzType="primary" (click)="showModal()" *ngIf="permission.Add">
          Add New
        </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>

    <div style="padding: 8px; float: right">
      <input type="text" style="width: 250px; margin-right: 8px" nz-input placeholder="Search Config Name"
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />

      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px">
        Search
      </button>

      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()">
        <i nz-icon nzType="export" nzTheme="outline"></i> Export
      </button>
    </div>

    <nz-table [nzPageSize]="100" style="width: 100%" nzBordered [nzScroll]="{ x: '1200px', y: '515px' }" nzSize="small"
      #basicTable [nzData]="this.WhatsAppConfigList" [nzLoading]="isTableLoading">
      <thead>
        <tr>
          <th nzWidth="50px" nzLeft>S.No</th>
          <th nzWidth="150px">Config Name</th>
          <th nzWidth="60px">Provider Name</th>
          <th nzWidth="60px">Sender Mobile Number</th>
          <th nzWidth="100px">Added By</th>
          <th nzWidth="100px">Added Date</th>
          <th nzWidth="100px" nzRight style="text-align: center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of WhatsAppConfigList; index as i">
          <td nzLeft>{{ data.SerialNumber }}</td>
          <td>{{ data.ConfigName }}</td>
          <td>{{ data.ProviderName }}</td>
          <td>{{ data.RegisteredSenderNumber }}</td>
          <td>{{ data.AddedBy }}</td>
          <td>{{ data.AddedDate | DatetimeConverter }}</td>

          <td nzRight style="text-align: center">
            <button class="btn btn-sm btn-light-primary" (click)="editConfig(data)" *ngIf="permission.Edit">
              Edit
            </button>
            &nbsp;
            <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)" *ngIf="permission.Delete">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <nz-modal [(nzVisible)]="isVisible" [nzStyle]="{ top: '20px' }" [nzTitle]="modalTitle" [nzContent]="modalContent"
      [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{ WhatsAppConfigTitle }}</ng-template>
      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm">
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Config Name</nz-form-label>
            <nz-form-control [nzSpan]="10" nzValidateStatus="success">
              <nz-select name="select-error1" nzShowSearch formControlName="ConfigName">
                <nz-option nzValue="0" nzLabel="Select Config Name "></nz-option>
                <nz-option *ngFor="let provider of ConfigNameList" [nzValue]="provider.value"
                  [nzLabel]="provider.name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Provider Name</nz-form-label>
            <nz-form-control [nzSpan]="10" nzValidateStatus="success">
              <nz-select name="select-error2" nzShowSearch formControlName="ProviderName">
                <nz-option nzValue="0" nzLabel="Select Provider"></nz-option>
                <nz-option *ngFor="let provider of ProviderNameList" [nzValue]="provider.value"
                  [nzLabel]="provider.name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Sender Mobile</nz-form-label>
            <nz-form-control [nzSpan]="10">
              <input nz-input formControlName="RegisteredSenderNumber" name="RegisteredSenderNumber" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Provider Key</nz-form-label>
            <nz-form-control [nzSpan]="10">
              <input nz-input formControlName="ProviderKey" name="ProviderKey" type="password" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Max Daily Messages</nz-form-label>
            <nz-form-control [nzSpan]="10">
              <input nz-input formControlName="MaxDailyMessages" name="MaxDailyMessages" type="number" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Max Monthly Messages</nz-form-label>
            <nz-form-control [nzSpan]="10">
              <input nz-input formControlName="MaxMonthlyMessages" name="MaxMonthlyMessages" type="number" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>API Endpoint</nz-form-label>
            <nz-form-control [nzSpan]="10">
              <input nz-input formControlName="ApiEndpoint" name="ApiEndpoint" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="10">Webhook URL</nz-form-label>
            <nz-form-control [nzSpan]="10">
              <input nz-input formControlName="WebhookUrl" name="WebhookUrl" />
            </nz-form-control>
          </nz-form-item>
        </form>
      </ng-template>

      <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="handleCancel()">
          Cancel
        </button>
        <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isLoading" *ngIf="permission.Add || permission.Edit">
          Save WhatsApp Config
        </button>
      </ng-template>
    </nz-modal>
  </div>
</div>