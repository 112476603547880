import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { BarcodeLabelUpdateService } from '../../services/BarcodeLabelUpdateService';
import { AlertMessageService } from '../../../../Services/AlertMessageService';
import { LoadingService } from '../../../../Services/loadingService';
import { LabelFullDetailsModel, StockLabelModel, StockLabelResponseModel, BarcodeLabelUpdateModalState } from '../../../../Models/BarcodeLabelModel';
import { PackagingTypeModel } from 'src/PmsUIApp/Models/MeasureUnitModel';
import { environment } from 'src/environments/environment';
import { BarcodeScannerService } from '../../services/BarcodeScannerService';
import { AuthService } from '../../../../Services/auth.service';
import { Modules, Responsibility, ScannerMode } from '../../../../Models/Enums';
import { RackModel, StoreModel } from 'src/PmsUIApp/Models/MasterModel';

@Component({
    selector: 'app-barcode-label-update-modal',
    templateUrl: './BarcodeLabelUpdateModal.component.html',
    styleUrls: ['./BarcodeLabelUpdateModal.component.css']
})
export class BarcodeLabelUpdateModalComponent implements OnInit, OnDestroy {
    updateForm: FormGroup;
    updateFormLocation: FormGroup;
    labelDetails: LabelFullDetailsModel | null = null;
    isVisible: boolean = false;
    SerialNo: string = '';
    action: string | null = null;
    private subscriptions: Subscription = new Subscription();
    ApiUrl = environment.Api_Url;
    PackagingTypeList: PackagingTypeModel[] = [];
    permission = {
        View: false,
        Add: false,
        Edit: false,
        Delete: false,
        StockInspectionAdd: false
    }
    IsScannedLabelListVisible: boolean = false;
    IsStoreBarcodeListVisible: boolean = false;
    ScannedStockProductLabelList: StockLabelResponseModel[] = [];
    StoreBarcodeStockProductLabelList: StockLabelResponseModel[] = [];
    totalItemsCountScannedLabel: number = 0;
    totalItemsCountStoreBarcodeLabel: number = 0;
    ScannedLabelRequest: StockLabelModel | null = null;
    splitForm: FormGroup;
    isSplitModalVisible: boolean = false;
    splitRequestData = {
        ExistingLabelId: 0,
        Quantities: []
    }
    splitServiceData: StockLabelModel | null = null;
    StockProductLabelList: StockLabelResponseModel[] = [];
    CurrentLabelNewQuantity: number = 0;
    isSplitBtnVisible: boolean = false;
    BarcodeLabelUpdateState: BarcodeLabelUpdateModalState = new BarcodeLabelUpdateModalState();
    IsScanAgainOptionsVisible: boolean;
    StoreList: StoreModel[];
    RackList: RackModel[];
    IsLocationRequired: boolean = false;
    constructor(
        private fb: FormBuilder,
        private barcodeLabelUpdateService: BarcodeLabelUpdateService,
        private barcodeScannerService: BarcodeScannerService,
        private alertService: AlertMessageService,
        private loader: LoadingService,
        private http: HttpClient,
        private auth: AuthService
    ) {
        this.initForm();
        this.initSplitForm();
    }

    initForm(): void {
        this.updateForm = this.fb.group({
            inspectionStatus: [null, Validators.required],
            quantity: [null, Validators.required],
            packagingType: [null, Validators.required],
            mfgDate: [null],
            expiryDate: [null]
        });
        this.updateFormLocation = this.fb.group({
            currentStoreId: [0, [Validators.required, Validators.min(1)]],
            currentRackId: [0, [Validators.required, Validators.min(1)]]
        });
    }

    initSplitForm(): void {
        this.splitForm = this.fb.group({
            numberOfLabels: [1, [Validators.required, Validators.min(1)]],
            quantities: this.fb.array([])
        });
        this.addQuantityInput();
    }

    get quantities(): FormArray {
        return this.splitForm.get('quantities') as FormArray;
    }

    addQuantityInput(): void {
        this.quantities.push(this.fb.control(null, [Validators.required, Validators.min(1)]));
    }

    removeQuantityInput(index: number): void {
        this.quantities.removeAt(index);
        this.splitForm.patchValue({
            numberOfLabels: this.quantities.length
        });
    }

    onNumberOfLabelsChange(): void {
        const numberOfLabels = this.splitForm.get('numberOfLabels')?.value;
        while (this.quantities.length > numberOfLabels) {
            this.quantities.removeAt(this.quantities.length - 1);
        }
        while (this.quantities.length < numberOfLabels) {
            this.addQuantityInput();
        }
    }

    openSplitModal(): void {
        this.isSplitModalVisible = true;
        this.initSplitForm();
        this.onQuantityChange();
    }

    closeSplitModal(): void {
        this.isSplitModalVisible = false;
        this.splitForm.reset();
        this.splitForm.get('numberOfLabels').setValue(1);
        this.onNumberOfLabelsChange();
    }

    onSplitSubmit(): void {
        if (this.splitForm.valid) {
            this.loader.show();
            this.splitRequestData.Quantities = this.splitForm.get('quantities').value;
            this.splitRequestData.ExistingLabelId = this.splitServiceData?.StockLabelId;
            let url = this.ApiUrl + `stock/splitproductstocklabel/`;
            this.http.post(url, this.splitRequestData).subscribe({
                next: (res: StockLabelResponseModel[]) => {
                    this.StockProductLabelList = res;
                    this.alertService.success('Label split successfully');
                    const selectedLabelsId = this.StockProductLabelList.map(label => label.StockLabelId);

                    // Store the selected labels in session storage
                    sessionStorage.setItem('selectedLabelsIdList', JSON.stringify(selectedLabelsId));
                    window.open(`${window.location.origin}/barcodelabel/` + 3);
                    this.isSplitModalVisible = false;
                    this.splitForm.reset();
                    this.loader.hide();
                },
                error: (err: any) => {
                    this.alertService.error(err.error.ResponseBody);
                    this.loader.hide();
                }
            });
        }
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.barcodeLabelUpdateService.modalState$.subscribe(
                state => {
                    this.BarcodeLabelUpdateState = state;
                    this.SerialNo = state.serialNo;
                    this.action = state.action;
                    if (this.action == 'Update' && state.isVisible) {
                        this.fetchLabelDetails();
                        this.isVisible = state.isVisible;
                    } else if (this.action == 'ShowScanList' && state.isVisible) {
                        this.getScannedStockProductLabel();
                        this.IsScannedLabelListVisible = state.isVisible;
                    }
                    else if (this.action == 'IssueRequestScan' && state.isVisible) {
                        this.fetchScannedLabelsDetails(this.barcodeLabelUpdateService.getStockLabelIds());
                    }
                    else if (this.action == 'ShowStoreBarcodeList' && state.stockProductId > 0 && state.currentRackId > 0) {
                        this.getStockProductLabelByRack();
                        this.IsStoreBarcodeListVisible = state.isVisible;
                    }
                    else if (!state.isVisible) {
                        this.IsScannedLabelListVisible = this.isVisible = this.IsStoreBarcodeListVisible = state.isVisible;
                    }
                    else {
                        this.initForm();
                        this.labelDetails = null;
                    }
                }
            )
        );
        this.subscriptions.add(
            this.barcodeLabelUpdateService.splitModalState$.subscribe(
                state => {
                    this.isSplitModalVisible = state.isVisible;
                    this.splitServiceData = {
                        StockLabelId: state.StockLabelId,
                        SerialNo: state.SerialNo,
                        Quantity: state.Quantity
                    } as StockLabelModel;
                }
            )
        );
    }

    fetchLabelDetails(): void {
        this.loader.show();
        this.IsLocationRequired = false;
        this.permission.View = this.auth.CheckResponsibility(Modules.StockBarcodeLabel, Responsibility.View);
        this.permission.Add = this.auth.CheckResponsibility(Modules.StockBarcodeLabel, Responsibility.Add);
        this.permission.Edit = this.auth.CheckResponsibility(Modules.StockBarcodeLabel, Responsibility.Edit);
        this.permission.Delete = this.auth.CheckResponsibility(Modules.StockBarcodeLabel, Responsibility.Delete);
        this.permission.StockInspectionAdd = this.auth.CheckResponsibility(Modules.Inspection, Responsibility.Add);
        const stockProductId = this.barcodeLabelUpdateService.getStockProductId();
        this.labelDetails = this.barcodeScannerService.getLabelDetails();
        var currentAction = this.barcodeScannerService.getCurrentAction();
        this.isSplitBtnVisible = this.labelDetails?.StockLabel.Quantity > 1 && currentAction != 'InspectionScan' && this.action != 'Update';
        this.GetAllPackagingTypes();
        if (this.labelDetails && this.labelDetails.StockLabel.SerialNo == this.SerialNo) {
            this.updateForm.patchValue({
                inspectionStatus: this.labelDetails.StockLabel.InspectionStatus,
                quantity: this.labelDetails.StockLabel.Quantity,
                packagingType: this.labelDetails.StockLabel.PackagingUnit,
                mfgDate: this.labelDetails.StockLabel.MfgDate ? new Date(this.labelDetails.StockLabel.MfgDate) : null,
                expiryDate: this.labelDetails.StockLabel.ExpiryDate ? new Date(this.labelDetails.StockLabel.ExpiryDate) : null
            });
        }
        if (this.labelDetails?.StockLabel.CurrentStoreId < 1 && currentAction != 'InspectionScan') {
            this.IsLocationRequired = true;
            this.GetAllStore();
            this.updateFormLocation.patchValue({
                currentStoreId: this.labelDetails.StockLabel.CurrentStoreId,
                currentRackId: this.labelDetails.StockLabel.CurrentRackId
            });
        }
        this.loader.hide();
    }

    GetAllPackagingTypes() {
        this.loader.show();
        let url = this.ApiUrl + "data/getallpackagingtypes";
        this.http.get<PackagingTypeModel[]>(url).subscribe({
            next: (res) => {
                this.PackagingTypeList = res;
                this.loader.hide();
            },
            error: () => {
                this.alertService.error('Failed to fetch packaging types.');
                this.loader.hide();
            }
        });
    }

    GetAllStore() {
        this.loader.show();
        let url = this.ApiUrl + "store/getallstores";
        this.http.get<StoreModel[]>(url).subscribe({
            next: (res) => {
                this.StoreList = res;
                this.loader.hide();
            },
            error: () => {
                this.alertService.error('Failed to fetch stores.');
                this.loader.hide();
            }
        });
    }
    onSelectedStoreChange() {
        this.loader.show();
        let url = this.ApiUrl + "rack/getallracks";
        this.http.get<RackModel[]>(url).subscribe({
            next: (res) => {
                this.RackList = res.filter(x => x.StoreId == this.updateFormLocation.get('currentStoreId').value);
                this.loader.hide();
            },
            error: () => {
                this.alertService.error('Failed to fetch racks.');
                this.loader.hide();
            }
        });
    }

    onSubmit(): void {
        if (this.updateForm.valid && this.labelDetails) {
            this.loader.show();
            const updateData = {
                serialNo: this.labelDetails.StockLabel.SerialNo,
                packagingUnit: this.updateForm.get('packagingType').value,
                ...this.updateForm.value
            };
            if (this.updateFormLocation.valid) {
                updateData.currentStoreId = this.updateFormLocation.get('currentStoreId').value;
                updateData.currentRackId = this.updateFormLocation.get('currentRackId').value;
            }
            let url = this.ApiUrl + `stock/updatestockproductlabel/`;
            this.http.post(url, updateData).subscribe({
                next: (res: any) => {
                    this.alertService.success(res.ResponseBody);
                    if (this.IsLocationRequired) {
                        this.IsLocationRequired = false;
                    }
                    this.closeModal(this.action);
                    this.loader.hide();
                },
                error: (err: any) => {
                    this.alertService.error(err.error);
                    this.loader.hide();
                }
            });
        }
    }
    SaveAndScanAgain(): void {
        if (this.updateForm.valid && this.labelDetails) {
            this.loader.show();
            const updateData = {
                serialNo: this.labelDetails.StockLabel.SerialNo,
                packagingUnit: this.updateForm.get('packagingType').value,
                ...this.updateForm.value
            };
            if (this.updateFormLocation.valid) {
                updateData.currentStoreId = this.updateFormLocation.get('currentStoreId').value;
                updateData.currentRackId = this.updateFormLocation.get('currentRackId').value;
            }
            let url = this.ApiUrl + `stock/updatestockproductlabel/`;
            this.http.post(url, updateData).subscribe({
                next: (res: any) => {
                    this.alertService.success(res.ResponseBody);
                    if (this.IsLocationRequired) {
                        this.IsLocationRequired = false;
                    }
                    this.closeModal(this.action);
                    this.loader.hide();
                    setTimeout(() => {
                        this.IsScanAgainOptionsVisible = true;
                    }, 2000);
                },
                error: (err: any) => {
                    this.alertService.error(err.error);
                    this.loader.hide();
                }
            });
        }
    }

    ScanAgain(): void {
        this.closeModal(this.action);
        setTimeout(() => {
            this.IsScanAgainOptionsVisible = true;
        }, 1000);
    }

    handleCancel(): void {
        this.closeModal(this.action);
    }

    closeModal(action: string): void {
        this.barcodeLabelUpdateService.closeModal(action);
        this.barcodeLabelUpdateService.resetValues();
        this.barcodeScannerService.resetValues();
        this.updateForm.reset();
        this.updateFormLocation.reset();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    openBarcodeInspectionScanner() {
        this.barcodeScannerService.setScannerMode(ScannerMode.OverlayScanner, 'InspectionScan');
        this.IsScanAgainOptionsVisible = false;
    }

    openManualInputInspection() {
        this.barcodeScannerService.setScannerMode(ScannerMode.ManualEntry, 'InspectionScan');
        this.IsScanAgainOptionsVisible = false;
    }
    OpenSelectedLabelPrintModal() {
        this.getScannedStockProductLabel();
    }
    handleScannedLabelListCancel(): void {
        this.barcodeLabelUpdateService.closeModalShowScanList();
        this.barcodeLabelUpdateService.resetValues();
    }
    getScannedStockProductLabel() {
        this.loader.show();
        var stockProductId = this.barcodeLabelUpdateService.getStockProductId();
        this.ScannedLabelRequest = {
            StockProductId: stockProductId
        } as StockLabelModel;
        let url = this.ApiUrl + "stock/getstocklabelsbyproductstock";
        this.http.post(url, this.ScannedLabelRequest).subscribe({
            next: (res: any) => {
                this.ScannedStockProductLabelList = res.filter(x => x.Quantity > 0);
                this.totalItemsCountScannedLabel = this.ScannedStockProductLabelList.length;
                this.loader.hide();
            }, error: (err: any) => {
                this.loader.hide();
            }
        })
    }
    getStockProductLabelByRack() {
        this.loader.show();
        const StoreBarcodeRequest = {
            StockProductId: this.BarcodeLabelUpdateState.stockProductId,
            CurrentRackId: this.BarcodeLabelUpdateState.currentRackId
        } as StockLabelModel;
        let url = this.ApiUrl + "stock/getstocklabelsbyproductstock";
        this.http.post(url, StoreBarcodeRequest).subscribe({
            next: (res: any) => {
                this.StoreBarcodeStockProductLabelList = res.filter(x => x.Quantity > 0);
                this.totalItemsCountStoreBarcodeLabel = this.StoreBarcodeStockProductLabelList.length;
                this.loader.hide();
            }, error: (err: any) => {
                this.loader.hide();
            }
        })
    }
    SetScannedLabelStatus(data: any) {
        if (data.IsActive && data.Quantity > 0) {
            return 'Active';
        } else if (!data.IsActive && (data.Quantity == 0 || data.Quantity == null)) {
            return 'Pending';
        } else {
            return 'In-Active';
        }
    }
    SetScannedLabelColor(data: any) {
        if (data.LabelStatus == 'Active' || data.LabelStatus == 'Updated') {
            return '#96f0b1';
        } else if (data.LabelStatus == 'Pending' || data.LabelStatus == 'Accepted' || data.LabelStatus == 'Allocated') {
            return 'yellow';
        } else if (data.LabelStatus == 'Rejected' || data.LabelStatus == 'In-Active' || data.LabelStatus == '') {
            return 'red';
        } else {
            return '';
        }
    }
    SetInspectionStatusColor(data: any) {
        if (data.InspectionStatus == 'Accepted') {
            return '#96f0b1';
        } else if (data.InspectionStatus == 'Rejected') {
            return '#f09696';
        } else {
            return '';
        }
    }
    fetchLabelDetailsbyStockLabelIds(ids: number[]) {
        let url = this.ApiUrl + `stock/getstocklabelsbystocklabelid`;
        this.http.post(url, ids).subscribe({
            next: (res: any) => {
                this.labelDetails = res;
            }
        })
    }
    fetchScannedLabelsDetails(ids: number[]) {
        if (ids.length === 0) return;

        this.loader.show();
        this.http.post<StockLabelResponseModel[]>(`${this.ApiUrl}stock/getstocklabelsbystocklabelid`, ids).subscribe({
            next: (res) => {
                this.ScannedStockProductLabelList = res;
                this.loader.hide();
            },
            error: (err) => {
                this.loader.hide();
                this.alertService.error('Error fetching scanned labels.');
            }
        });
    }
    decreaseLabels(): void {
        const currentValue = this.splitForm.get('numberOfLabels').value;
        if (currentValue > 1) {
            this.splitForm.get('numberOfLabels').setValue(currentValue - 1);
            this.onNumberOfLabelsChange();
        }
    }

    increaseLabels(): void {
        const currentValue = this.splitForm.get('numberOfLabels').value;
        if (currentValue < 10) {
            this.splitForm.get('numberOfLabels').setValue(currentValue + 1);
            this.onNumberOfLabelsChange();
        }
    }
    onQuantityChange(): void {
        const quantities = this.splitForm.get(`quantities`)?.value;
        this.CurrentLabelNewQuantity = this.splitServiceData?.Quantity - quantities.reduce((acc, curr) => acc + curr, 0);
    }
    handleScanAgainOptionsCancel(): void {
        this.IsScanAgainOptionsVisible = false;
    }
    disableUpdateButton(): boolean {
        if (this.IsLocationRequired) {
            var res = !(this.updateForm.valid && this.updateFormLocation.valid);
            return res;
        }
        return !this.updateForm.valid;
    }
}
