<nz-modal [(nzVisible)]="isPriceHistoryVisible" [nzStyle]="{ top: '20px' }" [nzWidth]="700"
  [nzTitle]="modalTitlePriceHistory" [nzContent]="modalContentPriceHistory"
  [nzFooter]="modalFooterPriceHistory" (nzOnCancel)="handlePriceHistoryCancel()">
  <ng-template #modalTitlePriceHistory>Sale Price History</ng-template>

  <ng-template #modalContentPriceHistory>
    <div nz-row style="margin-bottom: 10px;">
      <div nz-col [nzSpan]="8">
        <label>Number of Records: </label>
        <nz-select nzSize="default" [(ngModel)]="selectedNumberOfRecords" [ngModelOptions]="{standalone: true}"
          (ngModelChange)="onNumberOfRecordsChange($event)">
          <nz-option nzValue="3" nzLabel="3"></nz-option>
          <nz-option nzValue="5" nzLabel="5"></nz-option>
          <nz-option nzValue="10" nzLabel="10"></nz-option>
          <nz-option nzValue="20" nzLabel="20"></nz-option>
          <nz-option nzValue="50" nzLabel="50"></nz-option>
        </nz-select>
      </div>
    </div>
    <p>
        The price history is based on Formulation Code, Thickness and Customer. Number of records can be changed to get more price history.
    </p>

    <nz-table #basicTable [nzData]="PriceHistoryList" nzSize="small" [nzLoading]="isLoading"
      [nzShowPagination]="true" [nzPageSize]="10" nzBordered>
      <thead>
        <tr>
          <th>Sale Order No.</th>
          <th>Sale Price</th>
        </tr>
      </thead>
      <tbody nzBordered>
        <tr *ngFor="let data of basicTable.data">
          <td>
            <nz-tag [nzColor]="'blue'" (click)="OpenSODetails(data.SaleOrderId)">
              {{data.SaleOrderNumber}}
            </nz-tag>
          </td>
          <td>{{data.SalePrice | number:'1.2-2'}}</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>

  <ng-template #modalFooterPriceHistory>
    <button nz-button nzType="default" (click)="handlePriceHistoryCancel()">Close</button>
  </ng-template>
</nz-modal>