<div *ngIf="costingService.IsCostingVisible">
    <nz-drawer [nzClosable]="true" [nzVisible]="true" nzPlacement="right" [nzWidth]="900" nzTitle="Costing Details"
        (nzOnClose)="costingService.handleCancelCostingPopup()">
        <ng-container *nzDrawerContent>
            <div>
                <div style="padding: 8px; display: flex; align-items: center;">
                    <button nz-button nzType="primary" (click)="PrintListPage()" style="margin-right: 8px;">
                        <i nz-icon nzType="printer" nzTheme="outline"></i>Print
                    </button>
                    <button nz-button nzType="primary" (click)="PrintPageStandardGSM()">
                        <i nz-icon nzType="printer" nzTheme="outline"></i>Print with Standard GSM
                    </button>
                </div>
                <div>
                    <div *ngIf="costingService.Orderlist">
                        <nz-divider nzText="Sales Order Details"></nz-divider>
                        <nz-descriptions nzBordered nzLayout="vertical" *ngIf="costingService.Orderlist" nzSize="small"
                            [nzColumn]="6">
                            <nz-descriptions-item nzTitle="Order Type" [nzSpan]="1" nzColon="true">
                                <b>{{ costingService.Orderlist.SaleOrderType }}</b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="CUSTOMER"
                                [nzSpan]="2"><b>{{costingService.Orderlist.CustomerName}}</b></nz-descriptions-item>

                            <nz-descriptions-item nzTitle="Sale Order No." [nzSpan]="1"><b>{{
                                    costingService.Orderlist.SaleOrderNumber
                                    }}</b></nz-descriptions-item>
                        </nz-descriptions>
                        <nz-descriptions nzBordered nzLayout="vertical" *ngIf="costingService.Orderlist" nzSize="small"
                            [nzColumn]="6">
                            <nz-descriptions-item nzTitle="Product Type">
                                <b>
                                    {{
                                    costingService.GetProductType(costingService.Orderlist.SaleFormulationCode)
                                    }}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Sale Order Date">
                                <b>
                                    {{
                                    costingService.Orderlist.SaleOrderDate | date: "dd-MMM-yyyy"
                                    }}
                                </b>
                            </nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Delivery Date">
                                <b>
                                    {{
                                    costingService.Orderlist.DeliveryDate | date: "dd-MMM-yyyy"
                                    }}
                                </b>
                            </nz-descriptions-item>
                        </nz-descriptions>

                        <nz-divider nzText="Production Details"></nz-divider>
                        <nz-descriptions nzBordered nzLayout="vertical"
                            *ngIf="costingService.Orderlist?.SaleOrderProduction" nzSize="small" [nzColumn]="4">
                            <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true"><b>{{
                                    costingService.Orderlist.SaleFormulationCode }}</b></nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Category"
                                [nzSpan]="1"><b>{{costingService.Orderlist.Category}}</b></nz-descriptions-item>
                            <nz-descriptions-item
                                nzTitle="Thick"><b>{{costingService.Orderlist.SaleOrderProduction.ThicknessValue}}</b></nz-descriptions-item>
                            <nz-descriptions-item
                                nzTitle="Width"><b>{{costingService.Orderlist.SaleOrderProduction.WidthNumber}}</b></nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Article Name"
                                [nzSpan]="2"><b>{{costingService.Orderlist.SaleOrderProduction.ManufacturingProductName}}</b></nz-descriptions-item>
                            <nz-descriptions-item
                                nzTitle="Order Quantity"><b>{{costingService.Orderlist.SaleOrderProduction.OrderQuantity}}</b></nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Sale Price"><b>&#8377; {{
                                    costingService.Orderlist.SaleOrderProduction.SalePrice
                                    }}</b></nz-descriptions-item>
                            <nz-descriptions-item nzTitle="Total Sale Price"><b>&#8377; {{
                                    costingService.Orderlist.SaleOrderProduction.TotalSalePrice
                                    }}</b></nz-descriptions-item>
                            <nz-descriptions-item
                                nzTitle="Grain Code"><b>{{costingService.Orderlist.SaleOrderProduction.GrainCode}}</b>
                                <span style="position: relative; margin-left: 5px;margin-top: 4px;" nz-icon
                                    nzTooltipTitle="Grain Name: {{costingService.Orderlist.SaleOrderProduction.GrainName}}"
                                    nzTooltipPlacement="left" nz-tooltip nzType="info-circle" nzTheme="outline">
                                </span></nz-descriptions-item>
                            <nz-descriptions-item
                                nzTitle="Color"><b>{{costingService.Orderlist.SaleOrderProduction.ColorName}}</b></nz-descriptions-item>
                            <nz-descriptions-item
                                nzTitle="Fabric Color"><b>{{this.costingService.FabricColorName}}</b></nz-descriptions-item>

                        </nz-descriptions>
                        <nz-divider nzText="Inspection Details"></nz-divider>

                        <div nz-row [nzGutter]="24" style="margin: 0px 0px"
                            *ngIf="costingService.Orderlist?.SaleOrderProduction">
                            <div nz-col [nzSpan]="24">
                                <nz-divider nzText="Fabric"></nz-divider>
                                <span>
                                    <div *ngFor="let i of costingService.Orderlist.SaleOrderProduction.FinalFabricData">
                                        <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                                            nzShowPagination="false">
                                            <thead>
                                                <tr>
                                                    <th nzWidth="50px">S.No</th>
                                                    <th>Product</th>
                                                    <th nzWidth="100px">Unit</th>
                                                    <th nzWidth="100px">Price</th>
                                                    <th nzWidth="100px">Batch No.</th>
                                                    <th nzWidth="100px">QTY</th>
                                                    <th nzWidth="100px">Cost</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of i.MixingRawMaterial; let i = index">
                                                    <td nzWidth="20px">{{ i + 1 }}</td>
                                                    <td>{{ data.ProductName }}</td>
                                                    <td>{{ data.Unit }}</td>
                                                    <td>{{ data.Price }}</td>
                                                    <td>{{ data.BatchNo }}</td>
                                                    <td>{{data.Quantity}} <span *ngIf="data.Unit == 'Kgs'"
                                                            style="position: relative; margin-left: 5px;margin-top: 4px;"
                                                            nz-icon nzPopoverTitle="Conversion to Meter"
                                                            nzPopoverContent="QTY in Meter: {{data.ConvertedQtyKgsToMtrs}}"
                                                            nzPopoverPlacement="right" nz-popover nzType="info-circle"
                                                            nzTheme="outline">
                                                        </span></td>
                                                    <td>{{(data.Price * data.Quantity).toFixed(2)}}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><b>Total Fabric QTY (MTR)</b></td>
                                                    <td><b>{{i.TotalQuantity}}</b></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><b>Total Cost</b></td>
                                                    <td><b>&#8377; {{i.TotalCost}}</b> </td>
                                                </tr>
                                            </tbody>
                                        </nz-table>
                                    </div>
                                </span>

                            </div>
                            <div nz-col [nzSpan]="24">
                                <nz-divider nzText="Mixing"></nz-divider>
                                <span>
                                    <div *ngFor="let i of costingService.Orderlist.SaleOrderProduction.FinalFormulationMixing"
                                        style="
                                            padding: 5px;
                                            border: 1px solid #ccc;
                                            border-radius: 4px;
                                            margin-top: 10px;
                                            background: #fff;
                                            box-shadow: 1px 1px 2px 2px #415994;
                                        ">
                                        <div style="padding: 10px 0; text-transform: uppercase">
                                            Mixing Name :
                                            <b>{{ i.MixingName }}</b>
                                        </div>
                                        <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                                            nzShowPagination="false">
                                            <thead>
                                                <tr>
                                                    <th nzWidth="50px">S.No</th>
                                                    <th>Product</th>

                                                    <th nzWidth="100px">Unit</th>
                                                    <th nzWidth="100px">Price</th>
                                                    <th nzWidth="100px">Batch No.</th>
                                                    <th nzWidth="100px">QTY</th>
                                                    <th nzWidth="100px">SC QTY</th>
                                                    <th nzWidth="100px">Cost</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of i.MixingRawMaterial; let i = index">
                                                    <td nzWidth="20px">{{ i + 1 }}</td>
                                                    <td>{{ data.ProductName }}</td>

                                                    <td>{{ data.Unit }}</td>
                                                    <td>&#8377; {{ data.Price }}</td>
                                                    <td>{{ data.BatchNo }}</td>
                                                    <td> {{data.Quantity}}</td>
                                                    <td> {{data.Scquantity}}</td>
                                                    <td> &#8377; {{(data.Price * data.Quantity).toFixed(2)}}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Total</b></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><b>{{i.TotalQuantity}}</b> </td>
                                                    <td></td>
                                                    <td><b>&#8377; {{ i.TotalCost}}</b> </td>
                                                </tr>
                                            </tbody>
                                        </nz-table>



                                    </div>
                                </span>

                            </div>
                            <!-- <div nz-col [nzSpan]="24"
                                *ngIf="costingService.Orderlist.SaleOrderProduction.Lacquer.length > 0">
                                <nz-divider nzText="Lacquer"></nz-divider>
                                <div *ngFor="let i of costingService.Orderlist.SaleOrderProduction.Lacquer" style="
                                        padding: 5px;
                                        border: 1px solid #ccc;
                                        border-radius: 4px;
                                        margin-top: 10px;
                                        background: #fff;
                                        box-shadow: 1px 1px 2px 2px #415994;
                                    ">
                                    <div style="padding: 10px 0; text-transform: uppercase">
                                        Lacquer Name :
                                        <b>{{ i.Name }}</b>
                                    </div>
                                    <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                                        nzShowPagination="false">
                                        <thead>
                                            <tr>
                                                <th nzWidth="50px">S.No</th>
                                                <th>Product</th>

                                                <th nzWidth="100px">Unit</th>
                                                <th nzWidth="100px">Price</th>
                                                <th nzWidth="100px">QTY</th>
                                                <th nzWidth="100px">Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of i.LacquerRawMaterial; let i = index">
                                                <td nzWidth="20px">{{ i + 1 }}</td>
                                                <td>{{ data.ProductName }}</td>

                                                <td>{{ data.Unit }}</td>
                                                <td>{{ data.Price }}</td>
                                                <td> {{data.Quantity}}</td>
                                                <td> {{data.Price * data.Quantity}}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><b>Total</b></td>

                                                <td></td>
                                                <td></td>
                                                <td><b>{{i.TotalQuantity}}</b> </td>
                                                <td><b>{{ i.TotalPerUnitPrice}}</b> </td>
                                            </tr>
                                        </tbody>
                                    </nz-table>
                                </div>
                                <br>

                                <br> <b>Lacquer Total Per MTR Cost: </b>
                                {{(this.costingService.TotalLaquerPrice/this.costingService.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)}}

                            </div> -->
                            <div nz-col [nzSpan]="24"
                                *ngIf="costingService.Orderlist.SaleOrderProduction.PackagingRawMaterial.length > 0">
                                <nz-divider nzText="Packaging"></nz-divider>
                                <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                                    nzShowPagination="false">
                                    <thead>
                                        <tr>
                                            <th nzWidth="50px">S.No</th>
                                            <th>Product</th>
                                            <th nzWidth="100px">Unit</th>
                                            <th nzWidth="100px">Price</th>
                                            <th nzWidth="100px">Batch No.</th>
                                            <th nzWidth="100px">QTY</th>
                                            <th nzWidth="100px">Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let data of costingService.Orderlist.SaleOrderProduction.PackagingRawMaterial; let i = index">
                                            <td nzWidth="20px">{{ i + 1 }}</td>
                                            <td>{{ data.ProductName }}</td>
                                            <td>{{ data.Unit }}</td>
                                            <td>&#8377; {{ data.Price }}</td>
                                            <td>{{ data.BatchNo }}</td>
                                            <td>{{ data.Quantity }}</td>
                                            <td>&#8377; {{ (data.Price * data.Quantity).toFixed(2) }}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><b>Total</b></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><b>{{ costingService.PackagingTotalQuantity }}</b> </td>
                                            <td><b>&#8377; {{ costingService.PackagingTotalCost }}</b> </td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                                <br>
                            </div>
                            <div nz-col [nzSpan]="24"
                                *ngIf="costingService.Orderlist.SaleOrderProduction.SaleOrderProductionMiscellaneousRawMaterial.length > 0">
                                <nz-divider nzText="Miscellaneous"></nz-divider>
                                <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                                    nzShowPagination="false">
                                    <thead>
                                        <tr>
                                            <th nzWidth="50px">S.No</th>
                                            <th>Product</th>
                                            <th>Material Category</th>
                                            <th nzWidth="100px">Unit</th>
                                            <th nzWidth="100px">Price</th>
                                            <th nzWidth="100px">Batch No.</th>
                                            <th nzWidth="100px">QTY</th>
                                            <th nzWidth="100px">Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let data of costingService.Orderlist.SaleOrderProduction.SaleOrderProductionMiscellaneousRawMaterial; let i = index">
                                            <td nzWidth="20px">{{ i + 1 }}</td>
                                            <td>{{ data.ProductName }}</td>
                                            <td>{{ data.MaterialCategory }}</td>
                                            <td>{{ data.Unit }}</td>
                                            <td>&#8377; {{ data.Price }}</td>
                                            <td>{{ data.BatchNo }}</td>
                                            <td>{{ data.Quantity }}</td>
                                            <td>&#8377; {{ (data.Price * data.Quantity).toFixed(2) }}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><b>Total</b></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><b>{{ costingService.MiscTotalQuantity }}</b> </td>
                                            <td><b>&#8377; {{ costingService.MiscTotalCost }}</b> </td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                                <br>
                            </div>
                            <nz-divider></nz-divider>
                            <div
                                *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null">
                                <b style="margin-left: 15px;">Inline Scraping(Optional) : </b> <input type="number"
                                    style="width: 20%;margin-right: 3px" placeholder="Per LM Constant"
                                    [disabled]="this.costingService.Orderlist.SaleOrderCosting != null"
                                    (ngModelChange)="costingService.calculateInline($event)" nz-input
                                    [(ngModel)]="this.costingService.InlineScraping" />
                            </div>

                            <div nz-col [nzSpan]="24">
                                <nz-divider nzText="Costing Details"></nz-divider>
                                Note: Costing Details are calculated after the consumption for raw material is completed
                                and if there are
                                Post Process included in this order then that also needs to be processed before to show
                                the actual cost.
                                <nz-divider></nz-divider>
                                <nz-descriptions *ngIf="costingService.havePostProcess" nzBordered nzLayout="vertical"
                                    nzSize="small" [nzColumn]="6">

                                    <nz-descriptions-item nzTitle="Material Type">
                                        <nz-select [disabled]="this.costingService.Orderlist.SaleOrderCosting != null"
                                            class="form-select mb-2" nzSize="default" nzPlaceHolder="Choose"
                                            [(ngModel)]="this.costingService.MaterialType"
                                            (ngModelChange)="costingService.calclulatePostProesscost()"
                                            [ngModelOptions]="{standalone: true}">
                                            <nz-option nzValue="PVC" nzLabel="PVC"></nz-option>
                                            <nz-option nzValue="PU" nzLabel="PU"></nz-option>
                                        </nz-select>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Printing"
                                        *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint != null">
                                        <b>
                                            &#8377; {{
                                            this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessPrint.PrintCost}}
                                        </b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Embossing"
                                        *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing != null">
                                        <b>
                                            &#8377; {{
                                            this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessEmbossing.EmbossingCost}}
                                        </b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Tumbling"
                                        *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling != null">
                                        <b>
                                            &#8377; {{
                                            this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessTumbling.TumblingCost}}
                                        </b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Vacuum"
                                        *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum != null">
                                        <b>
                                            &#8377; {{
                                            this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessVacuum.VacuumCost}}
                                        </b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Lacquer"
                                        *ngIf="this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer != null">
                                        <b>
                                            &#8377; {{
                                            this.costingService.Orderlist.SaleOrderProduction.SaleOrderPostProcessLacquer.LacquerCost}}
                                        </b>
                                    </nz-descriptions-item>
                                </nz-descriptions>
                                <br>
                                <div>
                                    <nz-table [nzData]="['']" #basicTable1 nzBordered nzShowPagination="false">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Per Kg Cost</th>
                                                <th>Cost Per Lm</th>
                                                <th>Final GSM</th>
                                                <th>STD. GSM</th>
                                                <th>Less GSM</th>
                                                <th>Extra GSM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let i of costingService.Orderlist.SaleOrderProduction.FinalFormulationMixing">
                                                <td> {{ i.MixingName }}</td>
                                                <td>&#8377; {{(i.TotalCost / i.TotalQuantity).toFixed(2)}}</td>
                                                <td>&#8377; {{i.CostPerLm}}</td>
                                                <td> {{i.FinalGSM}}</td>
                                                <td> {{i.GSM}}</td>
                                                <td> {{i.LessGSM}}</td>
                                                <td> {{i.ExtraGSM}}</td>
                                            </tr>
                                        </tbody>
                                    </nz-table>
                                </div>
                                <div>
                                    <br>
                                    <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered
                                        nzShowPagination="false">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>GSM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngIf="costingService.Orderlist.SaleOrderProduction.FinalFabricData?.length > 0">
                                                <td>Fabric</td>
                                                <td>{{costingService.FabricFinalAvgGsm}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Coating</td>
                                                <td> {{this.costingService.TotalCoating}} </td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{costingService.FabricFinalAvgGsm +
                                                    this.costingService.TotalCoating}} </td>
                                            </tr>
                                        </tbody>
                                    </nz-table>
                                </div>
                                <br>
                                <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="5">
                                    <nz-descriptions-item nzTitle="Per LM Constant" [nzSpan]="1">
                                        <input type="number" style="width: 80%;" placeholder="Per LM Constant"
                                            [disabled]="this.costingService.Orderlist.SaleOrderCosting != null"
                                            (ngModelChange)="costingService.calclulateLMcost($event)" nz-input
                                            [(ngModel)]="this.costingService.PerLMConstant" />
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="PASTE COST / LM" [nzSpan]="1" nzColon="true">
                                        <b>&#8377; {{ this.costingService.TotalCostLm }}</b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="GRAIN COST / LM" [nzSpan]="1"><b>&#8377; {{
                                            costingService.Orderlist.SaleOrderProduction.GrainPrice
                                            }}</b></nz-descriptions-item>

                                    <nz-descriptions-item nzTitle="FABRIC COST / LM" [nzSpan]="1"><b>&#8377; {{
                                            this.costingService.FabricProductCostPerLm}}</b></nz-descriptions-item>

                                    <nz-descriptions-item nzTitle="FINISHING COST / LM">
                                        <b>
                                            &#8377; {{costingService.calculatefinishingcost()}}
                                        </b>
                                    </nz-descriptions-item>
                                </nz-descriptions>
                                <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="4">
                                    <nz-descriptions-item nzTitle="R\M COST / LM">
                                        <b>
                                            &#8377; {{ costingService.calculateRMCost()}}
                                        </b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="** REJECTION %">
                                        <input type="number" style="width: 80%;"
                                            [disabled]="EnableEditing != true && this.costingService.IsCostingSaved"
                                            placeholder="Rejection Constant"
                                            (ngModelChange)="costingService.calclulateRejection($event)"
                                            [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="this.costingService.RejectionConstant" /> %

                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="REJECTION COST /LM">
                                        <b>
                                            &#8377; {{ this.costingService.Rejection }}
                                        </b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="PRODUCTION COST / LM">
                                        <b>
                                            &#8377; {{ this.costingService?.ProductionCostLm }}
                                        </b>
                                    </nz-descriptions-item>
                                </nz-descriptions>
                                <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="4">

                                    <nz-descriptions-item nzTitle="*** Overhead">
                                        &#8377; <input type="number"
                                            [disabled]="EnableEditing != true && this.costingService.IsCostingSaved"
                                            style="width: 80%;" placeholder="Overhead cost" nz-input
                                            [(ngModel)]="this.costingService.OverheadCost"
                                            (ngModelChange)="costingService.calclulateProductinCostPerLm($event)"
                                            [ngModelOptions]="{standalone: true}" />
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="PACKAGING COST / LM" [nzSpan]="1">
                                        <b>
                                            &#8377; {{this.costingService.PackagingCostPerLm}}
                                        </b>
                                    </nz-descriptions-item>

                                    <nz-descriptions-item nzTitle="MISC. COST/ LM"
                                        *ngIf="costingService.Orderlist.SaleOrderProduction.SaleOrderProductionMiscellaneousRawMaterial.length > 0">
                                        <b>
                                            &#8377; {{costingService.MiscCostPerLm}}
                                        </b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Total Cost">
                                        <b>
                                            &#8377; {{ this.costingService.TotalCostPerLm }}
                                        </b>
                                    </nz-descriptions-item>
                                </nz-descriptions>
                                <p style="margin: 10px;">** Rejection Percentage shown above is auto calculated by
                                    taking sum quantity of
                                    these Graded Mini Rolls - LOT, NS, CUT-PC, FILM, Waste. Each Roll Details can be
                                    seen on Yield Report.
                                </p>
                                <p style="margin: 10px;">*** Overhead is auto calculated based on Line Speed for this
                                    order -
                                    {{this.costingService.Orderlist.SaleOrderProductionComplete?.PrdLineSpeed}}
                                    MTRs/Minute and Per Hour Avg Overhead provided in Overhead Details under Costing
                                    Menu.
                                </p>
                                <nz-divider nzText="Lining Order Costing"
                                    *ngIf="this.costingService.Orderlist.IsUpperOrder"></nz-divider>
                                <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="3"
                                    *ngIf="this.costingService.Orderlist.IsUpperOrder">
                                    <nz-descriptions-item nzTitle="Sale Order Number">
                                        <div>
                                            <nz-tag [nzColor]="'blue'"><a
                                                    (click)="this.costingService.OpenSaleOrder(this.costingService.Orderlist.LinkedSaleOrderCosting.SaleOrderId)">
                                                    {{this.costingService.Orderlist.LinkedSaleOrderCosting.SaleOrderNumber}}
                                                </a></nz-tag>
                                        </div>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Lining Cost Per LM">
                                        <div>
                                            &#8377;
                                            {{this.costingService.Orderlist.LinkedSaleOrderCosting.TotalCostPerLm}}
                                        </div>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Upper + Lining Cost Per LM">
                                        <div>
                                            &#8377;
                                            {{this.costingService.TotalCostPerLmWithLining}}
                                        </div>
                                    </nz-descriptions-item>
                                </nz-descriptions>
                                <nz-divider nzText="Profit/Loss Details"></nz-divider>
                                <nz-descriptions nzBordered nzLayout="vertical" nzSize="small" [nzColumn]="3">
                                    <nz-descriptions-item nzTitle="Order QTY">
                                        <div>
                                            {{this.costingService.Orderlist.SaleOrderProduction.OrderQuantity}}
                                        </div>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Manufactured QTY">
                                        <div>
                                            {{this.costingService.Orderlist.SaleOrderProduction.ManufacturingQuantity}}
                                        </div>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Production Line Speed">
                                        <div>
                                            {{this.costingService.Orderlist.SaleOrderProductionComplete?.PrdLineSpeed}}
                                            MTRs/Minute
                                        </div>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Formulation Code" [nzSpan]="1" nzColon="true"><b>{{
                                            costingService.Orderlist.SaleFormulationCode }}</b></nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Sale Price">
                                        <b>
                                            &#8377; {{costingService.Orderlist.SaleOrderProduction.SalePrice }}
                                        </b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item
                                        nzTitle="Thick"><b>{{costingService.Orderlist.SaleOrderProduction.ThicknessValue}}</b></nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Final Cost">
                                        <b *ngIf="this.costingService.Orderlist.IsUpperOrder">
                                            &#8377; {{ (this.costingService.TotalCostPerLmWithLining *
                                            this.costingService.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)
                                            }}
                                        </b>
                                        <b *ngIf="!this.costingService.Orderlist.IsUpperOrder">
                                            &#8377; {{ (this.costingService.TotalCostPerLm *
                                            this.costingService.Orderlist.SaleOrderProduction.ManufacturingQuantity).toFixed(2)
                                            }}
                                        </b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Profit/Loss Per LM">
                                        <b *ngIf="this.costingService.Orderlist.IsUpperOrder">
                                            &#8377; {{ (costingService.Orderlist.SaleOrderProduction.SalePrice -
                                            this.costingService.TotalCostPerLmWithLining).toFixed(2) }}
                                        </b>
                                        <b *ngIf="!this.costingService.Orderlist.IsUpperOrder">
                                            &#8377; {{ (costingService.Orderlist.SaleOrderProduction.SalePrice -
                                            this.costingService.TotalCostPerLm).toFixed(2) }}
                                        </b>
                                    </nz-descriptions-item>
                                    <nz-descriptions-item nzTitle="Total Profit/Loss">
                                        <b>
                                            &#8377; {{ this.costingService.calculateTotalProfitLoss() }}
                                        </b>
                                    </nz-descriptions-item>
                                </nz-descriptions>
                                <div style="margin-top: 10px;">
                                    <p>
                                        Note: Total Profit/Loss is calculated based on Total Sale Cost - Total
                                        Manufactured Cost
                                    </p>
                                </div>

                            </div><br>

                            <nz-divider></nz-divider>
                            <button nz-button
                                *ngIf="(this.costingService.IsCostingSaved == false || EnableEditing == true) && this.permission.Add"
                                nzType="primary" (click)="costingService.savecosting()">
                                Save Costing Constants
                            </button>
                            <button nz-button
                                *ngIf="this.costingService.IsCostingSaved == true && EnableEditing == false && this.permission.Edit"
                                nzType="primary" (click)="EnableEdit()">
                                Edit
                            </button>
                            <div class="ms-2">
                                <button nz-button nzType="primary" (click)="PrintListPage()">
                                    <i nz-icon nzType="printer" nzTheme="outline"></i>Print
                                </button>
                            </div>
                            <div class="ms-4">
                                <button nz-button nzType="primary" (click)="PrintPageStandardGSM()">
                                    <i nz-icon nzType="printer" nzTheme="outline"></i>Print with Standard GSM
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </nz-drawer>
</div>