import { FileUploadModel } from "./UploadModel";

export class StockProductModel {
  SerialNo: number = 0;
  StockProductId: number = 0;
  StockId: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  ProductType: string = '';
  ProductCode: string = '';
  Sku: string = '';
  Barcode: string = '';
  Quantity: number = 0;
  ManufacturedDate: string = '';
  ExpiryDate: string = '';
  Unit: string = '';
  PricePerUnit: number = 0;
  FreightPerUnit: number = 0;
  Grade: string = '';
  AcceptedQuantity: number = 0;
  RejectedQuantity: number = 0;
  Comments: string = '';
  StockProductAllocation: StockProductAllocationModel[] = [];
  StockProductManageRejected: StockProductManageRejectedModel[] = [];
  ConsumeStockProduct: ConsumeStockProductModel[] = [];
  NewRecord: boolean = false;
  ExpandRecord: boolean = false;
  RackId: number = 0;
  SupplierId: number = 0;
  SupplierName: string = '';
  Batch: string = '';
  StockDate: string = '';
  ReceivedDate: string = '';
  RecievedQuantity: string = '';

  ThicknessId: number = 0;
  GrainId: number = 0;
  WidthId: number = 0;
  ColorId: number = 0;
  ThicknessNumber: string = '';
  GrainName: string = '';
  WidthNumber: string = '';
  ColorName: string = '';
  PostProcess: string = '';


  StoreId: number = 0;
  ProductCategoryId: number = 0;
  ProductCategory: string = '';
  ProductFirstSubCategoryId: number = 0;
  ProductFirstSubCategory: string = '';
  ProductSecSubCategoryId: number = 0;
  ProductSecSubCategory: string = '';
  IsStockReissued: boolean = false;
  AddedBy: string = '';
  IsChecked: boolean = false;
  IsBarcodeLabelGenerated: boolean = false;
  FileUploads: FileUploadModel[] = [];
  InspectionPendingLabelCount: number = 0;
}
export class StockPrintModel {
  ProductName: string = '';
  ProductType: string = '';
  Quantity: number = 0;
}



export class StockProductAllocationModel {
  AllocationId: number = 0;
  StockProductId: number = 0;
  InspectionType: string = '';
  Quantity: number = 0;
  StoreId: number = 0;
  RackId: number = 0;
  RackName: string = '';
  RackCode: string = '';
  StoreName: string = '';
  RackAllocation: string = '';
  AcceptQTY: number = 0;
  DisposeQTY: number = 0;
  DispatchQTY: number = 0;
  TransportId: number = 0;
  VehicleId: number = 0;
  DispatchId: string = '';
  StockLabelIds: number[] = [];
}

export class StockProductManageRejectedModel {
  Id: number = 0;
  StockProductId: number = 0;
  FromRackId: number = 0;
  ToRackId: number = 0;
  Quantity: number = 0;
  ItemAction: string = '';
  Comment: string = '';
  StoreId: number = 0;
  RackId: number = 0;
  AllocationId: number = 0;
  AcceptStockLabelIds: number[] = [];
  StockProductRejectedDispatch: StockProductRejectedDispatchModel = new StockProductRejectedDispatchModel;
}

export class StockProductRejectedDispatchModel {
  Id: number = 0;
  StockProductManageRejectedId: number = 0;
  TransportId: number = 0;
  VehicleId: number = 0;
  TransportCompany: string = '';
  VehicleNumber: string = '';
  DispatchDate: string = '';
  DispatchId: string = '';
}


export class ProductStockStoreReportModel {
  ProductId: number = 0;
  ProductName: string = '';
  ProductType: string = '';
  ProductCode: string = '';
  StoreId: number = 0;
  RackId: number = 0;
  StoreName: string = '';
  StoreCode: string = '';
  Quantity: number = 0;
  ConsumptionQuantity: number = 0;
  SCQuantity: number = 0;
  RemainingQuantity: number = 0;
  SCRemainingQuantity: number = 0;
  RackName: string = '';
  QtyStyle: string = '';
}
export class IssueSaleOrderProductsStockModel {
  SerialNo: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  ProductType: string = '';
  ProductCode: string = '';
  ProductCategory: string = '';

  ProductCategoryId: number = 0;

  SKU: string = '';
  BarCode: string = '';
  Barcode: string = '';
  BatchNumber: string = '';
  Batch: string = '';
  Unit: string = '';
  PerUnitPrice: number = 0;
  ManufacturedDate: string = '';
  ExpiryDate?: string = '';
  StockId: number = 0;
  StockProductId: number = 0;
  StockLabelId: number = 0;
  InvoiceId: number = 0;
  InvoiceNumber: string = '';
  SupplierId: number = 0;
  SupplierName: string = '';
  StoreId: number = 0;
  StoreName: string = '';
  StoreCode: string = '';
  RackId: number = 0;
  RackName: string = '';
  RackCode: string = '';
  Quantity: number = 0;
  StockQuantity: number = 0;
  SaleOrderQuantity: number = 0;
  ActualQuantity: number = 0;
  SCQuantity: number = 0;
  ConsumptionQuantity: number = 0;
  IsDamaged?: boolean = false;
  Damaged: string = '';
  IsNewAdd?: boolean = false;
  RemainingQuantity: number = 0;
  SCRemainingQuantity: number = 0;
  SaleOrderId?: number = 0;
  ConsumedQty: number = 0;
  ConsumedScQty: number = 0;
  Remark: string = '';
  MixingName: string = '';
  QtyStyle: string = '';
  ConsumedBy: string = '';
  ConsumedDate: string = '';
  ProductQuality: string = '';
  ToRackId: number = 0;
  IsBarcodeLabelExist: boolean = false;
  ShortCode: string = '';
}
export class ConsumeStockProductModel {
  ConsumeStockProductId: number = 0;
  RackId?: number = 0;
  RackName: string = '';
  RackCode: string = '';
  StoreId?: number = 0;
  StoreName: string = '';
  StoreCode: string = '';
  ProductId: number = 0;
  ProductName: string = '';
  ProductCode: string = '';
  Quantity: string = '';
  SCQuantity: number = 0;
  Purpose: string = '';
  Unit: string = '';
  ConsumedDate?: string = '';
  IsDamaged?: boolean = false;
  AddedBy: string = '';
  AddedDate?: string;
  SaleOrderId?: number = 0;
  StockId?: number = 0;
  StockProductId?: number = 0;
  PricePerUnit?: number = 0;
  IsNewAdd?: boolean = false;
  MixingName: string = '';
  StockLabelId?: number = 0;
}
