import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  ValidationErrors,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import moment from "moment";
import { NzModalService } from "ng-zorro-antd/modal";
import { Modules, Responsibility } from "src/PmsUIApp/Models/Enums";
import {
  AddNotificationGroups,
  NotificationGroups,
  NotificationGroupsModelExport,
  WhatsAppTemplateList,
} from "src/PmsUIApp/Models/Notification";
import { AlertMessageService } from "src/PmsUIApp/Services/AlertMessageService";
import { AuthService } from "src/PmsUIApp/Services/auth.service";
import { environment } from "src/environments/environment";
import { UserModel } from "src/PmsUIApp/Models/UserModel";
import { CustomerModel } from "src/PmsUIApp/Models/SupplierModel";
import { LoadingService } from "src/PmsUIApp/Services/loadingService";
import * as cronstrue from 'cronstrue';
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-notification-group",
  templateUrl: "./notification-group.component.html",
  styleUrls: ["./notification-group.component.css"],
})
export class NotificationGroupComponent implements OnInit {
  WhatsAppTemplateList: WhatsAppTemplateList[];
  constructor(
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private auth: AuthService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private loader: LoadingService
  ) { this.createForm() }

  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false,
  };

  ApiUrl = environment.Api_Url;
  validateForm!: UntypedFormGroup;

  PopUpTitle: string = "Add New Notification Group";
  searchValue = "";
  IsSearching = false;
  isTableLoading: boolean = false;
  isVisible = false;
  isLoading: boolean = false;
  isShownEdit: boolean = false;
  NotificationTitle: string = "";

  NotificationGroupList: NotificationGroups[] = [];
  NotificationGroupListOriginal: NotificationGroups[] = [];
  AddNotificationGroups: NotificationGroups = new NotificationGroups();

  selectedUser: "";
  selectedCustomer: "";

  NotificationGroupListExport: NotificationGroupsModelExport[] = [];
  exportfields: NotificationGroupsModelExport[] = [];
  exportoptions = {
    headers: [
      "Notification Type",
      "User Type",
      "Name",
      "Email",
      "Mobile Number",
      "Is WhatsApp Notification Enabled",
      "Enable To Email",
      "Enable Cc Email",
      "Enable Bcc Email",
    ],
  };

  fields: NotificationGroupsModelExport = new NotificationGroupsModelExport();
  count: number;

  NotificationTypeList: any[] = [
    { id: 1, name: "Reports", value: "Reports", IsReminderScheduleAllowed: true, IsReminderScheduleRequired: true },
    { id: 2, name: "Sales Order", value: "SaleOrderStatus", IsReminderScheduleAllowed: false, IsReminderScheduleRequired: false },
    { id: 3, name: 'Low Stock', value: 'LowStock', IsReminderScheduleAllowed: true, IsReminderScheduleRequired: false },
    { id: 4, name: 'Returnable Out Pass', value: 'ReturnableOutPass', IsReminderScheduleAllowed: true, IsReminderScheduleRequired: true },
  ];
  SelectNotificationType: any;
  UserTypeList: any[] = [
    { id: 1, name: "Internal", value: "Internal" },
    { id: 2, name: "Customer", value: "Customer" },
    { id: 3, name: "External", value: "External" },
  ];

  selectedOrderStage: "";
  isOnlyInternal: false;
  isOnlyCustomer: false;

  showInternalUser = false;
  showCustomerList = false;
  isUserTypeExternal = false;

  UserList: UserModel[] = [];
  CustomerList: CustomerModel[] = [];
  cronScheduleDescription: string = '';

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(
      Modules.NotificationGroup,
      Responsibility.View
    );
    this.permission.Add = this.auth.CheckResponsibility(
      Modules.NotificationGroup,
      Responsibility.Add
    );
    this.permission.Edit = this.auth.CheckResponsibility(
      Modules.NotificationGroup,
      Responsibility.Edit
    );
    this.permission.Delete = this.auth.CheckResponsibility(
      Modules.NotificationGroup,
      Responsibility.Delete
    );

    if (this.permission.View != true) {
      this.router.navigate(["/home/unauthorized"]);
    }



    this.GetAllNotificationsGroup();
    //this.GetAllUsers();
    // this.GetAllCustomer();
  }
  createForm() {
    this.validateForm = this.fb.group({
      CronScheduleExpression: ["", [this.AddNotificationGroups.IsReminderScheduleRequired ? Validators.required : Validators.nullValidator, this.cronExpressionValidator()]],
      NotificationType: ["", [Validators.required, Validators.minLength(3)]],
      Name: [""],
      Email: [
        "",
        [
          Validators.pattern(
            "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}(,[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4})*$"
          ),
        ],
      ],
      UserType: ["", [Validators.required]],
      UserMasterId: [""],
      IsWhatsAppNotificationEnabled: [false],
      WhatsAppTemplateMasterId: [false],
      MobileNumber: [""],
      EnableToEmail: [false],
      EnableCcemail: [false],
      EnableBccemail: [false],
    });

    const cronControl = this.validateForm.get('CronScheduleExpression');
    if (cronControl) {
      cronControl.valueChanges
        .pipe(
          debounceTime(300)
        )
        .subscribe({
          next: (value) => {
            this.updateCronDescription(value);
          },
          error: (error) => {
            console.error('Error in cron expression subscription:', error);
          }
        });
    }
  }
  private cronExpressionValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const cronExpression = control.value;

      if (!cronExpression) {
        return null;
      }

      try {
        // Attempt to parse the cron expression
        cronstrue.toString(cronExpression);
        return null;
      } catch (error) {
        return { invalidCron: true };
      }
    };
  }
  private updateCronDescription(cronExpression: string) {
    try {
      if (cronExpression && this.validateForm.get('CronScheduleExpression').valid) {
        this.cronScheduleDescription = cronstrue.toString(cronExpression);
      } else {
        this.cronScheduleDescription = '';
      }
    } catch (error) {
      this.cronScheduleDescription = '';
    }
    console.log(this.cronScheduleDescription)
  }

  GetAllUsers() {
    this.loader.show();
    let url = this.ApiUrl + "userrolesresponsibility/getalluserdata";
    this.http.get<UserModel[]>(url).subscribe(
      (res) => {
        this.UserList = res;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide();
        this.GetAllUsers();
      }
    );
  }

  GetAllCustomer() {
    this.loader.show();
    let url = this.ApiUrl + "customer/getallcustomers";
    this.http.get<CustomerModel[]>(url).subscribe(
      (res) => {
        this.CustomerList = res;
        this.loader.hide();
      },
      (res) => {
        this.loader.hide()
        this.GetAllCustomer();
      }
    );
  }

  GetAllNotificationsGroup() {
    this.isTableLoading = true;
    this.loader.show();
    let url = this.ApiUrl + "notificationconfig/getallnotificationgroup";

    this.http.get<NotificationGroups[]>(url).subscribe(
      (res) => {
        res.forEach((item, index) => {
          item.SerialNumber = index + 1;
        });
        this.loader.hide();
        this.NotificationGroupList = res;
        this.NotificationGroupListOriginal = res;
        this.isTableLoading = false;
        this.NotificationGroupListExport = res;
        this.exportfields = [];

        this.NotificationGroupListExport.forEach((x) => {
          this.fields = new NotificationGroupsModelExport();

          this.fields.NotificationType = x.NotificationType;
          this.fields.Name = x.Name;
          this.fields.Email = x.Email;
          this.fields.UserType = x.UserType;
          this.fields.IsWhatsAppNotificationEnabled =
            x.IsWhatsAppNotificationEnabled;
          this.fields.MobileNumber = x.MobileNumber;
          this.fields.EnableToEmail = x.EnableToEmail;
          this.fields.EnableCcemail = x.EnableCcemail;
          this.fields.EnableBccemail = x.EnableBccemail;

          this.exportfields.push(this.fields);
        });
      },
      (res) => {
        this.loader.hide();
        this.count++;
        if (this.count < 2) {
          this.GetAllNotificationsGroup();
        }
      }
    );
  }

  onUserTypeChange(value: any) {
    if (value == "Internal") {
      this.showInternalUser = true;
      this.showCustomerList = false;
      this.isUserTypeExternal = false;
      this.AddNotificationGroups.UserMasterId = null;
      this.validateForm.patchValue({ UserMasterId: null })
      this.GetAllUsers();
    } else if (value == "Customer") {
      this.showInternalUser = false;
      this.showCustomerList = true;
      this.isUserTypeExternal = false;
      this.validateForm.patchValue({ UserMasterId: null })
      this.GetAllCustomer();
    } else if (value == "External") {
      this.isUserTypeExternal = true;
      this.showInternalUser = false;
      this.showCustomerList = false;
    }
  }

  onKeydown(event: any) {
    if (
      (event.target.selectionStart === 0 && event.code === "Space") ||
      (event.key === "Enter" && event.keyCode === 13)
    ) {
      event.preventDefault();
      event = this.search();
    }
  }
  ValidateText() {
    this.searchValue.trim();
    this.searchValue = this.searchValue.trim();
  }

  search() {
    var res = this.NotificationGroupListOriginal;
    this.NotificationGroupList = res.filter((item: NotificationGroups) => {
      if (
        item?.NotificationType?.toLowerCase().includes(
          this.searchValue?.toLowerCase()
        )
      ) {
        return true;
      } else if (
        item?.UserType?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else if (
        item?.Name?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else if (
        item?.Email?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else if (
        item?.MobileNumber?.toLowerCase().includes(
          this.searchValue?.toLowerCase()
        )
      ) {
        return true;
      }
      return false;
    });
  }

  reset(): void {
    this.searchValue = "";
    this.search();
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        "notification-export" + exportdate,
        this.exportoptions
      );
  }

  handleDelete(data: NotificationGroups) {
    const modal = this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Are you sure to delete this Group?",
      nzOkDanger: true,
      nzOkText: "Yes",
      nzOnOk: () => this.DeleteNotificationGroup(data),
    });
    setTimeout(() => modal.destroy(), 5000);
  }

  DeleteNotificationGroup(data: NotificationGroups) {
    let url = this.ApiUrl + "notificationconfig/notificationgroupupdate";
    data.Disabled = true;
    this.http.post<any>(url, data).subscribe({
      next: (res) => {
        this.alertService.success("Deleted Successfully");
        this.isLoading = false;
        this.GetAllNotificationsGroup();
      },
      error: (res) => {
        if (res.StatusCode == 400) {
          this.alertService.warning(res.error.ResponseBody);
        } else {
          this.alertService.error(res.error.ResponseBody);
          this.isLoading = false;
        }
      },
    });
  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveNotificationGroup();
  }

  setWhatsAppNotification(event) {
    if (event.target.checked) {
      this.AddNotificationGroups.IsWhatsAppNotificationEnabled = true;
      if (this.validateForm.get("UserType").value == 'Internal') {
        const UserMobileCheck = this.UserList.find(x => x.UserId == this.validateForm.get("UserMasterId").value)
        console.log(UserMobileCheck)
        if (UserMobileCheck.Contact == "") {
          this.alertService.warning("No Mobile Number is saved in Users Master for this user. Messages will not be sent unless it is updated")
        }
      }
      else if (this.validateForm.get("UserType").value == 'Customer') {
        const UserMobileCheck = this.CustomerList.find(x => x.CustomerId == this.validateForm.get("UserMasterId").value)
        if (UserMobileCheck.CustomerContactNumber == "") {
          this.alertService.warning("No Mobile Number present in Customer Master for selected customer. Messages will not be sent unless it is updated")
        }
      }

    } else {
      this.AddNotificationGroups.IsWhatsAppNotificationEnabled = false;
    }
  }

  setEnableToEmail(event) {
    if (event.target.checked) {
      this.AddNotificationGroups.EnableToEmail = true;
      this.validateForm.patchValue({
        EnableToEmail: true,
        EnableCcemail: false,
        EnableBccemail: false,
      });
    } else {
      this.AddNotificationGroups.EnableToEmail = false;
    }
  }

  setEnableCcemail(event) {
    if (event.target.checked) {
      this.AddNotificationGroups.EnableCcemail = true;
      this.validateForm.patchValue({
        EnableToEmail: false,
        EnableCcemail: true,
        EnableBccemail: false,
      });
    } else {
      this.AddNotificationGroups.EnableCcemail = false;
    }
  }

  setEnableBccemail(event) {
    if (event.target.checked) {
      this.AddNotificationGroups.EnableBccemail = true;
      this.validateForm.patchValue({
        EnableToEmail: false,
        EnableCcemail: false,
        EnableBccemail: true,
      });
    } else {
      this.AddNotificationGroups.EnableBccemail = false;
    }
  }
  SetReminderScheduleAllowed(event: any) {
    console.log(event)
    if (!event)
      return;

    this.AddNotificationGroups.NotificationType = event.value;
    if (event.IsReminderScheduleAllowed) {
      this.AddNotificationGroups.IsReminderScheduleAllowed = true;
    }
    else {
      this.AddNotificationGroups.IsReminderScheduleAllowed = false;
    }
  }

  SaveNotificationGroup() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }

    this.AddNotificationGroups.CronScheduleExpression =
      this.validateForm.get("CronScheduleExpression").value;
    this.AddNotificationGroups.NotificationType = this.SelectNotificationType.value;
    this.AddNotificationGroups.UserType =
      this.validateForm.get("UserType").value;
    if (this.AddNotificationGroups.UserType == "External") {
      this.AddNotificationGroups.Name = this.validateForm.get("Name").value;
      this.AddNotificationGroups.Email = this.validateForm.get("Email").value;
      this.AddNotificationGroups.MobileNumber =
        this.validateForm.get("MobileNumber").value;
    } else {
      this.AddNotificationGroups.Name = "";
      this.AddNotificationGroups.Email = "";
      this.AddNotificationGroups.MobileNumber = ""
    }
    if (this.AddNotificationGroups.UserType == "Customer" || this.AddNotificationGroups.UserType == "Internal") {
      this.AddNotificationGroups.UserMasterId =
        this.validateForm.get("UserMasterId").value;
    } else {
      this.AddNotificationGroups.UserMasterId = null
    }
    if (this.AddNotificationGroups.IsWhatsAppNotificationEnabled) {
      this.AddNotificationGroups.WhatsAppTemplateMasterId = this.validateForm.get("WhatsAppTemplateMasterId").value;
    }
    if (!this.Validate(this.AddNotificationGroups)) {
      this.isLoading = false;
      return;
    }

    if (this.isShownEdit) {
      let url = this.ApiUrl + "notificationconfig/notificationgroupupdate";
      this.http
        .post<NotificationGroups>(url, this.AddNotificationGroups)
        .subscribe({
          next: (res) => {
            this.alertService.success(
              "Notification Group Updated Successfully"
            );
            this.isLoading = this.isVisible = false;
            this.GetAllNotificationsGroup();
          },
          error: (res) => {
            this.alertService.error(
              "An error has been occured. Please try again"
            );
            this.isLoading = this.isVisible = false;
          },
        });
    } else {
      let url = this.ApiUrl + "notificationconfig/addnotificationgroup";
      this.http
        .post<AddNotificationGroups>(url, this.AddNotificationGroups)
        .subscribe({
          next: (res) => {
            this.alertService.success("Notification Group Added Successfully");
            this.isLoading = this.isVisible = false;
            this.GetAllNotificationsGroup();
          },
          error: (res) => {
            this.alertService.error(
              "An error has been occured. Please try again"
            );
            this.isLoading = this.isVisible = false;
          },
        });
    }
  }

  Validate(model: NotificationGroups) {
    var Isvalid: boolean = true;
    if (model.CronScheduleExpression == "" && model.IsReminderScheduleRequired) {
      this.alertService.error("Schedule is required for selected notification type");
      Isvalid = false;
    } else if (model.NotificationType == "0" || model.NotificationType == "") {
      this.alertService.error("Select Notification Type");
      Isvalid = false;
    } else if (model.UserType == "0" || model.UserType == "") {
      this.alertService.error("Select User Type");
      Isvalid = false;
    } else if (model.UserType == "Customer" && !model.UserMasterId) {
      this.alertService.error("Select Customer");
      Isvalid = false;
    } else if (model.UserType == "Internal" && !model.UserMasterId) {
      this.alertService.error("Select User");
      Isvalid = false;
    } else if (model.UserType == "External" && model.Name == "") {
      this.alertService.error("Enter Name");
      Isvalid = false;
    } else if (model.UserType == "External" && model.Email == "") {
      this.alertService.error("Enter Email");
      Isvalid = false;
    }

    // else if(model.Name == '') {
    //   this.alertService.error("Enter Name"); Isvalid = false;
    // }
    // else if(model.Email== '') {
    //   this.alertService.error("Enter Email"); Isvalid = false;
    // }
    // else if(model.MobileNumber == '') {
    //   this.alertService.error("Enter Mobile Number"); Isvalid = false;
    // }

    return Isvalid;
  }

  showModal(): void {
    this.PopUpTitle = "Add Notification Group";
    this.NotificationTitle = "Add New Notification Group";
    this.isVisible = true;
    this.isShownEdit = false;
    this.validateForm.reset();
    this.AddNotificationGroups = new NotificationGroups();
    this.isUserTypeExternal = false;
    this.showInternalUser = false;
    this.showCustomerList = false;
    this.GetAllWhatsAppTemplateList();
  }

  editNotificationGroup(value) {
    console.log(value)
    if (value.NotificationGroupUserId) {
      this.PopUpTitle = "Edit Notification Group";
      this.NotificationTitle = "Edit Notification Group";
      this.isVisible = true;

      this.SelectNotificationType = this.NotificationTypeList.find(x => x.value == value.NotificationType);
      if (this.SelectNotificationType.IsReminderScheduleAllowed) {
        this.AddNotificationGroups.IsReminderScheduleAllowed = true;
        if (value.CronScheduleExpression) {
          this.validateForm.patchValue({
            CronScheduleExpression: value.CronScheduleExpression
          });
        }
      } else {
        this.AddNotificationGroups.IsReminderScheduleAllowed = false;
        this.validateForm.patchValue({
          CronScheduleExpression: null
        });
      }
      if (this.SelectNotificationType.IsReminderScheduleRequired) {
        this.AddNotificationGroups.IsReminderScheduleRequired = true;
        this.validateForm.patchValue({
          CronScheduleExpression: value.CronScheduleExpression
        });
      } else {
        this.AddNotificationGroups.IsReminderScheduleRequired = false;
        this.validateForm.patchValue({
          CronScheduleExpression: null
        });
      }

      if (value.UserType == "Internal") {
        this.loader.show();
        let url = this.ApiUrl + "userrolesresponsibility/getalluserdata";
        this.http.get<UserModel[]>(url).subscribe({
          next: (res) => {
            this.UserList = res;
            this.validateForm.patchValue({
              UserMasterId: value.UserMasterId
            });
            this.loader.hide();
          },
          error: (res) => {
            this.loader.hide();
          }
        });
      } else if (value.UserType == "Customer") {
        this.loader.show();
        let url = this.ApiUrl + "customer/getallcustomer";
        this.http.get<CustomerModel[]>(url).subscribe({
          next: (res) => {
            this.CustomerList = res;
            this.validateForm.patchValue({
              UserMasterId: value.CustomerId
            });
            this.loader.hide();
          },
          error: (res) => {
            this.loader.hide();
          }
        });
      }
      if (value.IsWhatsAppNotificationEnabled) {
        let url = this.ApiUrl + "notificationconfig/getwhatsapptemplate";
        this.http.get<WhatsAppTemplateList[]>(url).subscribe({
          next: (res) => {
            this.WhatsAppTemplateList = res;
            this.validateForm.patchValue({
              IsWhatsAppNotificationEnabled: true,
              WhatsAppTemplateMasterId: value.WhatsAppTemplateMasterId
            });
            this.AddNotificationGroups.IsWhatsAppNotificationEnabled = true;
          },
          error: (res) => {
            this.count++;
            if (this.count < 2) {
              this.GetAllWhatsAppTemplateList();
            }
          }
        });
      }
      if (value.EnableToEmail) {
        this.AddNotificationGroups.EnableToEmail = true;
      }
      if (value.EnableCcemail) {
        this.AddNotificationGroups.EnableCcemail = true;
      }
      if (value.EnableBccemail) {
        this.AddNotificationGroups.EnableBccemail = true;
      }
      this.validateForm.patchValue({
        CronScheduleExpression: value.CronScheduleExpression,
        Name: value.Name,
        Email: value.Email,
        UserType: value.UserType,
        MobileNumber: value.MobileNumber,
      });

      this.AddNotificationGroups = new NotificationGroups();
      this.AddNotificationGroups.NotificationGroupUserId = value.NotificationGroupUserId;

      this.isUserTypeExternal = false;
      this.showInternalUser = false;
      this.showCustomerList = false;

      this.onUserTypeChange(value.UserType);
      this.isShownEdit = true;
    }
  }

  handleCancel(): void {
    this.isVisible = false;
    this.validateForm.reset();
  }
  GetAllWhatsAppTemplateList() {
    let url = this.ApiUrl + "notificationconfig/getwhatsapptemplate";
    this.http.get<WhatsAppTemplateList[]>(url).subscribe(
      (res) => {
        this.WhatsAppTemplateList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllWhatsAppTemplateList();
        }
      }
    );
  }
}
