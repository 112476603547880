export class BranchModel {
  BranchId: number = 0;
  BranchName: string = '';
  BranchCode: string = '';
  BranchDesc: string = '';
  BranchAddedDate: string = '';
  BranchAddedBy: string = '';

}

export class TagModel {
  TagId: number = 0;
  TagName: string = '';

  TagDesc: string = '';
  TagAddedDate: string = '';
  TagAddedBy: string = '';

}

export class DeptModel {
  DeptId: number = 0;
  DeptName: string = '';
  DeptCode: string = '';
  DeptDesc: string = '';
  DeptAddedDate: string = '';
  DeptAddedBy: string = '';

}

export class ColorModel {
  ColorId: number = 0;
  ColorName: string = '';
  ColorCode: string = '';
  ColorDesc: string = '';
  ColorAddedDate: string = '';
  ColorAddedBy: string = '';

}

export class ElementModel {
  ElementId: number = 0;
  ElementName: string = '';
  ElementCode: string = '';
  ElementDesc: string = '';
  ElementAddedDate: string = '';
  ElementAddedBy: string = '';

}

export class GrainModel {
  GrainId: number = 0;
  GrainName: string = '';
  GrainCode: string = '';
  Price: number = 0;
  GrainDesc: string = '';
  GrainAddedDate: string = '';
  GrainAddedBy: string = '';

}

export class StoreModel {
  StoreId: number = 0;
  DeptId: number = 0;
  BranchId: number = 0;
  StoreName: string = '';
  DeptCode: string = '';
  BranchCode: string = '';
  BranchName: string = '';
  StoreCode: string = '';
  StoreDesc: string = '';
  StoreAddedDate: string = '';
  StoreAddedBy: string = '';
  IsWorkInProgressStore: boolean = false;
}
export class AdminStoreModel {
  StoreId: number = 0;
  DeptId: number = 0;
  BranchId: number = 0;
  StoreName: string = '';
  DeptCode: string = '';
  BranchCode: string = '';
  StoreCode: string = '';
  IsChecked: boolean = false;


}
export class RackModel {
  StoreId: number = 0;
  RackId: number = 0;
  StoreName: string = '';
  RackName: string = '';
  RackCode: string = '';
  RackDesc: string = '';
  RackAddedDate: string = '';
  RackAddedBy: string = '';

}

export class ProductCategoryModel {
  ProductCategoryId: number = 0;
  ProductCategory: string = '';
  ProductCategoryDesc: string = '';
  ProductCategoryAddedDate: string = '';
  ProductCategoryAddedBy: string = '';
  ProductType: any
}
export class ProductFirstSubCategoryModel {
  ProductFirstSubCategoryId: number = 0;
  ProductCategoryId: number = 0;
  ProductCategory: string = '';
  ProductFirstSubCategory: string = '';
  ProductFirstSubCategoryDesc: string = '';
  ProductFirstSubCategoryAddedDate: string = '';
  ProductFirstSubCategoryAddedBy: string = '';
}
export class ProductSecSubCategoryModel {
  ProductSecSubCategoryId: number = 0;
  ProductFirstSubCategoryId: number = 0;
  ProductCategoryId: number = 0;
  ProductCategory: string = '';
  ProductFirstSubCategory: string = '';
  ProductSecSubCategory: string = '';
  ProductSecSubCategoryDesc: string = '';
  ProductSecSubCategoryAddedDate: string = '';
  ProductSecSubCategoryAddedBy: string = '';
}

export class PaymentTermModel {
  PaymentTermId: number = 0;
  PaymentTerm: string = '';
  NumberOfDays: number = 0;
  PaymentTermDesc: string = '';
  PaymentTermAddedDate: string = '';
  PaymentTermAddedBy: string = '';

}
export class DeliveryTermModel {
  DeliveryTermId: number = 0;
  DeliveryTerm: string = '';
  DeliveryTermDesc: string = '';
  DeliveryTermAddedDate: string = '';
  DeliveryTermAddedBy: string = '';
  NumberOfDays: number = 0;
}

export class MeasurementConversionModel {
  ConversionId: number = 0;
  FromUnit: string = '';
  ToUnit: string = '';
  ConversionValue: number = 0;
  AddedDate: string = '';
  AddedBy: string = '';

}



export class ThicknessModel {
  ThicknessId: number = 0;
  ThicknessNumber: string = '';
  Unit: string = '';
  Code: string = '';
  Description: string = '';
  AddedDate!: any
  AddedBy: string = '';

}

export class WidthMasterModel {
  WidthId: number = 0;
  WidthNumber: string = '';
  Unit: string = '';
  Description: string = '';
  AddedDate!: any
  AddedBy: string = '';
}

export class PostProcessCostingMasterModel {
  PostProcessCostingMasterId: number = 0;
  PostProcessName: string = '';
  SaleOrderType: string = '';
  Cost: number = 0;
  AddedDate!: any
  AddedBy: string = '';
}

export class DesignationModel {
  SerialNo: number = 0;
  DesignationId: number = 0;
  Name: string = '';
  ShortName: string = '';
  AddedBy: string = '';
  AddedDate: string = '';
}
export class OutPassPurposeModel {
  SerialNo: number = 0;
  PurposeId: number = 0;
  PurposeName: string = '';
  PurposeCode: string = '';
  AddedBy: string = '';
  AddedDate: string = '';
}

export class FactoryWorkersModel {
  SerialNo: number = 0;
  WorkerId: number = 0;
  Name: string = '';
  ShortName: string = '';
  WorkShift: string = '';
  DesignationId: number = 0;
  DesignationName: string = '';
  DepartmentId: number = 0;
  AddedBy: string = '';
  AddedDate: string = '';
  DepartmentName: string = '';
}