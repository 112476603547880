<app-SoDrawer></app-SoDrawer>
<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">

        <nz-page-header [nzGhost]="false">
            <nz-page-header-title>Issue List</nz-page-header-title>
            <nz-page-header-subtitle>Manage your Issue here</nz-page-header-subtitle>
            <nz-page-header-extra>
                <button *ngIf="this.permission.View && showPrintBtn" style="margin-right: 5px;" nz-button
                    nzType="primary" (click)="GenerateAndPrintIssueSlip()">
                    Generate Print
                </button>
                <button nz-button nzType="primary" routerLink="/home/issue/add">Add New</button>
            </nz-page-header-extra>

        </nz-page-header>
        <nz-divider></nz-divider>
        <nz-collapse>
            <nz-collapse-panel [nzHeader]="this.filterPanelTxt" [nzActive]="false"
                (nzActiveChange)="onFilterPanelOpen($event)">
                <div nz-col [nzSpan]="24">
                    <div nz-row [nzGutter]="24">
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                    <label> Requested Date (From)</label>
                                    <nz-date-picker nzPlaceHolder="From Date" name="requestedDateFrom"
                                        class="form-control" [(ngModel)]="request.DateFrom" nzAllowClear
                                        (ngModelChange)="onFilterChange()"></nz-date-picker>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                    <label>Requested Date (To)</label>
                                    <nz-date-picker nzPlaceHolder="To Date" name="requestedDateTo" class="form-control"
                                        [(ngModel)]="request.DateTo" nzAllowClear
                                        (ngModelChange)="onFilterChange()"></nz-date-picker>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">Product Type</label>
                                    <nz-select class="form-select mb-2" nzSize="default"
                                        [(ngModel)]="SelectedProductType"
                                        (ngModelChange)="onSelectedProductTypeChange();onFilterChange()" nzAllowClear
                                        nzPlaceHolder="Choose">
                                        <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                                        <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                                        <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">

                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">Category</label>

                                    <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                        [(ngModel)]="CategoryID" name="Category" nzAllowClear nzPlaceHolder="Category"
                                        (ngModelChange)="GetAllFirstCategory($event);onFilterChange()">
                                        <nz-option *ngFor="let s of this.ProductCategoryList;"
                                            [nzValue]="s.ProductCategoryId" [nzLabel]="s.ProductCategory"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">

                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">Sub Category</label>
                                    <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                        [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear
                                        nzPlaceHolder="Sub Category"
                                        (ngModelChange)="GetAllSecondCategory($event);onFilterChange()">
                                        <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;"
                                            [nzValue]="s.ProductFirstSubCategoryId"
                                            [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">

                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">2nd Sub Category</label>
                                    <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                                        [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear
                                        nzPlaceHolder="Second Sub Category"
                                        (ngModelChange)="GetSecondCategoryFilteredProduct();onFilterChange()">
                                        <nz-option *ngFor="let s of this.ProductSecSubCategoryList;"
                                            [nzValue]="s.ProductSecSubCategoryId"
                                            [nzLabel]="s.ProductSecSubCategory"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-col [nzSpan]="8">

                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">Product Name</label>
                                    <nz-select nzShowSearch class="form-select " nzSize="default"
                                        [(ngModel)]="SelectedProductId" name="ProductId" nzAllowClear
                                        nzPlaceHolder="Choose" (ngModelChange)="onFilterChange()">
                                        <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s.ProductId"
                                            [nzLabel]="s.ProductName"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">

                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">Measure unit</label>
                                    <nz-select nzShowSearch class="form-select mb-2" nzSize="default"
                                        [(ngModel)]="SelectedUnit" nzAllowClear nzPlaceHolder="Choose"
                                        (ngModelChange)="onFilterChange()">
                                        <nz-option *ngFor="let s of this.MeasureUnits;" [nzValue]="s.Unit"
                                            [nzLabel]="s.Unit"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">To Store </label>
                                    <nz-select nzShowSearch class="form-select mb-2" [(ngModel)]="ToStoreID"
                                        nzSize="default" nzAllowClear nzPlaceHolder="Choose"
                                        (ngModelChange)="onFilterChange()">
                                        <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId"
                                            [nzLabel]="s.StoreName"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">From Store </label>
                                    <nz-select nzShowSearch class="form-select mb-2" [(ngModel)]="FromStoreID"
                                        nzSize="default" nzAllowClear nzPlaceHolder="Choose"
                                        (ngModelChange)="onFilterChange()">
                                        <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId"
                                            [nzLabel]="s.StoreName"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">Requested By</label>
                                    <nz-select nzShowSearch class="form-select mb-2" nzSize="default"
                                        [(ngModel)]="request.RequestedBy" nzAllowClear nzPlaceHolder="Choose"
                                        (ngModelChange)="onFilterChange()">
                                        <nz-option *ngFor="let s of this.UserList;" [nzValue]="s.Email"
                                            [nzLabel]="s.Name"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">Action By</label>
                                    <nz-select nzShowSearch class="form-select mb-2" nzSize="default"
                                        [(ngModel)]="request.ActionBy" nzAllowClear nzPlaceHolder="Choose"
                                        (ngModelChange)="onFilterChange()">
                                        <nz-option *ngFor="let s of this.UserList;" [nzValue]="s.Email"
                                            [nzLabel]="s.Name"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">Batch No.</label>
                                    <input nz-input name="BatchNo" nzAllowClear placeholder="Enter Batch No."
                                        maxlength="30" [(ngModel)]="request.BatchNo" (keydown)="space($event)"
                                        (ngModelChange)="onFilterChange()" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                    <label class="form-label">Sale Order No.</label>
                                    <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Full Number"
                                        maxlength="30" [(ngModel)]="request.SaleOrderNumber" (keydown)="space($event)"
                                        (ngModelChange)="onFilterChange()" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                    <label class="form-label">Issue Slip No.</label>
                                    <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Full Number"
                                        maxlength="30" [(ngModel)]="request.IssueSlipNumber" (keydown)="space($event)"
                                        (ngModelChange)="onFilterChange()" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                                    <label class="form-label">Issue Serial No.</label>
                                    <input nz-input name="salesOrderNo" nzAllowClear placeholder="Enter Full Number"
                                        maxlength="30" [(ngModel)]="request.IssueId" (keydown)="space($event)"
                                        (ngModelChange)="onFilterChange()" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        <div nz-col [nzSpan]="4">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24">
                                    <label class="form-label">Status </label>
                                    <nz-select nzShowSearch class="form-select mb-2" [(ngModel)]="request.Status"
                                        nzSize="default" nzAllowClear nzPlaceHolder="Choose"
                                        (ngModelChange)="onFilterChange()">
                                        <nz-option nzValue="Pending" nzLabel="Pending"></nz-option>
                                        <nz-option nzValue="Approved" nzLabel="Approved"></nz-option>
                                        <nz-option nzValue="Rejected" nzLabel="Rejected"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </div>
                <div style="float: right; margin-bottom: 2%; margin-right: 10px">
                    <div style="display: flex">
                        <button nz-button nzType="primary" (click)="GetAllProduction()">
                            Apply Filters
                        </button>
                    </div>
                </div>
            </nz-collapse-panel>
        </nz-collapse>
        <nz-divider></nz-divider>
        <div style="padding: 8px;float:right">
            <!-- <nz-date-picker [(ngModel)]="Request.DateFrom" nzPlaceHolder="Start Date"
                style="width: 150px;margin-right: 8px;"></nz-date-picker>
            <nz-date-picker [(ngModel)]="Request.DateTo" nzPlaceHolder="End Date"
                style="width: 150px;margin-right: 8px;"></nz-date-picker>
            <input type="text" style="width: 250px;margin-right: 8px;" nz-input maxlength="30"
                placeholder="Sale Order No. " [(ngModel)]="saleOrderNumber" (keydown)="onKeydown($event)" />
            <button nz-button nzSize="small" nzType="primary" (click)="GetAllProduction()"
                style="margin-right: 8px;">Search</button>
            <button nz-button nzSize="small" (click)="reset()">Reset</button> -->
            <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
                Export</button>
        </div>


        <nz-table [nzPageSize]="100" nzSize="small" [nzScroll]="{ x: '1200px',y:'515px' }" style="width: 100%;"
            #basicTable [nzData]="this.ProductionList" [nzLoading]="isTableLoading" nzBordered nzShowPagination="true"
            nzShowSizeChanger [nzPageSize]="20" [nzPageSizeOptions]=[20,50,100,200,500] [nzShowTotal]="totalTemplate"
            nzShowQuickJumper="true" nzResponsive=true>
            <thead>
                <tr style="font-size: smaller;">
                    <th nzWidth="70px" style="text-align: center" nzLeft *ngIf="this.permission.View">Select</th>
                    <th nzWidth="100px">Issue Serial No.</th>
                    <th nzWidth="170px">Issue Slip No.</th>
                    <th nzWidth="100px" nzLeft>Issue Type</th>
                    <th nzWidth="100px">Request Type</th>
                    <th nzWidth="200px" nzLeft>Product</th>
                    <!--<th nzWidth="150px" nzLeft>Product Code</th>-->
                    <th nzWidth="120px">Sale Order No.</th>
                    <th nzWidth="80px">Demand QTY</th>
                    <th nzWidth="80px">Issued QTY</th>
                    <th nzWidth="140px">Batch No.</th>
                    <th nzWidth="180px">From Store</th>
                    <!--<th nzWidth="150px">From Dept</th>-->
                    <th nzWidth="150px">To Store</th>
                    <th nzWidth="150px">Remark</th>
                    <!--<th nzWidth="150px">To Dept </th>-->
                    <th nzWidth="110px">Added Date Time</th>
                    <th nzWidth="150px">Added By</th>
                    <th nzWidth="110px">Action Date Time</th>
                    <th nzWidth="150px">Action By</th>

                    <th nzWidth="120px" style="text-align:center" nzRight>Status
                        <span nz-icon nzType="caret-up" nzTheme="outline"
                            (click)="onUp('Status',basicTable.data)"></span>
                        <span nz-icon nzType="caret-down" nzTheme="outline"
                            (click)="onDown('Status',basicTable.data)"></span>
                    </th>
                    <th nzWidth="100px" style="text-align:center" nzRight>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data; let i = index">
                    <td nzLeft style="text-align: center" *ngIf="this.permission.View">
                        <input *ngIf="enableIssueSelect(data)" style="margin-right: 5px" [hidden]="data.IsDisabled"
                            type="checkbox" [checked]="data.IsChecked"
                            (change)="data.IsChecked = !data.IsChecked; add()" />
                    </td>
                    <td>{{ data.IssueId }}</td>
                    <td *ngIf="data.IssueSlipId != null"><a class="btn-link" (click)="PrintSlip(data.IssueSlipId)">{{
                            data.IssueSlipNumber }} </a></td>
                    <td *ngIf="data.IssueSlipId == null">{{ data.IssueSlipNumber }}</td>
                    <td nzLeft>{{ data.SaleOrderId==null?'Store':'Workplan' }}</td>
                    <td>{{ data.RequestType }}</td>
                    <td nzLeft>{{ data.ProductName }}</td>
                    <!--<td nzLeft>{{ data.ProductCode }}</td>-->
                    <td><nz-tag *ngIf="data.SaleOrderId!=null" [nzColor]="'blue'"><a
                                (click)="OpenSaleOrderViewPop(data)"> {{ data.SaleOrderNumber}}
                            </a></nz-tag></td>
                    <td>{{ data.DemandQuantity }}</td>
                    <td>{{ data.Quantity }}</td>
                    <td>{{ data.Batch }}</td>
                    <td>{{ data.FromStoreName }}</td>
                    <!--<td>{{ data.FromDeptName }}</td>-->
                    <td>{{ data.ToStoreName }}</td>
                    <td>{{ data.Remark }}</td>
                    <!--<td>{{ data.ToDeptName }}</td>-->
                    <td>{{ data.CreatedDate | DatetimeConverter }}</td>
                    <td>{{ data.CreatedBy }}</td>
                    <td>{{ data.ActionDate | DatetimeConverter }}</td>

                    <td>{{ data.ActionBy }}</td>

                    <td nzRight style="text-align:center">{{ data.Status }}</td>
                    <td nzRight style="text-align:center">
                        <div>
                            <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                                class="btn btn-sm btn-light-primary" style="line-height:0">
                                Action
                                <span nz-icon nzType="down"></span>
                            </button>
                            <nz-dropdown-menu #menu="nzDropdownMenu">
                                <ul nz-menu nzSelectable>
                                    <li style="width: 120px;"
                                        *ngIf="data.Status=='Pending' && data.isAction==true && data.IssueSlipId != null"
                                        nz-menu-item (click)="OpenViewPop(data)">Approve/Reject</li>
                                    <li style="width: 120px;"
                                        *ngIf="data.IssueSlipId != null && data.Status == 'Pending'" nz-menu-item
                                        (click)="PrintSlip(data.IssueSlipId)">Print Issue Slip</li>
                                    <li style="width: 120px;"
                                        *ngIf="data.IssueSlipId != null && data.Status == 'Approved'" nz-menu-item
                                        (click)="PrintSlip(data.IssueSlipId)">Print Approved Slip</li>
                                </ul>
                            </nz-dropdown-menu>
                        </div>
                    </td>


                    <!--<td>
  <button class="btn btn-sm btn-light-primary" (click)="OpenEditPop(data)">Edit</button> &nbsp;
  <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)">Delete</button>
        </td>-->
                </tr>
            </tbody>
        </nz-table>




    </div>
</div>

<nz-modal [nzWidth]="750" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisible" [nzTitle]="modalTitle"
    [nzContent]="modalContent" [nzFooter]=null (nzOnCancel)="handleCancel()">
    <ng-template #modalTitle>Issue Action</ng-template>

    <ng-template #modalContent>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="2">

            </div>
            <div nz-col [nzSpan]="20">
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                    <label><b>Demand QTY: {{ this.Production.DemandQuantity}}</b></label>
                </nz-form-control>
                <!-- <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                    <label><b>Issue QTY:</b></label>
                    <nz-input-number class="form-select mb-2" nzSize="default" style="margin-left: 5px;" [(ngModel)]="Production.IssueQuantity"
                        nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                </nz-input-number>
                </nz-form-control> -->
                <nz-radio-group [(ngModel)]="approvalMode" (ngModelChange)="onApprovalModeChange()"
                    style="margin: 10px;">
                    <label nz-radio nzValue="Manual" [nzDisabled]="HasStockLabel">Manual</label>
                    <label nz-radio nzValue="Manual - Multi Batch" [nzDisabled]="HasStockLabel">Manual - Multi Batch</label>
                    <label nz-radio nzValue="Scan">Scan Barcodes</label>
                </nz-radio-group>
                <ng-container *ngIf="RequestType === 'Simple' && approvalMode === 'Manual'">
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                        <label class="required form-label">Supplier</label>
                        <nz-select class="form-select " name="SupplierList" [(ngModel)]="SelectedSupplier"
                            [ngModelOptions]="{standalone: true}" nzAllowClear nzPlaceHolder="Choose"
                            (ngModelChange)="OnSupplierDdlchange()">
                            <nz-option *ngFor="let s of this.UniqueSupplierList;" [nzValue]="s.SupplierId"
                                [nzLabel]="s.SupplierName"></nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                        <label class="required form-label">Batch </label>
                        <nz-select [disabled]="IsBatch" nzShowSearch class="form-select " name="BatchList"
                            [(ngModel)]="SelectedBatch" [ngModelOptions]="{standalone: true}" nzAllowClear
                            nzPlaceHolder="Choose" (ngModelChange)="OnBatchDdlchange()">
                            <nz-option *ngFor="let s of this.BatchList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                        <label class="required form-label">From Store - Racks </label>
                        <nz-select [disabled]="IsRack" nzShowSearch class="form-select " name="BatchList"
                            [(ngModel)]="SelectedRackId" [ngModelOptions]="{standalone: true}" nzAllowClear
                            nzPlaceHolder="Choose" (ngModelChange)="OnRackDdlchange()">
                            <nz-option *ngFor="let s of this.StoreRackList;" [nzValue]="s.RackId"
                                [nzLabel]="s.RackName"></nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                        <label class="required form-label">Barcode</label>
                        <nz-select [disabled]="IsBarcode" nzShowSearch class="form-select " name="Barcode"
                            [(ngModel)]="SelectedBarcode" [ngModelOptions]="{standalone: true}" nzAllowClear
                            nzPlaceHolder="Choose" (ngModelChange)="OnBarCodeDdlchange()">
                            <nz-option *ngFor="let s of this.BarcodeList;" [nzValue]="s" [nzLabel]="s"></nz-option>
                        </nz-select>
                    </nz-form-control>
                    <nz-form-control class="mt-2" [nzSpan]="24">
                        <label class="form-label">Available Qty.</label>
                        <label>{{availableQuantity}}</label>
                    </nz-form-control>
                </ng-container>
                <ng-container *ngIf="RequestType === 'Simple' && approvalMode === 'Manual - Multi Batch'">
                    <nz-table #batchTable [nzData]="selectedBatches" nzSize="small" [nzShowPagination]="false">
                        <thead>
                            <tr>
                                <th>Supplier</th>
                                <th>Batch</th>
                                <th>Store - Rack</th>
                                <th>Available Qty</th>
                                <th>Issue Qty</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let batch of batchTable.data">
                                <td>{{batch.SupplierName}}</td>
                                <td>{{batch.BatchNo}}</td>
                                <td>{{batch.StoreName}} - {{batch.RackName}}</td>
                                <td>{{batch.AvailableQuantity}}</td>
                                <td>
                                    <nz-input-number [(ngModel)]="batch.IssueQuantity" [nzMin]="0"
                                        [nzMax]="batch.AvailableQuantity" [nzStep]="1"
                                        (ngModelChange)="calculateTotalIssueQuantity()">
                                    </nz-input-number>
                                </td>
                                <td>
                                    <button nz-button nzType="link" nzDanger (click)="removeBatch(batch)">
                                        <i nz-icon nzType="delete"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </nz-table>

                    <div style="margin-top: 10px;">
                        <button nz-button nzType="dashed" (click)="showBatchSelectionModal()">
                            <i nz-icon nzType="plus"></i>Add Batch
                        </button>
                    </div>

                    <nz-form-control class="mt-2" [nzSpan]="24">
                          <label class="form-label">Total Issue Quantity: {{totalIssueQuantity}}</label>
                    </nz-form-control>
                </ng-container>
                <ng-container *ngIf="approvalMode === 'Scan'" style="margin-bottom: 10px;">
                    <div>
                        <button type="button" nzSize='small' class="btn btn-sm btn-icon btn-success"
                            (click)="openBarcodeScanner()" title="Mobile Scanner">
                            <span class="svg-icon svg-icon-2">
                                <i nz-icon nzType="mobile" nzTheme="outline"></i>
                            </span>
                        </button>&nbsp;
                        <button type="button" nzSize='small' class="btn btn-sm btn-icon btn-success"
                            (click)="openExternalDeviceInput()" title="External Device Scanner">
                            <span class="svg-icon svg-icon-2">
                                <i nz-icon nzType="scan" nzTheme="outline"></i>
                            </span>
                        </button>&nbsp;
                        <button type="button" nzSize='small' class="btn btn-sm btn-icon btn-success"
                            (click)="openManualInput()" title="Manual Serial No. Entry">
                            <span class="svg-icon svg-icon-2">
                                <i nz-icon nzType="key" nzTheme="outline"></i>
                            </span>
                        </button>
                    </div>
                    <div style="margin: 10px;">
                        <button nz-button nzType="primary" (click)="showScannedLabels()">{{ShowScannedBtntext}}</button>
                    </div>
                    <nz-list *ngIf="scannedLabelsVisible" [nzDataSource]="scannedLabels" nzBordered nzSize="small"
                        style="margin-top: 10px;">
                        <nz-list-item *ngFor="let label of scannedLabels">
                            {{ label.SerialNo }} - {{ label.ProductName }} - {{ label.Quantity }}
                        </nz-list-item>
                    </nz-list>
                    <div style="margin: 10px;">
                        <p><label><b>Total Scanned Labels: {{scannedLabels.length}}</b></label></p>
                        <p><label><b>Total Quantity: {{TotalScannedQty}}</b></label></p>
                    </div>
                </ng-container>
                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element" style="margin-top: 10px;">
                    <label><b>Issue QTY:</b></label>
                    <nz-input-number *ngIf="approvalMode == 'Manual' || approvalMode == 'Manual - Multi Batch'" [disabled]="isIssueQuantity"
                        class="form-select mb-2" nzSize="default" style="margin-left: 5px;"
                        [(ngModel)]="Production.IssueQuantity" nzPlaceHolder="Choose"
                        [ngModelOptions]="{standalone: true}">
                    </nz-input-number>
                    <label *ngIf="approvalMode == 'Scan'">{{Production.IssueQuantity}}</label>
                </nz-form-control>
                <nz-divider></nz-divider>
                <div class="text-center" *ngIf="isApproveButtonVisible">
                    <button nz-button nzType="primary" [nzLoading]="isLoading" style="background: green;"
                        (click)="OnSubmit('Approved')">Approve</button>
                    <button nz-button nzType="primary" [nzLoading]="isLoading" style="background: red;"
                        (click)="OnSubmit('Rejected')">Reject</button>
                </div>
            </div>

        </div>
    </ng-template>
</nz-modal>

<!--<nz-drawer [nzClosable]="true"
           [nzVisible]="isVisible"
           nzPlacement="right"
           [nzWidth]="1000"
           nzTitle="Purchase Order details"
           (nzOnClose)="handleCancel()">
  <ng-container *nzDrawerContent>
    <nz-descriptions nzBordered nzLayout="vertical" *ngIf="PurchaseOrder">
      <nz-descriptions-item nzTitle="Order No" nzColon="true"><b>{{PurchaseOrder.Ponumber}}</b> </nz-descriptions-item>
      <nz-descriptions-item nzTitle="Order Date" [nzSpan]="2"><b>{{PurchaseOrder.PocreationDate}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Supplier Name" [nzSpan]="2"><b>{{PurchaseOrder.SupplierName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Reference#"><b>{{PurchaseOrder.Reference}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Delivery Terms"> <b>{{PurchaseOrder.DeliveryTerm}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Shipment Preference"><b>{{PurchaseOrder.TransportCompanyName}}</b></nz-descriptions-item>
      <nz-descriptions-item nzTitle="Payment Terms"><b>{{PurchaseOrder.PaymentTerm}}</b></nz-descriptions-item>-->

<!--<nz-descriptions-item nzTitle="View Invoice">
      <a class="btn btn-sm btn-light-info" *ngIf="this.NewStock.Invoice.InvoiceFile != null" [href]="NewStock.Invoice.InvoiceFile" target="_blank">Link to Invoice</a>
      <span *ngIf="this.NewStock.Invoice.InvoiceFile == null">No invoice file found</span>
    </nz-descriptions-item>-->

<!--</nz-descriptions>
    <nz-divider></nz-divider>
    <nz-table nzSize="small" [nzData]="['']" #basicTable1 nzBordered>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Product</th>
          <th>Unit</th>
          <th>Grade</th>
          <th>Rate</th>
          <th>Quantity</th>
          <th>Amount</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of PurchaseOrder.PurchaseOrderProduct;let i=index">
          <td>{{i+1}}</td>
          <td>{{ data.ProductName }}</td>
          <td>{{ data.Unit }}</td>
          <td>{{ data.Grade }}</td>
          <td>{{ data.Rate }}</td>
          <td>{{ data.Quantity }}</td>
          <td>{{ data.Amount }}</td>

        </tr>

      </tbody>
      <tfoot *ngIf="this.PurchaseOrder.PurchaseOrderProduct.length>0">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th>Total</th>
          <th><input nz-input name="GrandTotal" readonly placeholder="Grand Total" value="{{PurchaseOrder.PototalAmount}}" /></th>
          <th></th>-->
<!-- <th nzWidth="20%">Action</th> -->
<!--</tr>
      </tfoot>
    </nz-table>
  </ng-container>
</nz-drawer>-->

<ng-template #totalTemplate let-total>Total {{ this.totalItemsCount }} items</ng-template>

<nz-modal 
    [(nzVisible)]="isBatchSelectionVisible" 
    [nzTitle]="'Select Batch'"
    [nzWidth]="800"
    (nzOnCancel)="handleBatchSelectionCancel()"
    [nzFooter]="null">
    <ng-container *nzModalContent>
        <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="12">
                <nz-form-control [nzSpan]="24">
                    <label class="required form-label">Supplier</label>
                    <nz-select 
                        class="form-select" 
                        [(ngModel)]="tempBatchSelection.SupplierId"
                        (ngModelChange)="onTempSupplierChange($event)"
                        nzAllowClear 
                        nzPlaceHolder="Choose">
                        <nz-option 
                            *ngFor="let s of UniqueSupplierList" 
                            [nzValue]="s.SupplierId"
                            [nzLabel]="s.SupplierName">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </div>
            <div nz-col [nzSpan]="12">
                <nz-form-control [nzSpan]="24">
                    <label class="required form-label">Batch</label>
                    <nz-select 
                        class="form-select"
                        [(ngModel)]="tempBatchSelection.BatchNo"
                        (ngModelChange)="onTempBatchChange($event)"
                        nzShowSearch
                        nzAllowClear 
                        nzPlaceHolder="Choose">
                        <nz-option 
                            *ngFor="let batch of filteredBatchList" 
                            [nzValue]="batch"
                            [nzLabel]="batch">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </div>
            <div nz-col [nzSpan]="12">
                <nz-form-control [nzSpan]="24">
                    <label class="required form-label">Store - Rack</label>
                    <nz-select 
                        class="form-select"
                        [(ngModel)]="tempBatchSelection.RackId"
                        (ngModelChange)="onTempRackChange($event)"
                        nzShowSearch
                        nzAllowClear 
                        nzPlaceHolder="Choose">
                        <nz-option 
                            *ngFor="let rack of filteredRackList" 
                            [nzValue]="rack.RackId"
                            [nzLabel]="rack.StoreName + ' - ' + rack.RackName">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </div>
            <div nz-col [nzSpan]="12">
                <nz-form-control [nzSpan]="24">
                    <label class="required form-label">Issue Quantity</label>
                    <nz-input-number
                        class="form-select"
                        [(ngModel)]="tempBatchSelection.IssueQuantity"
                        [nzMin]="0"
                        [nzMax]="tempBatchSelection.AvailableQuantity"
                        [nzStep]="1">
                    </nz-input-number>
                    <div class="mt-2">
                        <small>Available: {{tempBatchSelection.AvailableQuantity || 0}}</small>
                    </div>
                </nz-form-control>
            </div>
        </div>
        <div class="text-center mt-4">
            <button 
                nz-button 
                nzType="primary" 
                [disabled]="!isValidBatchSelection()"
                (click)="addBatchSelection()">
                Add Batch
            </button>
        </div>
    </ng-container>
</nz-modal>