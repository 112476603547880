<img src="/assets/img/logo-globe.jpg" style="display:none" (load)="CheckOnload()" />
<div #barcodeContainer>
  <div *ngFor="let data of this.StockLabel" class="label-container">
    <table class="companytable">
      <tr>
        <td>
          <div class="container1">
            <div class="image">
              <img src="/assets/img/logo-globe.jpg" />
            </div>
            <div>
              <b style="text-transform: uppercase;font-size: 15px;margin-top: 10px;">{{ Company.CompanyName }}</b>
              <p style="font-size: 11px;margin-top: 5px;">{{Company.Address}}</p>
              <p style="font-size: 11px;">{{Company.Address2ndLine}}</p>
            </div>
          </div>
          <div class="company">
            <table style="border-top: 1px solid; border-bottom: 1px solid; margin-top: 10px;width: 100%; ">
              <b>
                <tr>
                  <td><b>Name:</b></td>
                  <td style="padding-left:10px;font-size: larger;"><b>{{data.ProductName}}</b></td>
                </tr>
                <tr>
                  <td><b>Batch:</b></td>
                  <td style="padding-left:10px;font-size: medium;"><b>{{data.BatchNo}}</b></td>
                </tr>
              </b>
            </table>
          </div>
          <div class="barcode-container">
            <svg class="barcode"></svg>
          </div>
          <div class="barcode-shortcode">
            <p>{{data.ShortCode}}</p>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>