import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { NzModalService } from "ng-zorro-antd/modal";
import { Modules, Responsibility } from "src/PmsUIApp/Models/Enums";
import {
  AddEmailGroupMapping,
  EmailGroupMappingList,
  EmailGroupMappingModelExport,
  NotificationGroups,
  NotificationGroupsModelExport,
  StagesTableModel,
} from "src/PmsUIApp/Models/Notification";
import { AlertMessageService } from "src/PmsUIApp/Services/AlertMessageService";
import { AuthService } from "src/PmsUIApp/Services/auth.service";
import { environment } from "src/environments/environment";
import moment from "moment";

@Component({
  selector: "app-email-group-mapping",
  templateUrl: "./email-group-mapping.component.html",
  styleUrls: ["./email-group-mapping.component.css"],
})
export class EmailGroupMappingComponent {
  constructor(
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private auth: AuthService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) { }

  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false,
  };

  ApiUrl = environment.Api_Url;
  validateForm!: UntypedFormGroup;

  PopUpTitle: string = "Add New Notification Group";
  searchValue = "";
  IsSearching = false;
  isTableLoading: boolean = false;
  isVisible = false;
  isLoading: boolean = false;
  isShownEdit: boolean = false;
  EmailGroupMappingTitle: string = "";

  EmailGroupMappingList: EmailGroupMappingList[] = [];
  EmailGroupMappingOriginal: EmailGroupMappingList[] = [];
  AddEmailGroupMapping: EmailGroupMappingList = new EmailGroupMappingList();

  EmailGroupMappingExport: EmailGroupMappingModelExport[] = [];
  exportfields: any[] = [];
  exportoptions = {
    headers: [
      "Stage Name",
      "Notification Group User Name",
      "Enabled",
      "Added By",
      "Added Date",
    ],
  };

  fields: EmailGroupMappingModelExport = new EmailGroupMappingModelExport();
  count: number;

  NotificationGroupList: any[] = [];

  OrderStages: any[] = [
    { id: 1, name: "Order Received", value: "OrderReceived" },
    { id: 2, name: "In Production Planning", value: "InProductionPlanning" },
    { id: 3, name: "Removed Planning", value: "RemovedPlanning" },
    { id: 4, name: "Starting Production", value: "StartingProduction" },
    { id: 5, name: "Production Completed", value: "ProductionCompleted" },
    {
      id: 6,
      name: "Final Inspection Completed",
      value: "FinalInspectionCompleted",
    },
    { id: 7, name: "Ready To Dispacth", value: "ReadyToDispacth" },
    { id: 8, name: "Order Dispatched", value: "OrderDispatched" },
  ];

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(
      Modules.NotificationEmailGroupMapping,
      Responsibility.View
    );
    this.permission.Add = this.auth.CheckResponsibility(
      Modules.NotificationEmailGroupMapping,
      Responsibility.Add
    );
    this.permission.Edit = this.auth.CheckResponsibility(
      Modules.NotificationEmailGroupMapping,
      Responsibility.Edit
    );
    this.permission.Delete = this.auth.CheckResponsibility(
      Modules.NotificationEmailGroupMapping,
      Responsibility.Delete
    );

    if (this.permission.View != true) {
      this.router.navigate(["/home/unauthorized"]);
    }

    this.validateForm = this.fb.group({
      StageId: ["", [Validators.required]],
      NotificationGroupUserId: ["", [Validators.required]],
      Enabled: [false],
    });

    this.GetAllNotificationsGroup();
    this.GetAllEmailGroupList();
  }

  GetAllNotificationsGroup() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "notificationconfig/getallnotificationgroup";

    this.http.get<NotificationGroups[]>(url).subscribe(
      (res) => {
        this.NotificationGroupList = res;
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllNotificationsGroup();
        }
      }
    );
  }

  GetAllEmailGroupList() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "notificationconfig/getemailgroupmapping";

    this.http.get<EmailGroupMappingList[]>(url).subscribe(
      (res) => {
        res.forEach((item, index) => {
          item.SerialNumber = index + 1;
        });

        this.EmailGroupMappingList = res;
        this.EmailGroupMappingOriginal = res;
        this.isTableLoading = false;
        this.EmailGroupMappingExport = res;
        this.exportfields = [];

        this.EmailGroupMappingExport.forEach((x) => {
          this.fields = new EmailGroupMappingModelExport();

          this.fields.StageName = x.StageName;
          this.fields.NotificationGroupUserName = x.NotificationGroupUserName;
          this.fields.Enabled = x.Enabled;
          this.fields.AddedBy = x.AddedBy;
          this.fields.AddedDate = x.AddedDate;
          this.exportfields.push(this.fields);
        });
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllEmailGroupList();
        }
      }
    );
  }

  onKeydown(event: any) {
    if (
      (event.target.selectionStart === 0 && event.code === "Space") ||
      (event.key === "Enter" && event.keyCode === 13)
    ) {
      event.preventDefault();
      event = this.search();
    }
  }
  ValidateText() {
    this.searchValue.trim();
    this.searchValue = this.searchValue.trim();
  }

  search() {
    var res = this.EmailGroupMappingOriginal;
    this.EmailGroupMappingList = res.filter((item: EmailGroupMappingList) => {
      if (
        item?.StageName?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else if (
        item?.NotificationGroupUserName?.toLowerCase().includes(
          this.searchValue?.toLowerCase()
        )
      ) {
        return true;
      } else if (
        item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  }

  reset(): void {
    this.searchValue = "";
    this.search();
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        "EmailGroupMapping" + exportdate,
        this.exportoptions
      );
  }

  handleDelete(data: EmailGroupMappingList) {
    const modal = this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Are you sure to delete this Config?",
      nzOkDanger: true,
      nzOkText: "Yes",
      nzOnOk: () => this.DeleteEmailGroupMapping(data),
    });
    setTimeout(() => modal.destroy(), 5000);
  }

  DeleteEmailGroupMapping(data: EmailGroupMappingList) {
    let url = this.ApiUrl + "notificationconfig/emailgroupedit";
    data.Disabled = true;
    this.http.post<any>(url, data).subscribe({
      next: (res) => {
        this.alertService.success("Delete Successfully");
        this.isLoading = false;
        this.GetAllEmailGroupList();
      },
      error: (res) => {
        if (res.StatusCode == 400) {
          this.alertService.warning(res.error.ResponseBody);
        } else {
          this.alertService.error(res.error.ResponseBody);
          this.isLoading = false;
        }
      },
    });
  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveEmailMapping();
  }

  SaveEmailMapping() {
    this.AddEmailGroupMapping.StageId = this.validateForm.get("StageId").value;
    this.AddEmailGroupMapping.NotificationGroupUserId = this.validateForm.get(
      "NotificationGroupUserId"
    ).value;
    this.AddEmailGroupMapping.Enabled = this.validateForm.get("Enabled").value;

    if (!this.Validate(this.AddEmailGroupMapping)) {
      this.isLoading = false;
      return;
    }

    if (this.isShownEdit) {
      let url = this.ApiUrl + "notificationconfig/emailgroupedit";
      this.http
        .post<EmailGroupMappingList>(url, this.AddEmailGroupMapping)
        .subscribe({
          next: (res) => {
            this.alertService.success("Email Mapping Updated Successfully");
            this.isLoading = this.isVisible = false;
            this.GetAllEmailGroupList();
          },
          error: (res) => {
            this.alertService.error(
              "An error has been occured. Please try again"
            );
            this.isLoading = this.isVisible = false;
          },
        });
    } else {
      let url = this.ApiUrl + "notificationconfig/addemailmappings";
      this.http
        .post<AddEmailGroupMapping>(url, this.AddEmailGroupMapping)
        .subscribe({
          next: (res) => {
            this.alertService.success("Email Mapping Added Successfully");
            this.isLoading = this.isVisible = false;
            this.GetAllEmailGroupList();
          },
          error: (res) => {
            this.alertService.error(
              "An error has been occured. Please try again"
            );
            this.isLoading = this.isVisible = false;
          },
        });
    }
  }

  Validate(model: any) {
    var Isvalid: boolean = true;
    if (model.StageId == "0" || model.StageId == "") {
      this.alertService.error("Select Stage Name");
      Isvalid = false;
    } else if (
      model.NotificationGroupUserId == "0" ||
      model.NotificationGroupUserId == ""
    ) {
      this.alertService.error("Select Notification Name");
      Isvalid = false;
    }
    return Isvalid;
  }

  showModal(): void {
    this.PopUpTitle = "Add Email Group Mapping ";
    this.EmailGroupMappingTitle = "Add New Email Group Mapping ";
    this.isVisible = true;
    this.isShownEdit = false;
  }

  editEmailGrouping(value) {
    if (value.EmailGroupMappingId) {
      this.PopUpTitle = "Edit Email Group Mapping ";
      this.EmailGroupMappingTitle = "Edit Email Group Mapping ";
      this.isVisible = true;

      this.validateForm.setValue({
        StageId: value.StageId,
        NotificationGroupUserId: value.NotificationGroupUserId,
        Enabled: value.Enabled,
      });

      this.AddEmailGroupMapping = new EmailGroupMappingList();
      this.AddEmailGroupMapping.EmailGroupMappingId = value.EmailGroupMappingId;

      this.isShownEdit = true;
    }
  }

  handleCancel(): void {
    this.isVisible = false;
    this.validateForm.reset();
  }

  setEnabled(event) {
    if (event.target.checked) {
      this.validateForm.patchValue({
        Enabled: true,
      });
    }
  }
}
