import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LabelFullDetailsModel, StockLabelResponseModel } from '../../../Models/BarcodeLabelModel';
import { ScannerMode } from '../../../Models/Enums';
import { AlertMessageService } from '../../../Services/AlertMessageService';
import { FullDetailModalState } from '../../../Models/BarcodeLabelModel';

@Injectable({
    providedIn: 'root'
})
export class BarcodeScannerService {
    private barcodeValueSubject = new BehaviorSubject<string | null>(null);
    public barcodeValue$ = this.barcodeValueSubject.asObservable();

    private labelDetailsSubject = new BehaviorSubject<LabelFullDetailsModel | null>(null);
    public labelDetails$ = this.labelDetailsSubject.asObservable();

    private scannerModeSubject = new BehaviorSubject<ScannerMode>(ScannerMode.Closed);
    public scannerMode$ = this.scannerModeSubject.asObservable();

    private actionSubject = new BehaviorSubject<string | null>(null);
    public action$ = this.actionSubject.asObservable();

    private scannedLabelsSubject = new BehaviorSubject<StockLabelResponseModel[]>([]);
    public scannedLabels$ = this.scannedLabelsSubject.asObservable();

    private scannerStateSubject = new BehaviorSubject<boolean>(false);
    public scannerState$: Observable<boolean> = this.scannerStateSubject.asObservable();

    private currentProductIdSubject = new BehaviorSubject<number>(0);
    public currentProductId$ = this.currentProductIdSubject.asObservable();

    private currentFromStoreIdSubject = new BehaviorSubject<number>(0);
    public currentFromStoreId$ = this.currentFromStoreIdSubject.asObservable();

    private viewTypeSubject = new BehaviorSubject<FullDetailModalState>({ isVisible: false, action: null, SerialNo: null });
    public viewType$ = this.viewTypeSubject.asObservable();

    constructor(private alertService: AlertMessageService) { }

    setScannerMode(mode: ScannerMode, action: string | null = null): void {
        this.scannerModeSubject.next(mode);
        if (action) {
            console.log('action', action)
            this.actionSubject.next(action);
        }
        this.scannerStateSubject.next(mode !== ScannerMode.Closed);
    }

    setBarcodeValue(value: string): void {
        this.barcodeValueSubject.next(value);
    }

    setLabelDetails(details: LabelFullDetailsModel): void {
        this.labelDetailsSubject.next(details);
    }
    getLabelDetails(): LabelFullDetailsModel | null {
        return this.labelDetailsSubject.getValue();
    }

    getCurrentMode(): ScannerMode {
        return this.scannerModeSubject.getValue();
    }
    getCurrentAction(): string | null {
        console.log('actionget', this.actionSubject.getValue())
        return this.actionSubject.getValue();
    }
    setCurrentAction(action: string): void {
        this.actionSubject.next(action);
    }
    resetValues(): void {
        this.barcodeValueSubject.next(null);
        this.labelDetailsSubject.next(null);
        this.actionSubject.next(null);
        this.scannedLabelsSubject.next([]);
        this.currentProductIdSubject.next(0);
        this.currentFromStoreIdSubject.next(0);
        this.viewTypeSubject.next(null);
    }

    addScannedLabel(label: StockLabelResponseModel, ProductId: number, FromStoreId: number) {
        if (label.ProductId != ProductId) {
            this.alertService.error('Scanned label is not matching with the current product request you are approving.');
            return;
        }
        if (label.CurrentStoreId != FromStoreId) {
            this.alertService.error('Scanned label is not from your Store because it is not matching From Store name of this request.');
            return;
        }
        const currentLabels = this.scannedLabelsSubject.getValue();
        if (!currentLabels.some(l => l.StockLabelId === label.StockLabelId && l.ProductId === ProductId && l.CurrentStoreId === FromStoreId)) {
            this.scannedLabelsSubject.next([...currentLabels, label]);
        }
    }
    getScannedLabels(): StockLabelResponseModel[] {
        return this.scannedLabelsSubject.getValue();
    }
    getScannedLabelsIds(): number[] {
        return this.scannedLabelsSubject.getValue().map(label => label.StockLabelId);
    }
    clearScannedLabels() {
        this.scannedLabelsSubject.next([]);
    }
    setCurrentProductId(id: number) {
        this.currentProductIdSubject.next(id);
    }
    getCurrentProductId(): number {
        return this.currentProductIdSubject.getValue();
    }
    setCurrentFromStoreId(id: number) {
        this.currentFromStoreIdSubject.next(id);
    }
    getCurrentFromStoreId(): number {
        return this.currentFromStoreIdSubject.getValue();
    }
    // Add method to open full detail view
    openFullDetailView(data: FullDetailModalState): void {
        this.viewTypeSubject.next(data);
        // Trigger manual fetch
        this.setBarcodeValue(data.SerialNo);
    }
    getBarcodeValue(): string | null {
        return this.barcodeValueSubject.getValue();
    }
}
