<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">

    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Stock Label List</nz-page-header-title>
      <nz-page-header-subtitle>List of all stock labels</nz-page-header-subtitle>
      <nz-page-header-extra>

      </nz-page-header-extra>

    </nz-page-header>
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="this.filterPanelTxt" [nzActive]="false"
        (nzActiveChange)="onFilterPanelOpen($event)">
        <div nz-col [nzSpan]="24">
          <div nz-row [nzGutter]="24">

            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Product Type</label>
                  <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
                    (ngModelChange)="onSelectedProductTypeChange()" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
                    <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
                    <nz-option nzValue="Internal-Use" nzLabel="Internal-Use"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Category</label>

                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default" [(ngModel)]="CategoryID"
                    name="Category" nzAllowClear nzPlaceHolder="Category" (ngModelChange)="GetAllFirstCategory($event)">
                    <nz-option *ngFor="let s of this.FilteredProductCategoryList;" [nzValue]="s.ProductCategoryId"
                      [nzLabel]="s.ProductCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Sub Category</label>
                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                    [(ngModel)]="FirstCategoryID" name="FirstCategoryID" nzAllowClear nzPlaceHolder="Sub Category"
                    (ngModelChange)="GetAllSecondCategory($event)">
                    <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;"
                      [nzValue]="s.ProductFirstSubCategoryId" [nzLabel]="s.ProductFirstSubCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">

              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">2nd Sub Category</label>
                  <nz-select #microwaveRef class="form-select " nzShowSearch nzSize="default"
                    [(ngModel)]="SecondCategoryID" name="SecondCategoryID" nzAllowClear
                    nzPlaceHolder="Second Sub Category" (ngModelChange)="GetSecondCategoryFilteredProduct()">
                    <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId"
                      [nzLabel]="s.ProductSecSubCategory"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="8">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Product Name</label>
                  <nz-select nzShowSearch class="form-select " nzSize="default" [(ngModel)]="SelectedProduct"
                    (ngModelChange)="UpdateProduct($event)" name="ProductId" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s"
                      [nzLabel]="s.ProductName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Label Serial No.</label>
                  <input nz-input type="number" nzSize="default" [(ngModel)]="request.SerialNo" name="SerialNo"
                    placeholder="Enter Label Serial No." />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Store</label>
                  <nz-select nzShowSearch class="form-select mb-2" (ngModelChange)="onSelectedStoreChange()"
                    [(ngModel)]="StoreID" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.StoreList;" [nzValue]="s.StoreId"
                      [nzLabel]="s.StoreName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Rack</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.CurrentRackId"
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.RackList;" [nzValue]="s.RackId" [nzLabel]="s.RackName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Supplier</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.SupplierId"
                    nzAllowClear nzPlaceHolder="Choose">
                    <nz-option *ngFor="let s of this.SupplierList;" [nzValue]="s.SupplierId"
                      [nzLabel]="s.SupplierName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Purchase Order No</label>
                  <input nz-input nzSize="default" [(ngModel)]="request.PONumber" name="PONumber"
                    placeholder="Enter Purchase Order No." />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Invoice No</label>
                  <input nz-input nzSize="default" [(ngModel)]="request.InvoiceNo" name="InvoiceNo"
                    placeholder="Enter Invoice No." />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Inspection Status</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default"
                    [(ngModel)]="request.InspectionStatus" nzPlaceHolder="Choose">
                    <nz-option nzValue="" nzLabel="All"></nz-option>
                    <nz-option nzValue="Accepted" nzLabel="Accepted"></nz-option>
                    <nz-option nzValue="Rejected" nzLabel="Rejected"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Label Status</label>
                  <nz-select nzShowSearch class="form-select mb-2" nzSize="default" [(ngModel)]="request.LabelStatus"
                    nzPlaceHolder="Choose">
                    <nz-option nzValue="" nzLabel="All"></nz-option>
                    <nz-option nzValue="Active" nzLabel="Active"></nz-option>
                    <nz-option nzValue="Pending" nzLabel="Pending"></nz-option>
                    <nz-option nzValue="In-Active" nzLabel="In-Active"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24" style="margin-top: 30px;">
                  <label class="form-label">Show Only Active:</label>
                  <input style="margin-left: 10px" type="checkbox" [(ngModel)]="request.IsActive" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control [nzSpan]="24">
                  <label class="form-label">Common Search</label>
                  <input nz-input name="BatchNo" nzAllowClear placeholder="Enter Common Search" maxlength="30"
                    [(ngModel)]="request.SearchText" (keydown)="space($event)" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
        <div style="float: right; margin-bottom: 2%; margin-right: 10px">
          <div style="display: flex">
            <button nz-button nzType="primary" (click)="GetAllStockLabels()">
              Apply Filters
            </button>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-divider></nz-divider>
    <div style="padding: 8px;float:right" *ngIf="this.StockLabelList.length>0">
      <!-- <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button> -->
      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()"><i nz-icon nzType="export" nzTheme="outline"></i>
        Export</button>
    </div>
    <nz-alert nzCloseable nzType="info" *ngIf="IsMsgShow" nzMessage="No records found"></nz-alert>
    <nz-table *ngIf="this.StockLabelList.length>0" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
      style="width: 100%;" nzBordered [nzScroll]="{ x: '1200px',y:'1200px' }" nzSize="small" #basicTable [nzData]="[{}]"
      [nzLoading]="isTableLoading" (nzPageIndexChange)="onPageIndexChange($event)" [nzShowPagination]="true"
      nzShowSizeChanger (nzPageSizeChange)="onPageSizeChange($event)" [nzTotal]="totalRecords"
      [nzShowTotal]="totalTemplate" [nzFrontPagination]="false" [nzPageSizeOptions]="[10, 20, 50, 100]">
      <thead>
        <tr>
          <th nzLeft nzWidth="200px">Product Name</th>
          <th nzWidth="140px">Batch No.</th>
          <th nzWidth="140px">Product Quality</th>
          <th nzWidth="120px">No. of Packaging</th>
          <th nzWidth="160px">Label Serial No.</th>
          <th nzWidth="160px">Store Name</th>
          <th nzWidth="160px">Rack Name</th>
          <th nzWidth="80px">QTY</th>
          <th nzWidth="110px">Inspection Status</th>
          <th nzWidth="120px">Label Status</th>
          <th nzWidth="120px">MFG Date</th>
          <th nzWidth="120px">Expiry Date</th>
          <th nzWidth="160px">Added Date</th>
          <th nzWidth="160px">Added By</th>
          <th nzWidth="100px">Updated Date</th>
          <th nzWidth="100px">Updated By</th>
          <th nzRight="0" nzWidth="100px">Action</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let data of this.StockLabelList; let i=index" class="parent-row">
          <td nzLeft class="parent-cell">{{ data.ProductName }}</td>
          <td class="parent-cell">{{ data.BatchNo }}</td>
          <td class="parent-cell">{{ data.ProductQuality }}</td>
          <td class="parent-cell">{{ GetPackagingUnit(data) }}</td>

          <td colspan="12" class="nested-table-container" *ngIf="data.StockLabels.length > 0">
            <div class="nested-table">
              <div *ngFor="let item of data.StockLabels" class="nested-row">
                <div class="nested-cell" [style.width]="'160px'" *ngIf="item.LabelStatus != 'Pending' && item.LabelStatus != 'In-Active'">
                  <nz-tag [nzColor]="'blue'"><a (click)="OpenLabelFullDetailView(item.SerialNo)">
                      {{ item.SerialNo }}
                    </a></nz-tag>
                </div>
                <div *ngIf="item.LabelStatus == 'Pending' || item.LabelStatus == 'In-Active'" class="nested-cell" [style.width]="'160px'">{{ item.SerialNo }}</div>
                <div class="nested-cell" [style.width]="'160px'">{{ item.StoreName }}</div>
                <div class="nested-cell" [style.width]="'160px'">{{ item.RackName }}</div>
                <div class="nested-cell" [style.width]="'80px'">
                  <ng-container *ngIf="item.Quantity > 0">
                    {{item.Quantity}} {{data.StockUnit}}
                  </ng-container>
                  <ng-container *ngIf="item.Quantity == null">
                    Pending
                  </ng-container>
                </div>

                <div class="nested-cell" [style.width]="'110px'">{{ item.InspectionStatus }}</div>
                <div class="nested-cell" [style.width]="'120px'">{{ item.LabelStatus }}</div>
                <div class="nested-cell" [style.width]="'120px'">{{ item.MfgDate | date:'dd-MMM-yyyy' }}</div>
                <div class="nested-cell" [style.width]="'120px'">{{ item.ExpiryDate | date:'dd-MMM-yyyy' }}</div>
                <div class="nested-cell" [style.width]="'160px'">{{ item.AddedDate | DatetimeConverter }}</div>
                <div class="nested-cell" [style.width]="'160px'">{{ item.AddedBy }}</div>
                <div class="nested-cell" [style.width]="'100px'">{{ item.UpdatedDate | DatetimeConverter }}</div>
                <div class="nested-cell" [style.width]="'100px'">{{ item.UpdatedBy }}</div>
                <div class="nested-cell action-cell" [style.width]="'100px'" nzRight>
                  <div class="action-button-container">
                    <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                      class="btn btn-sm btn-light-primary" style="line-height:0">
                      Action
                      <span nz-icon nzType="down"></span>
                    </button>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul nz-menu nzSelectable>
                        <li style="width: 120px;" *ngIf="item.LabelStatus == 'Pending' && permission.Delete"
                          nz-menu-item (click)="StatusUpdateConfirmation(item.StockLabelId, 'In-Active')">
                          Deactivate
                        </li>
                        <li style="width: 120px;" *ngIf="item.LabelStatus == 'In-Active' && permission.Delete"
                          nz-menu-item (click)="StatusUpdateConfirmation(item.StockLabelId, 'Active')">
                          Activate
                        </li>
                        <li style="width: 120px;" *ngIf="permission.AddLabel"
                          nz-menu-item (click)="rePrintSelectedLabel(item.StockLabelId)">
                          Re-Print
                        </li>
                        <li style="width: 120px;" *ngIf="permission.View"
                          nz-menu-item (click)="OpenLabelFullDetailView(item.SerialNo)">
                          View Full Detail
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>



  </div>
</div>
<ng-template #totalTemplate let-total>Total {{ this.totalRecords }} Labels</ng-template>