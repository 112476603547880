export class AddStageModal {

  SaleOrderStages?: string = "";
  OnlyInternal?: boolean = false;
  OnlyCustomer?: boolean = false;
  WhatsAppTemplateMasterId?: number;
}

export class EditStageModal {

  StageId?: number = 0;
  SaleOrderStages?: string = "";
  OnlyInternal?: boolean = false;
  OnlyCustomer?: boolean = false;
  WhatsAppTemplateMasterId?: string = "";
}

export class StagesTableModel {

  SerialNumber?: number = 0;
  StageId?: number = 0;
  SaleOrderStages?: string = ""
  OnlyInternal?: boolean = false;
  OnlyCustomer?: boolean = false;
  AddedBy?: string = "";
  WhatsAppTemplateMasterId?: string = "";
  AddedDate?: string = "";
  Disabled?: boolean = false;
  DisabledBy?: string = "";
  DisabledDate?: string = "";
}


export class NotificationStageModelExport {

  SaleOrderStages?: string = ""
  OnlyInternal?: boolean = false;
  OnlyCustomer?: boolean = false;
  AddedBy?: string = "";
  AddedDate?: string = "";
}

export class NotificationGroupsModelExport {
  NotificationType?: string = "";
  UserType?: string = "";
  Name?: string = "";
  Email?: string = "";
  MobileNumber?: String = '';
  IsWhatsAppNotificationEnabled?: boolean = false;
  EnableToEmail?: boolean = false;
  EnableCcemail?: boolean = false;
  EnableBccemail?: boolean = false;
  UserMasterId?: Number = 0;
}

export class NotificationGroups {
  SerialNumber?: number = 0;
  NotificationGroupUserId?: Number = 0;
  CronScheduleExpression: string = "";
  NotificationType?: string = "";
  IsReminderScheduleRequired?: boolean = false;
  IsReminderScheduleAllowed?: boolean = false;
  Name?: string = "";
  Email?: string = "";
  UserType?: string = "";
  UserMasterId?: Number = 0;
  IsWhatsAppNotificationEnabled?: boolean = false;
  WhatsAppTemplateMasterId?: number = 0;
  MobileNumber?: String = '';
  EnableToEmail?: boolean = false;
  EnableCcemail?: boolean = false;
  EnableBccemail?: boolean = false;
  AddedBy?: string = '';
  AddedDate?: string = '';
  Disabled?: boolean = false;
  DisabledBy?: string = "";
  DateTime?: string = "";
  EmailGroupMappings?: any = [];
}



export class AddNotificationGroups {
  NotificationType?: string = "";
  Name?: string = "";
  Email?: string = "";
  UserType?: string = "";
  UserMasterId?: Number = 0;
  IsWhatsAppNotificationEnabled?: boolean = false;
  MobileNumber?: String = '';
  EnableToEmail?: boolean = false;
  EnableCcemail?: boolean = false;
  EnableBccemail?: boolean = false;
  AddedBy?: string = '';
  AddedDate?: string = '';
  Disabled?: boolean = false;
  DisabledBy?: string = "";
  DateTime?: string = "";
  EmailGroupMappings = [];
}

export class EmailGroupMappingList {
  SerialNumber?: number = 0;
  EmailGroupMappingId?: number = 0;
  NotificationGroupUserId?: number = 0;
  NotificationGroupUserName?: string = "";
  StageId?: number = 0;
  StageName
  Enabled?: boolean = false;
  AddedBy?: string = "";
  AddedDate?: string = "";
  Disabled?: boolean = false;
  DisabledBy?: string = "";
  DisabledDate?: string = "";
}

export class AddEmailGroupMapping {
  NotificationGroupUserId?: number = 0
  StageId?: number = 0
  Enabled?: boolean = false;
  AddedBy?: string = "";
  AddedDate?: string = "";
  Disabled?: boolean = false;
  DisabledBy?: string = "";
  DisabledDate?: string = "";
}

export class EmailGroupMappingModelExport {
  StageName?: string = "";
  NotificationGroupUserName?: string = "";
  Enabled?: boolean = false;
  AddedBy?: string = "";
  AddedDate?: string = "";
}

// Whatsapp Template

export class WhatsAppTemplateList {
  SerialNumber?: number = 0;
  ExpandRecord?: boolean = false;
  WhatsAppTemplateMasterId?: number = 0;
  ProviderName?: string = "";
  ProviderTemplateId?: number = 0;
  ProviderTemplateName?: string = "";
  ProviderTemplateDescription?: string = "";
  Parameters?: AddWhatsAppTemplateParameter[] = [];
  AddedBy?: string = "";
  AddedDate?: string = "";
  Disabled?: boolean = false;
  DisabledBy?: string = "";
  DisabledDate?: string = "";
}
export class AddWhatsAppTemplate {

  WhatsAppTemplateMasterId?: number = 0;
  ProviderName?: string = "";
  ProviderTemplateId?: number = 0;
  ProviderTemplateName?: string = "";
  ProviderTemplateDescription?: string = "";
  AddedBy?: string = "";
  AddedDate?: string = "";
  Disabled?: boolean = false;
  DisabledBy?: string = "";
  DisabledDate?: string = "";
}

export class AddWhatsAppTemplateParameter {
  ParameterId?: number = 0;
  TemplateMasterId?: number = 0;
  ParameterName?: string = "";
  ParameterType?: string = "";
  IsRequired?: boolean = false;
  DefaultValue?: string = "";
}

export class WhatsAppTemplateExport {
  ProviderName?: string = "";
  ProviderTemplateName?: string = "";
  ProviderTemplateDescription?: string = "";
  AddedBy?: string = "";
  AddedDate?: string = "";
}


// public class Whats App Config
export class WhatsAppConfigList {
  SerialNumber?: number = 0;
  WhatsAppConfigId?: number = 0;
  ConfigName?: string = "";
  ProviderName?: string = "";
  RegisteredSenderNumber?: string = "";
  ProviderKey?: string = "";
  AddedBy?: string = "";
  AddedDate?: string = "";
  Disabled?: boolean = false;
  DisabledBy?: string = "";
  DisabledDate?: string = "";
}

export class AddWhatsAppConfig {

  WhatsAppConfigId?: number = 0;
  ConfigName?: string = "";
  ProviderName?: string = "";
  RegisteredSenderNumber?: string = "";
  ProviderKey?: string = "";
  MaxDailyMessages?: number = 0;
  MaxMonthlyMessages?: number = 0;
  ApiEndpoint?: string = "";
  WebhookUrl?: string = "";
  AddedBy?: string = "";
  AddedDate?: string = "";
  Disabled?: boolean = false;
  DisabledBy?: string = "";
  DisabledDate?: string = "";
}

export class WhatsAppConfigExport {
  ConfigName?: string = "";
  ProviderName?: string = "";
  RegisteredSenderNumber?: string = "";
  ProviderKey?: string = "";
  AddedBy?: string = "";
  AddedDate?: string = "";
}