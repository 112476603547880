import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CostingEventService {
    private refreshCostingList = new Subject<void>();
    refreshCostingList$ = this.refreshCostingList.asObservable();

    triggerRefresh() {
        this.refreshCostingList.next();
    }
}