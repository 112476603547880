
<div class="card card-flush h-xl-100">
    <div class="card-body pt-5">
  
      <nz-page-header [nzGhost]="false">
        <nz-page-header-title>Packaging Type List</nz-page-header-title>
        <nz-page-header-subtitle>Manage your Packaging Type here</nz-page-header-subtitle>
        <nz-page-header-extra>
  
          <button nz-button nzType="primary"  *ngIf="this.permission.Add" (click)="showModal()">Add New</button>
        </nz-page-header-extra>
  
      </nz-page-header>
      <nz-divider></nz-divider>
      <div style="padding: 8px;float:right">
        <nz-date-picker [(ngModel)]="myDateValue"  nzPlaceHolder="Start Date" style="width: 150px;margin-right: 8px;" ></nz-date-picker>
        <nz-date-picker [(ngModel)]="toDate"  nzPlaceHolder="End Date" style="width: 150px;margin-right: 8px;"></nz-date-picker>
        <input type="text" style="width: 250px;margin-right: 8px;" nz-input placeholder="Search " [(ngModel)]="searchValue" 
        (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()"  />
        <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px;">Search</button>
        <button nz-button nzSize="small" (click)="reset()">Reset</button>
      </div>
      <nz-table nzSize="small"  [nzPageSize]="100" style="width: 100%;" #basicTable [nzData]="this.PackagingTypeList" [nzLoading]="isTableLoading" nzBordered>
        <thead>
          <tr>
            <th>Packaging Type Name</th>
            <th>Packaging Type Code</th>
            <th>Added Date</th>
            <th>Added By</th>
            <th nzWidth="200px" nzRight style="text-align:center" *ngIf="permission.Edit || permission.Delete">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{data.PackagingTypeName}}</td>
            <td>{{data.PackagingTypeCode}}</td>
            <td>{{ data.AddedDate | DatetimeConverter }}</td>
            <td title="{{ data.AddedBy?.Email }}">{{ data.AddedBy?.Name }}</td>

            <td nzRight style="text-align:center" *ngIf="CheckUserAddPermission(data.AddedBy?.Email)">
              <button class="btn btn-sm btn-light-primary" *ngIf="permission.Edit" (click)="OpenEditPop(data)">Edit</button> &nbsp;
              <button class="btn btn-sm btn-light-danger"  *ngIf="permission.Delete" (click)="handleDelete(data)">Delete</button>
            </td>
          </tr>
        </tbody>
      </nz-table>
  
  
      <nz-modal [nzWidth]="800"
                [nzStyle]="{ top: '20px' }"
                [(nzVisible)]="isVisible"
                [nzTitle]="modalTitle"
                [nzContent]="modalContent"
                [nzFooter]=null
                (nzOnCancel)="handleCancel()">
        <ng-template #modalTitle>{{PopUpTitle}}</ng-template>
  
        <ng-template #modalContent>
          <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Packaging Type Name</nz-form-label>
              <nz-form-control [nzSpan]="14" [nzErrorTip]="PackagingTypeNameError">
                <input nz-input formControlName="PackagingTypeName" name="PackagingTypeName" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="8" nzRequired>Packaging Type Code</nz-form-label>
              <nz-form-control [nzSpan]="14" [nzErrorTip]="PackagingTypeCodeError">
                <input nz-input formControlName="PackagingTypeCode" name="PackagingTypeCode" />
              </nz-form-control>
            </nz-form-item>
            <div class="text-center">
              <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
            </div>

          </form>
        </ng-template>
      </nz-modal>
  
    </div>
  </div>
  
