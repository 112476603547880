<app-SoDrawer></app-SoDrawer>
<app-sale-order-price-history></app-sale-order-price-history>
<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title> Sales Order</nz-page-header-title>
      <nz-page-header-subtitle>Add new</nz-page-header-subtitle>
      <nz-page-header-extra>
      </nz-page-header-extra>

    </nz-page-header>
    <nz-divider></nz-divider>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="handleOk()">

      <div style="margin:10px">

        <div nz-row [nzGutter]="24" style="margin: 0px 0px; background: #f0f2f5; border-radius: 4px;">
          <nz-divider></nz-divider>
          <div nz-col [nzSpan]="3">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                <label class="required">Order Type</label>
                <nz-select class="form-select mb-2" nzShowSearch name="OrderType" nzSize="default"
                  nzPlaceHolder="Choose" [(ngModel)]="this.NewSaleOrder.SaleOrderType"
                  [ngModelOptions]="{standalone: true}">
                  <nz-option nzValue="Sample" nzLabel="Sample"></nz-option>
                  <nz-option nzValue="Trial" nzLabel="Trial"></nz-option>
                  <nz-option nzValue="Product" nzLabel="Product"></nz-option>
                  <nz-option nzValue="Job Work" nzLabel="Job Work"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Reference cannot be more than 100 characters">
                <label class="">Buyer Order Reference No.</label>
                <input nz-input [(ngModel)]="NewSaleOrder.BORNumber" [ngModelOptions]="{standalone: true}"
                  name="SkinGsm" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="5">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                <label class="required">Customer</label>
                <nz-select class="form-select mb-2" nzShowSearch name="CustomerId" nzSize="default" nzAllowClear
                  [(ngModel)]="this.NewSaleOrder.CustomerId" [ngModelOptions]="{standalone: true}"
                  nzPlaceHolder="Choose" (ngModelChange)="loadProformaInvoices($event)">
                  <nz-option *ngFor="let s of this.CustomerList;" [nzValue]="s.CustomerId"
                    [nzLabel]="s.CustomerName"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Select Proforma Invoice">
                <label class="">Proforma Invoice</label>
                <nz-select class="form-select mb-2" nzSize="default" nzShowSearch
                  [(ngModel)]="this.NewSaleOrder.ProformaInvoiceId" nzPlaceHolder="Choose Proforma Invoice"
                  [ngModelOptions]="{standalone: true}" [disabled]="proformaInvoices.length === 0">
                  <nz-option *ngFor="let invoice of proformaInvoices" [nzValue]="invoice.ProformaInvoiceId"
                    [nzLabel]="invoice.ProformaInvoiceNumber"></nz-option>
                </nz-select>
                <a *ngIf="this.NewSaleOrder.ProformaInvoiceId && proformaInvoices.length>0" nz-button nzType="text"
                  nzSize="small" style="color: #1890ff;"
                  (click)="viewPIDrawer(this.NewSaleOrder.ProformaInvoiceId)">View</a>
              </nz-form-control>
            </nz-form-item>
          </div>
          <!--<div nz-col [nzSpan]="6">
    <nz-form-item>
      <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
        <label>Sale Order No.</label>
        <input nz-input name="SaleOrderNo" [(ngModel)]="this.NewSaleOrder.SaleOrderNumber" [ngModelOptions]="{standalone: true}" />
      </nz-form-control>
    </nz-form-item>
  </div>-->
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                <label class="required">Order Date</label>
                <nz-date-picker class="form-select mb-2" name="OrderCreationDate" nzAllowClear="false"
                  [nzDisabledDate]="disabledStartDate" [(ngModel)]="this.NewSaleOrder.SaleOrderDate"
                  [ngModelOptions]="{standalone: true}"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="4">
            <nz-form-item>
              <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                <label class="required">Delivery Date</label>
                <nz-date-picker class="form-select mb-2" name="DeliveryDate" nzAllowClear="false"
                  [(ngModel)]="this.NewSaleOrder.DeliveryDate" [ngModelOptions]="{standalone: true}"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>



        </div>
        <nz-divider></nz-divider>
        <div nz-row [nzGutter]="24" style="margin: 0px 0px;">
          <div nz-col [nzSpan]="24" style="background: #f0f2f5; border-radius: 4px; ">
            <nz-divider nzText="Formulation Code"></nz-divider>
            <div nz-row [nzGutter]="24">
              <div nz-col [nzSpan]="this.FormulationCodeDropDownSpan">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Formulation">
                    <label class="required">Formulation Code</label>
                    <nz-select class="form-select mb-2" nzShowSearch name="SelectedFormulationCode" nzSize="default"
                      [(ngModel)]="this.SelectedFormulationCodeId" (ngModelChange)="OnFormulationddlChange()"
                      [ngModelOptions]="{standalone: true}" nzPlaceHolder="Choose">
                      <nz-option *ngFor="let s of this.FormulationCodeList;" [nzValue]="s.SaleFormulationCodeId"
                        [nzLabel]="s.SaleFormulationCode"></nz-option>
                    </nz-select>
                    <label *ngIf="this.SelectedFormulationCodeId > 0">Fabric/Base Name:
                      <b>{{this.selectedFabricName}}</b> | <a class="btn-link" (click)="enableFabricChange()">Change</a>
                      | <a class="btn-link" (click)="ResetFabricChange()">Reset</a></label>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Category">
                    <label>Category</label>
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch
                      [(ngModel)]="NewPO.ProductCategoryId" nzPlaceHolder="Choose"
                      [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ProductCategoryList;" [nzValue]="s.ProductCategoryId"
                        [nzLabel]="s.ProductCategory"></nz-option>
                    </nz-select>

                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4" *ngIf="this.SelectedFormulationCode?.IsOrderLinkingRequired">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24">
                    <label class="required">Select Upper Order</label>
                    <nz-select class="form-select " [(ngModel)]="NewSaleOrder.OrderIdForLinking" nzShowSearch
                      nzSize="default" name="UpperOrderForLinking" nzAllowClear nzPlaceHolder="Sales Order"
                      [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.LinkingSalesOrderList;" [nzValue]="s.SaleOrderId"
                        [nzLabel]="s.SaleOrderNumber"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="this.SelectedFormulationCode?.IsOrderLinkingRequired ? 3 : 4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                    <label>Thick</label>
                    <!--<input nz-input name="Thick" placeholder="Thick" value="NewPO.Thick" [(ngModel)]="NewPO.Thick" [ngModelOptions]="{standalone: true}" />-->
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="NewPO.Thick"
                      nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ThicknessList;" [nzValue]="s.ThicknessId"
                        [nzLabel]="s.ThicknessNumber"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Quantity">
                    <label class="required">Grain</label>
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="NewPO.GrainId"
                      nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.GrainList;" [nzValue]="s.GrainId"
                        [nzLabel]="s.GrainName"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                    <label class="required">Width</label>

                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="NewPO.Width"
                      nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.WidthList;" [nzValue]="s.WidthId"
                        [nzLabel]="s.WidthNumber"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="5">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Select product Name">
                    <label class="required">Article Name</label>
                    <input nz-input name="MFG" placeholder="Article Name" value="NewPO.OrderQuantity"
                      [(ngModel)]="NewPO.ManufacturingProductName" [ngModelOptions]="{standalone: true}" />

                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col [nzSpan]="3">
                <nz-form-item>

                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Quantity">
                    <label class="required">QTY</label>
                    <input nz-input name="OrderQuantity" placeholder="OrderQuantity" value="NewPO.OrderQuantity"
                      [(ngModel)]="NewPO.OrderQuantity" (ngModelChange)="calclulateTotalSalePrice()"
                      [ngModelOptions]="{standalone: true}" />
                  </nz-form-control>
                </nz-form-item>

              </div>
              <div nz-col [nzSpan]="3">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Price">
                    <label class="required">Sales Price</label>
                    <div class="price-container">
                      <input nz-input name="SalePrice" value="this.NewPO.SalePrice" [(ngModel)]="this.NewPO.SalePrice"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="calclulateTotalSalePrice()" />
                      <button *ngIf="SelectedFormulationCodeId > 0 && NewSaleOrder.CustomerId > 0 && NewPO.Thick > 0" nz-button nzType="default" type="button" class="price-history-btn"
                        (click)="showPriceHistory(SelectedFormulationCodeId, NewSaleOrder.CustomerId, NewPO.Thick)"
                        title="Show Price History">
                        <i nz-icon nzType="history" nzTheme="outline"></i>
                      </button>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="3">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Price">
                    <label class="required">Total Sales Price</label>
                    <input nz-input name="Total" disabled value="this.NewPO.TotalSalePrice"
                      [(ngModel)]="this.NewPO.TotalSalePrice" [ngModelOptions]="{standalone: true}"
                      style="background: #fff;color: #000;" />
                  </nz-form-control>
                </nz-form-item>
              </div>



              <div nz-col [nzSpan]="4">
                <nz-form-item>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                    <label class="required">Color</label>
                    <nz-select class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="NewPO.ColorId"
                      nzAllowClear nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                        [nzLabel]="s.ColorName"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="4">
                <nz-form-item>

                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                    <label [class]="this.GetConditionalRequired()">Fabric/Base Color</label>
                    <nz-select [disabled]="this.NewPO?.FabricProductId == null || this.NewPO?.FabricProductId == 0"
                      class="form-select mb-2" nzSize="default" nzShowSearch [(ngModel)]="NewPO.FabricColorId"
                      nzAllowClear [nzPlaceHolder]="GetConditionalFabricPlaceholder()"
                      [ngModelOptions]="{standalone: true}">
                      <nz-option *ngFor="let s of this.ColorList;" [nzValue]="s.ColorId"
                        [nzLabel]="s.ColorName"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>

              </div>

              <div nz-col [nzSpan]="7">
                <nz-form-item [hidden]="this.disableFabricChange">

                  <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                    <label>Select fabric if formulation code Fabric is not applicable</label>
                    <nz-radio-group [(ngModel)]="selectedFabricCategory"
                      (ngModelChange)="FilterFabricBaseProduct($event)" [ngModelOptions]="{standalone: true}">
                      <label nz-radio nzValue="fabric">Fabric</label>
                      <label nz-radio nzValue="pu base">PU Base</label>
                    </nz-radio-group>
                    <nz-form-control [nzSpan]="24" nzErrorTip="Enter Amount">
                      <nz-select class="form-select mb-2" nzSize="default" nzAllowClear nzShowSearch
                        [(ngModel)]="selectedNewFabric" (ngModelChange)="SetNewFabricProduct()" nzPlaceHolder="Choose"
                        [ngModelOptions]="{standalone: true}">
                        <nz-option *ngFor="let s of this.ProductFilteredFabricBaseList;" [nzValue]="s"
                          [nzLabel]="s.ProductName"></nz-option>
                        /n
                      </nz-select>
                    </nz-form-control>
                  </nz-form-control>
                </nz-form-item>

              </div>

            </div>




          </div>

          <div nz-col [nzSpan]="10"
            style="display:none; background-color: #f0f2f5; border-radius: 4px; border-left: 5px solid #fff;">
            <nz-divider nzText="Production Element"></nz-divider>
            <div nz-col [nzSpan]="24">
              <nz-form-item>

                <nz-form-control [nzSpan]="24" nzErrorTip="Enter Element">
                  <label>Select Elements</label>
                  <nz-select class="form-select mb-2" nzSize="default" nzMode="multiple"
                    [(ngModel)]="listOfSelectedElement" (ngModelChange)="OnElementDdlchange()" nzAllowClear
                    nzPlaceHolder="Choose" [ngModelOptions]="{standalone: true}">
                    <nz-option *ngFor="let s of this.ElementList;" [nzValue]="s.ElementId"
                      [nzLabel]="s.ElementName"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-table nzSize="small" [nzScroll]="{ y: '200px' }" style="width: 1200px;" [nzData]="['']" #basicTable2
                nzBordered nzShowPagination="false">
                <thead>
                  <tr>

                    <th nzWidth="50px">S.No</th>
                    <th>Element</th>
                    <th nzWidth="120px">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of productionElement;let i=index">

                    <td nzWidth="20px">{{i+1}}</td>
                    <td>{{ data.ElementName }}</td>
                    <td> <input nz-input value="data.value" [(ngModel)]="data.Value"
                        [ngModelOptions]="{standalone: true}" /></td>


                  </tr>

                </tbody>

              </nz-table>
              <nz-divider></nz-divider>
            </div>

          </div>
        </div>
        <nz-divider nzText="Post Process"></nz-divider>
        <app-post-process-options [SelectedPostProcess]="SelectedPostProcess" (handleProcess)="handleProcess($event)"
          [PrintMasterList]="PrintMasterList" [EmbossingList]="EmbossingList" [LacquerList]="LacquerList"
          [VaccumList]="VaccumList" [TumblingList]="TumblingList"></app-post-process-options>
        <!--<nz-divider></nz-divider>-->

        <nz-divider nzText="Weight in GSM"></nz-divider>
        <div nz-row [nzGutter]="24"
          style="margin: 0px 0px; margin-top: 5px; margin-bottom: 5px; padding: 30px 0;    background: rgb(240, 242, 245);">

          <div nz-col [nzSpan]="6">

          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col [nzSpan]="3">

            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>

                <nz-form-control [nzSpan]="24" style="margin-left: -70px;"
                  nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                  <label style="margin-left: -1px;">Pre Skin</label><br>
                  <input nz-input type="number" [(ngModel)]="NewPO.PreSkinGsm" [ngModelOptions]="{standalone: true}"
                    name="PreSkinGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>

                <nz-form-control [nzSpan]="24" style="margin-left: -70px;"
                  nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                  <label style="margin-left: -1px;">Skin</label>
                  <input nz-input type="number" [(ngModel)]="NewPO.SkinGsm" [ngModelOptions]="{standalone: true}"
                    name="SkinGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>

                <nz-form-control style="margin-left: -70px;" [nzSpan]="24"
                  nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                  <label style="margin-left: -1px;">Foam</label>
                  <input nz-input type="number" [(ngModel)]="NewPO.FoamGsm" [ngModelOptions]="{standalone: true}"
                    name="FoamGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="4">
              <nz-form-item>
                <nz-form-control style="margin-left: -70px;" [nzSpan]="24"
                  nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                  <label style="margin-left: -1px;">Adhesive</label>
                  <input nz-input type="number" [(ngModel)]="NewPO.AdhesiveGsm" [ngModelOptions]="{standalone: true}"
                    name="AdhesiveGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="5">
              <nz-form-item>

                <nz-form-control style="margin-left: -70px;" [nzSpan]="20"
                  nzErrorTip="Cannot be empty, either enter 0 or a higher value">
                  <label style="margin-left: -1px;">Fabric (GSM)</label>
                  <input nz-input type="number" [(ngModel)]="NewPO.FabricGsm" readonly
                    [ngModelOptions]="{standalone: true}" name="FabricGsm" (ngModelChange)="calculateGrandTotal()" />
                </nz-form-control>
              </nz-form-item>

            </div>

          </div>
          <div nz-col [nzSpan]="15">



          </div>
          <div nz-col [nzSpan]="9">
            <nz-descriptions [nzColumn]="1">
              <nz-descriptions-item nzTitle="Total (GSM)" nzColon="true">
                <input nz-input type="text" readonly style="width:130px;" [(ngModel)]="NewPO.TotalGsm"
                  [ngModelOptions]="{standalone: true}" />
              </nz-descriptions-item>
            </nz-descriptions>
            <nz-descriptions [nzColumn]="1">
              <nz-descriptions-item nzTitle="Remark" nzColon="true">
                <textarea nz-input name="remark" placeholder="Remark"
                  style="height:100px; width:250px; margin-left: -4px;" [(ngModel)]="NewSaleOrder.Remarks"
                  [ngModelOptions]="{standalone: true}"></textarea>
              </nz-descriptions-item>

            </nz-descriptions>

          </div>



        </div>
        <nz-divider></nz-divider>
        <div class="text-center">
          <button nz-button nzType="primary" [nzLoading]="isLoading">Save</button>
        </div>
        <nz-divider></nz-divider>
      </div>
    </form>


  </div>
</div>
<app-PIDrawerView></app-PIDrawerView>

<nz-modal [nzWidth]="500" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isFabricPopupVisible" [nzTitle]="modalTitle"
  [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="handlePopupCancel()">
  <ng-template #modalTitle>Select Fabric Product</ng-template>

  <ng-template #modalContent>

    <nz-form-item>
      <nz-form-label [nzSpan]="8" nzRequired>Product Type</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <nz-select class="form-select mb-2" nzSize="default" [(ngModel)]="SelectedProductType"
          (ngModelChange)="onSelectedProductTypeChange()" nzPlaceHolder="Choose">
          <nz-option nzValue="Raw" nzLabel="Raw"></nz-option>
          <nz-option nzValue="Finished" nzLabel="Finished"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="8" nzRequired>Category</nz-form-label>
      <nz-form-control [nzSpan]="14" nzErrorTip="Select Product Category">
        <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="productItem.CategoryID" name="ProductCategoryId"
          nzSize="default" nzAllowClear nzPlaceHolder="Choose" (ngModelChange)="GetAllFirstCategory($event)">
          <nz-option *ngFor="let s of this.FabricCategoryFilteredList;" [nzValue]="s.ProductCategoryId"
            [nzLabel]="s.ProductCategory"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="8">Sub Category</nz-form-label>
      <nz-form-control [nzSpan]="14" nzErrorTip="Select Product First Category">
        <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="productItem.FirstCategoryID"
          name="ProductFirstSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose"
          (ngModelChange)="GetAllSecondCategory($event)">
          <nz-option *ngFor="let s of this.ProductFirstSubCategoryList;" [nzValue]="s.ProductFirstSubCategoryId"
            [nzLabel]="s.ProductFirstSubCategory"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="8">2nd Sub Category</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <nz-select class="form-select mb-2" nzShowSearch [(ngModel)]="productItem.SecondCategoryID"
          name="ProductSecSubCategoryId" nzSize="default" nzAllowClear nzPlaceHolder="Choose">
          <nz-option *ngFor="let s of this.ProductSecSubCategoryList;" [nzValue]="s.ProductSecSubCategoryId"
            [nzLabel]="s.ProductSecSubCategory"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="8">Product Name</nz-form-label>
      <nz-form-control [nzSpan]="14">
        <nz-select nzShowSearch class="form-select " nzSize="default" [(ngModel)]="selectedNewFabric" name="ProductId"
          nzAllowClear nzPlaceHolder="Choose">
          <nz-option *ngFor="let s of this.FilteredProductList;" [nzValue]="s" [nzLabel]="s.ProductName"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </ng-template>
  <ng-template #modalFooter>
    <div class="text-center">
      <a nz-button nzType="primary" (click)="validateFabricProduct()">Save</a>
    </div>
  </ng-template>

</nz-modal>