import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SaleOrderPriceHistoryModel, SaleOrderPriceHistoryRequestModel } from '../../../Models/SalesOrderModel';

@Injectable({
    providedIn: 'root'
})
export class SaleOrderPriceHistoryService {
    private apiUrl = environment.Api_Url;
    private isPriceHistoryVisibleSubject = new BehaviorSubject<boolean>(false);
    public isPriceHistoryVisible$ = this.isPriceHistoryVisibleSubject.asObservable();

    constructor(private http: HttpClient) { }

    getPriceHistory(SaleFormulationCodeId: number, Thick: number, CustomerId: number, NumberOfRecords: number): Observable<any> {
        let request = {
            SaleFormulationCodeId: SaleFormulationCodeId,
            Thick: Thick,
            CustomerId: CustomerId,
            NumberOfRecords: NumberOfRecords
        }
        return this.http.post<any>(
            `${this.apiUrl}saleorder/getsalepricebyproduct`, request
        );
    }

    showPriceHistory(): void {
        this.isPriceHistoryVisibleSubject.next(true);
    }

    hidePriceHistory(): void {
        this.isPriceHistoryVisibleSubject.next(false);
    }
}
