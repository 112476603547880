import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { Modules, Responsibility } from '../../Models/Enums';
import { StoreModel, AdminStoreModel, RackModel, ProductCategoryModel } from '../../Models/MasterModel';
import {
    ConsumeStockProductModel,
    IssueSaleOrderProductsStockModel,
    ProductStockStoreReportModel,
} from '../../Models/StockProductModel';
import {
    WorkPlanMasterModel,
    WorkPlanOrderModel,
} from '../../Models/WorkPlanModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import { AuthService } from '../../Services/auth.service';
import { LoadingService } from '../../Services/loadingService';
import moment from 'moment';
import { isNull } from 'util';
import { GeneralConfigModel } from 'src/PmsUIApp/Models/GeneralConfiguration';
import { IssueProductModel } from '../../Models/IssueModel';
import { SupplierModel } from '../../Models/SupplierModel';
import { MixingModel } from '../../Models/MixingModel';
import { ProductModel } from '../../Models/ProductModel';
import { SaleOrderProductionRawMaterialModel } from '../../Models/SalesOrderModel';
import { Subscription } from 'rxjs';
import { ConsumedSaleOrderProductsStockModel } from 'src/PmsUIApp/Models/ConsumptionModel';

@Component({
    selector: 'app-Consumption',
    templateUrl: './consumption.component.html',
})
export class ConsumptionComponent implements OnInit {
    selectedStoreDn: any;

    searchValue = '';
    ApiUrl = environment.Api_Url;
    isLoading: boolean = false;
    isVisible = false;
    isTableLoading: boolean = true;
    newCategory: boolean = false;
    NewCategoryStoreId: number;
    NewCategoryStoreList: StoreModel[] = [];
    NewCatProductTypeList: string[] = [];
    NewCatSelectedProductType: string;
    storeListCopy: any[] = [];
    StoreList: StoreModel[] = [];
    AdminStoreList: AdminStoreModel[] = [];
    StoreListOriginal: StoreModel[] = [];
    SelectedStoreID: number = 0;
    StoreWiseStock: IssueSaleOrderProductsStockModel[] = [];
    StoreWiseStockOriginal: IssueSaleOrderProductsStockModel[] = [];
    ConsumedStockList: ConsumedSaleOrderProductsStockModel[] = [];
    OutofStockList: IssueSaleOrderProductsStockModel[] = [];
    IsAuth = true;
    Type = '';
    SelectedWorkPlan: WorkPlanMasterModel = new WorkPlanMasterModel();
    SelectedWorkPlanOrder: WorkPlanOrderModel[] = [];
    WorkPlanList: WorkPlanMasterModel[] = [];
    SelectedOrderNo: number = 0;
    SelectedOrderNumber: string = '';
    exportfields: IssueSaleOrderProductsStockModel[] = [];
    exportProductionFields: IssueSaleOrderProductsStockModel[] = [];
    exportConsumedFields: IssueSaleOrderProductsStockModel[] = [];
    NewEdit: IssueSaleOrderProductsStockModel[] = [];
    GeneralConfigList: GeneralConfigModel[] = [];
    enableConsumeShowAllBeforeAllDispatch: boolean = false;
    IsPopUpOpen = false;
    exportoptions = {
        headers: ['ProductType', 'ProductName', 'Batch Number', 'Barcode', 'Rack Name', 'Available QTY'],
    };
    exportproductioinoptions = {
        headers: ['ProductType', 'Mixing Name', 'Material Name', 'Batch Number', 'Barcode', 'Actual QTY', 'Available QTY'],
    };
    exportconsumedoptions = {
        headers: ['ProductType', 'Material Category', 'Material Name', 'Batch Number', 'Barcode', 'Rack Name', 'Consumsed QTY', 'Consumed SC QTY', 'Damaged'],
    };
    fields: any;
    count: 0;
    permission = {
        View: false,
        Add: false,
        Delete: false

    }
    SelectedProduction = 0;
    SelectedDepartment1 = 0;
    SelectedDepartment2 = 0;
    SelectedStore = 0;
    SelectedRack = 0;
    SelectedStore1 = 0;
    SelectedStore2 = 0;
    SelectedSupplier: string = '';
    SelectedBatch: string = '';
    SelectedBarcode: string = '';
    SelectedRemark: string = '';
    SelectedUnit: string = '-';
    SelectedQTY = 0;
    PricePerUnit = 0;
    SelectedRawMaterialCategory = 0;
    SelectedMaterialCategory = '';
    OtherMaterialCategory = '';
    selectedproduct: any;
    selectedproductId: any = 0;
    SupplierList: SupplierModel[] = [];
    FilteredSupplierList: SupplierModel[] = [];

    BatchList: string[] = [];
    BarcodeList: string[] = [];
    IsProductType = true;
    IsCategory = true;
    IsProduct = true;
    IsSupplier = true;
    IsBatch = true;
    IsBarcode = true;
    IsStore = true;
    IsRack = true;
    IsQuantity = true;
    IsRequiredQuantity = true;
    ReqQTY = 0;
    StockQTY = 0;
    RawDetails: IssueSaleOrderProductsStockModel[] = [];
    RawDetailsOriginal: IssueSaleOrderProductsStockModel[] = [];
    RawDetailsRackOriginal: RackModel[] = [];
    RawDetailsProductOriginal: IssueSaleOrderProductsStockModel[] = [];
    RawDetailsCategoryOriginal: IssueSaleOrderProductsStockModel[] = [];
    RawDetailsSupplierOriginal: IssueSaleOrderProductsStockModel[] = [];
    RawDetailsBatchOriginal: IssueSaleOrderProductsStockModel[] = [];
    RawDetailsBarcodeOriginal: IssueSaleOrderProductsStockModel[] = [];
    SelectedRawDetails: IssueSaleOrderProductsStockModel[] = [];
    SelectedRawList: IssueSaleOrderProductsStockModel[] = [];
    MixingList: MixingModel[] = [];
    ProductList: ProductModel[] = [];
    ProductListOriginal: ProductModel[] = [];
    RackList: RackModel[] = [];
    ProductCategoryList: ProductCategoryModel[] = [];
    ProductCategoryListOriginal: ProductCategoryModel[] = [];
    NewRawMaterialCategoryList: string[] = [];
    private route$: Subscription = new Subscription;
    SaleOrderId: number;
    WorkPlanId: number;
    saleOrderbtnDisabled: boolean;
    saleOrderbtnHidden: boolean = true;
    consumedOrderReattempt: boolean = false;
    totalItemsCount: number = 0;
    ConsumedtotalItemsCount: number = 0;
    MixingStoreName: string = '';
    TotalCountofProductToConsume: number = 0;
    TotalCountofProductToSaveConsume: number = 0;
    TotalRemainingProductToFillQuantity: number = 0;
    TotalProductsQuantityFilled: number = 0;
    IsAllProductFilled: boolean = false;
    IsMaterialFiltered: string = "false";
    constructor(
        public http: HttpClient,
        private alertService: AlertMessageService,
        private modalService: NzModalService,
        private route: ActivatedRoute,
        private router: Router,
        private loader: LoadingService
        , private auth: AuthService
    ) { }

    ngOnInit(): void {
        this.permission.View = this.auth.CheckResponsibility(Modules.Consumption, Responsibility.View);
        this.permission.Add = this.auth.CheckResponsibility(Modules.Consumption, Responsibility.Add);
        this.permission.Delete = this.auth.CheckResponsibility(Modules.Consumption, Responsibility.Delete);
        if (this.permission.Add != true) {
            this.router.navigate(['/home/welcome']);
        }
        this.route$ = this.route.params.subscribe((params: Params) => {
            this.SaleOrderId = params["saleorderid"];
            this.WorkPlanId = params["workplanid"];
            this.consumedOrderReattempt = params["consumedorder"] == "true" ? true : false;
        })
        this.GetAllStore();
        this.GetGeneralConfig();
        //this.GetAllMixing();
        if (this.SaleOrderId > 0) {
            setTimeout(() => {
                this.Type = 'Production';
                this.WorkPlanId = this.WorkPlanId;
                this.OnTypeDdlchange();
            }, 3000);

        }
    }
    GetGeneralConfig() {
        let url = this.ApiUrl + 'generalconfig/getconfig'
        this.http.get<GeneralConfigModel[]>(url).subscribe({
            next: (res) => {
                this.GeneralConfigList = res;
                this.GeneralConfigList.forEach((s) => {
                    if (s.ConfigItem == 'EnableConsumptionShowAllBeforeAllDispatch') {
                        this.enableConsumeShowAllBeforeAllDispatch = s.ConfigValue == 'true' ? true : false;
                    }
                });
            },
            error: (res) => { }
        });
    }
    GetUserStores(UserName: string) {
        this.isTableLoading = true;
        this.AdminStoreList.forEach((x) => (x.IsChecked = false));
        let url = this.ApiUrl + 'user/getuserstores/' + UserName;
        this.http.get<number[]>(url).subscribe(
            (res) => {
                this.AdminStoreList.forEach((s) => {
                    if (res.includes(s.StoreId)) {
                        s.IsChecked = true;
                    }
                });
                this.AdminStoreList = this.AdminStoreList.filter((x) => x.IsChecked);

                this.isTableLoading = false;
            },
            (res) => { }
        );
    }


    onUp(key: any, data: any) {
        let res = this.StoreWiseStock;
        data = data.sort((a: any, b: any) =>
            a[key] > b[key] ? 1 : -1
        );
    }
    onDown(key: any, data: any) {
        let res = this.StoreWiseStock;
        data = data.sort((a: any, b: any) =>
            a[key] < b[key] ? 1 : -1
        );
    }

    search() {
        // var res = this.StoreWiseStock;
        var res = this.StoreWiseStock;
        this.StoreWiseStock = res.filter(
            (item: IssueSaleOrderProductsStockModel) => {
                if (
                    item?.ProductName?.toLowerCase().includes(
                        this.searchValue?.toLowerCase()
                    )
                ) {
                    return true;
                } else if (
                    item?.RackName?.toLowerCase().includes(this.searchValue?.toLowerCase())
                ) {
                    return true;
                } else if (
                    item?.Batch?.toLowerCase().includes(
                        this.searchValue
                    )
                ) {
                    return true;
                } else if (
                    item?.BatchNumber?.toLowerCase().includes(this.searchValue?.toLowerCase())
                ) {
                    return true;
                } else if (
                    item?.BarCode?.toLowerCase().includes(this.searchValue?.toLowerCase())
                ) {
                    return true;
                } else if (
                    item?.ProductType?.toLowerCase().includes(
                        this.searchValue?.toLowerCase()
                    )
                ) {
                    return true;
                } else if (item.RemainingQuantity == +this.searchValue) {
                    return true;
                } else if (item.Quantity == +this.searchValue) {
                    return true;
                }

                return false;
            }
        );
    }

    reset(): void {
        this.searchValue = '';
        this.search();
        this.GetStorewiseStock(this.selectedStoreDn);
    }

    export() {
        var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
        if (this.Type == 'Store') {
            if (this.exportfields)
                new AngularCsv(
                    this.exportfields,
                    ('Consumption-store-export' + exportdate),
                    this.exportoptions
                );
            else {
                console.log(`not found`);
            }
        }
        else {
            if (this.exportProductionFields)
                new AngularCsv(
                    this.exportProductionFields,
                    ('Consumption-production-export' + exportdate),
                    this.exportproductioinoptions
                );
            else {
                console.log(`not found`);
            }
        }
    }
    exportConsumedList() {
        var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
        if (this.exportConsumedFields)
            new AngularCsv(
                this.exportConsumedFields,
                ('Consumed-export' + exportdate),
                this.exportconsumedoptions
            );
        else {
            console.log(`not found`);
        }
    }
    onKeydown(event: any) {
        if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

            event.preventDefault();
            event = this.search()
        }
    }
    ValidateText() {
        this.searchValue.trim()
        this.searchValue = this.searchValue.trim();

    }
    GetAllStore() {
        if (this.StoreListOriginal.length <= 0) {
            this.isTableLoading = true;
            let url = this.ApiUrl + 'store/getallstores';
            this.http.get<StoreModel[]>(url).subscribe(
                (res) => {
                    this.StoreList = res;

                    res.forEach((x) => {
                        var str = new AdminStoreModel();
                        str.StoreId = x.StoreId;
                        str.StoreName = x.StoreName;
                        this.AdminStoreList.push(str);
                    });
                    this.StoreListOriginal = res;
                    //setTimeout(() => { this.SelectedStore1 = this.SelectedStore2 = this.StoreListOriginal[0].StoreId; }, 10)
                    this.isTableLoading = false;
                    this.GetUserStores(UserInfo.EmailID);
                },
                (res) => {
                    this.count++
                    if (this.count < 2) {
                        this.GetAllStore();
                    }
                }
            );
        }
    }
    GetAllRack() {
        this.isTableLoading = true;
        let url = this.ApiUrl + "rack/getallracks";
        this.http.get<RackModel[]>(url).subscribe(res => {
            this.RackList = res;
            this.isTableLoading = false;
        }, res => {
            this.count++;
            if (this.count < 2) {
                this.GetAllRack();
            }
        });
    }
    GetStorewiseStock(data: any) {
        this.selectedStoreDn = data;

        if (this.SelectedStoreID > 0) {
            this.loader.show();
            this.isTableLoading = true;
            let url =
                this.ApiUrl +
                'consume/getallproductstoconsumebystoreid/' +
                this.SelectedStoreID;
            this.http.get<IssueSaleOrderProductsStockModel[]>(url).subscribe(
                (res) => {
                    this.StoreWiseStock = res.filter((x) => x.Quantity > 0);
                    this.StoreWiseStock.forEach((x) => {
                        this.CheckQtyProduction(x);

                        this.exportfields = [];
                        this.StoreWiseStock.forEach((x) => {
                            this.fields = []
                            this.fields.ProductType = x.ProductType;
                            this.fields.ProductName = x.ProductName;
                            this.fields.BatchNumber = x.Batch;
                            this.fields.BarCode = x.BarCode;
                            this.fields.RackName = x.RackName;
                            this.fields.Quantity = x.Quantity;
                            this.exportfields.push(this.fields);
                        });
                    });
                    this.loader.hide();
                    this.isTableLoading = false;
                },
                (res) => { }
            );
        }
    }
    GetAllProductCategory() {
        this.isTableLoading = true;
        let url = this.ApiUrl + "productcategory/getallproductcategoriesforlisting";
        this.http.get<ProductCategoryModel[]>(url).subscribe(res => {
            this.ProductCategoryList = res;
            this.ProductCategoryListOriginal = res;
            this.isTableLoading = false;
        }, res => {
            this.count++;
            if (this.count < 2) {
                this.GetAllProductCategory();
            }
        });
    }
    GetAllSupplier() {

        let url = this.ApiUrl + "supplier/getallsuppliers";
        this.http.get<SupplierModel[]>(url).subscribe(res => {
            this.SupplierList = res;
            this.FilteredSupplierList = res
        }, res => {
            this.count++;
            if (this.count < 2) {
                this.GetAllSupplier();
            }
        });
    }
    // GetAllProducts() {
    //   let url = this.ApiUrl + 'product/getallproducts';
    //   this.http.get<StockPrintModel[]>(url).subscribe(
    //     (res) => {

    //     },

    //     (res) => {
    //       this.exportfields = [];
    //       this.NewEdit.forEach(x => {
    //         this.fields.ProductName = x.ProductName;
    //         this.fields.ProductType = x.ProductType;
    //         this.fields.Quantity=x.Quantity
    //         this.exportfields.push(this.fields);

    //       })
    //         this.isTableLoading=false;
    //     }
    //   );
    // }

    CheckQtyProduction(data: IssueSaleOrderProductsStockModel) {
        var OveragePercentage = 15;
        this.GeneralConfigList.forEach((s) => {
            if (s.ConfigItem == 'ConsumptionQuantityOveragePercentageLimit') {
                OveragePercentage = parseInt(s.ConfigValue);
            }
        });
        var margin = (data.ActualQuantity * OveragePercentage) / 100;
        var totalConsumptionQty = this.StoreWiseStock.filter(x => x.MixingName == data.MixingName && x.ProductId == data.ProductId).reduce((sum, current) => sum + parseFloat(current.ConsumptionQuantity == null ? "0" : current.ConsumptionQuantity?.toString()), 0) ?? 0;
        var marginQty = data.ActualQuantity + margin;
        //var marginQty = totalConsumptionQty + margin;
        data.QtyStyle = "";
        if (data.Quantity < data.ConsumptionQuantity) {
            data.QtyStyle = "background: #f50;color: white;";
            data.ConsumptionQuantity = 0;
            data.RemainingQuantity = null;
            this.alertService.error(
                'Consumption Quantity cannot be more than ' + data.Quantity
            );
        }
        else if (totalConsumptionQty > data.ActualQuantity && totalConsumptionQty <= marginQty) {
            data.QtyStyle = "background: #ffc700;";
            data.RemainingQuantity = parseFloat(((data.Quantity ?? 0) - (data.ConsumptionQuantity ?? 0)).toFixed(2));
        }
        else if (totalConsumptionQty > marginQty && data.IsDamaged != true) {
            data.QtyStyle = "background: #f50;color: white;";
            data.ConsumptionQuantity = 0;
            data.RemainingQuantity = 0;
            this.alertService.error('Overage Consumption Quantity cannot be more than ' + OveragePercentage + '% Limit of Required Quantity - ' + data.ActualQuantity + ' which is - ' + marginQty);
        }
        else {
            data.RemainingQuantity = parseFloat(((data.Quantity ?? 0) - (totalConsumptionQty ?? 0)).toFixed(2));
            if (data.RemainingQuantity < 0) {
                data.RemainingQuantity = 0;
            }
        }
        if (data.SCQuantity > data.Quantity) {
            data.SCQuantity = data.Quantity;
            data.SCRemainingQuantity = 0;
            this.alertService.error(
                'Consumption Quantity cannot be more than ' + data.SCQuantity
            );
        } else {
            data.SCRemainingQuantity = parseFloat(((data.Quantity ?? 0) - (data.SCQuantity ?? 0)).toFixed(2));
            data.SCQuantity = data.ConsumptionQuantity;
        }
        this.CalculateTotalProductsToConsume();
    }
    CheckQty(data: ProductStockStoreReportModel) {
        if (data.ConsumptionQuantity > data.Quantity) {
            data.ConsumptionQuantity = data.Quantity;
            data.RemainingQuantity = 0;
            this.alertService.error(
                'Consumption Quantity cannot be more than ' + data.Quantity
            );
        } else {
            data.RemainingQuantity =
                (data.Quantity ?? 0) - (data.ConsumptionQuantity ?? 0);
        }

        if (data.SCQuantity > data.Quantity) {
            data.SCQuantity = data.Quantity;
            data.SCRemainingQuantity = 0;
            this.alertService.error(
                'Consumption Quantity cannot be more than ' + data.SCQuantity
            );
        } else {
            data.SCRemainingQuantity = (data.Quantity ?? 0) - (data.SCQuantity ?? 0);
        }
    }
    handleSave() {

        const modal = this.modalService.confirm({
            nzTitle: 'Confirm',
            nzContent: 'Are you sure to save?',
            nzOnOk: () => this.Save(),
        });

        setTimeout(() => modal.destroy(), 5000);

    }
    Save() {
        var consumeList: ConsumeStockProductModel[] = [];
        if (this.StoreWiseStock.filter(x => x.Remark?.length > 100).length > 0) {
            this.alertService.error("Purpose cannot be more than 100 characters.");
            return;
        }
        if (!this.IsAllProductFilled) {
            this.alertService.error("Please enter consumption quantity for all required products in every material category. No quantity given for " + this.TotalRemainingProductToFillQuantity + " products.");
            return;
        }
        if (this.Type == 'Store') {
            this.StoreWiseStock.forEach((x) => {
                var item = new ConsumeStockProductModel();
                item.AddedBy = UserInfo.EmailID;
                item.ProductId = x.ProductId;
                item.RackId = x.RackId;
                item.StoreId = x.StoreId;
                item.Quantity = x.ConsumptionQuantity?.toString();
                item.StockId = x.StockId;
                item.StockProductId = x.StockProductId;
                item.StockLabelId = x.StockLabelId;
                item.SaleOrderId = x.SaleOrderId;
                item.Purpose = x.Remark;
                item.Unit = x.Unit;
                item.IsDamaged = x.IsDamaged;
                item.IsNewAdd = x.IsNewAdd;
                item.MixingName = x.MixingName;
                item.PricePerUnit = x.PerUnitPrice;              //item.Unit=x.un
                //item.SaleOrderId=x.sa
                if (x.ConsumptionQuantity > 0) {
                    consumeList.push(item);
                }
            });
        } else {
            this.StoreWiseStock.forEach((x) => {
                var item = new ConsumeStockProductModel();
                item.AddedBy = UserInfo.EmailID;
                item.ProductId = x.ProductId;
                item.ProductName = x.ProductName;
                item.RackId = x.RackId;
                item.IsNewAdd = x.IsNewAdd;
                item.MixingName = x.MixingName;
                item.StoreId = x.StoreId;
                item.Quantity = x.ConsumptionQuantity?.toString();
                item.SCQuantity = x.SCQuantity;
                item.SaleOrderId = this.SelectedOrderNo;
                // item.Purpose = "Sale Order No: " + (this.SelectedWorkPlanOrder.filter(x => x.SaleOrder.SaleOrderId = this.SelectedOrderNo))[0].SaleOrder.SaleOrderNumber;
                item.StockId = x.StockId;
                item.StockProductId = x.StockProductId;
                item.StockLabelId = x.StockLabelId;
                item.Unit = x.Unit;
                item.IsDamaged = x.IsDamaged;
                //item.SaleOrderId=x.sa
                if (x.ConsumptionQuantity > 0) {
                    consumeList.push(item);
                }
            });
        }
        this.loader.show();
        let url = this.ApiUrl + 'consume/addupdateconsumestockproduct';
        this.http.post<any>(url, consumeList).subscribe({
            next: (res) => {
                this.alertService.success('Consumption Saved Successfully');
                this.isLoading = false;
                this.loader.hide();
                this.router.navigate(['/home/consumption/list']);
            },
            error: (res) => {
                debugger;
                if (res?.error?.StatusCode == 'BadRequest') {
                    this.alertService.error(res.error.ResponseBody);
                } else {
                    this.alertService.error(res?.error);
                }
                this.isLoading = false;
                this.loader.hide();
            },
        });
    }

    validate() {
        var saveflag = true;
        this.StoreWiseStock.forEach((x) => {
            if (x.ConsumptionQuantity > x.Quantity) {
                this.alertService.error(
                    'Consumption QTY for ' +
                    x.ProductName +
                    ' cannot be more than ' +
                    x.Quantity
                );
                saveflag = false;
            }
            if (this.Type == 'Store' && (x.Remark == undefined || x.Remark == '') && x.ConsumptionQuantity > 0) {
                this.alertService.error("Please provide purpose to consume");
                saveflag = false;
            }
        });
        if (saveflag) {
            this.handleSave();
        }
    }
    OnTypeDdlchange() {
        if (this.Type == 'Store') {
            this.GetAllStore();
            this.SelectedWorkPlan = new WorkPlanMasterModel();
            this.SelectedOrderNo = 0;
            //this.GetAllProductCategory();
            //this.NewPOList = [];
            //this.ProductFilteredList.forEach(x => {
            //  var issueitem = new IssueProductModel;
            //  issueitem.ProductId = x.ProductId;
            //  issueitem.ProductCode = x.ProductCode;
            //  issueitem.ProductName = x.ProductName;
            //  issueitem.Quantity = 0;
            //  issueitem.QuantityValue = 0;
            //  issueitem.ProductId = x.ProductId;
            //  this.NewPOList.push(issueitem);
            //})
        } else {
            this.GetAllWorkPlanList();
            this.StoreWiseStock = [];
            //this.Production = new ProductionModel();
            this.IsMaterialFiltered = "false";
        }
        this.loader.hide();
    }
    GetAllWorkPlanList() {
        if (this.WorkPlanList.length <= 0) {
            //this.isTableLoading = true;
            //let url = this.ApiUrl + 'workplan/getallworkplan';
            let url = '';
            if (this.enableConsumeShowAllBeforeAllDispatch || this.consumedOrderReattempt) {
                url = this.ApiUrl + 'workplan/getallworkplanbysaleorderstatus';
            }
            else {
                url = this.ApiUrl + 'workplan/getallworkplanbysaleorderstatusforconsume';
            }
            var item = ["RawMaterialIssued",
                "ProductionStarted",
                "Mixing",
                "InJumbo",
                "MoveToPostProcess",
                "PrintAssigned",
                "PrintInProcess",
                "PrintCompleted",
                "EmbossingAssigned",
                "EmbossingInProcess",
                "EmbossingCompleted",
                "VacuumAssigned",
                "VacuumInProcess",
                "VacuumCompleted",
                "LacquerAssigned",
                "LacquerInProcess",
                "LacquerCompleted",
                "TumblingAssigned",
                "TumblingInProcess",
                "TumblingCompleted",
                "JumboInspection",
                "MoveToDispatch",
                "DispatchReady",
                "PartialDispatchReady",
                "PartialDispatchCompleted",
                "LiningOrderMerged"];

            this.http.post<any>(url, item).subscribe(
                (res) => {
                    this.WorkPlanList = res.Data;
                    if (this.WorkPlanId > 0) {
                        this.SelectedWorkPlan = this.WorkPlanList.filter(x => x.WorkPlanId == this.WorkPlanId)[0];
                        this.OnWorkPlanChange();
                    }
                },
                (res) => {
                    this.loader.hide();
                }
            );
        }
    }
    OnWorkPlanChange() {
        this.loader.show();
        this.StoreWiseStock = [];
        this.SelectedWorkPlanOrder = [];
        //this.isTableLoading = true;
        let url = '';
        if (this.enableConsumeShowAllBeforeAllDispatch || this.consumedOrderReattempt) {
            url = this.ApiUrl +
                'workplan/getworkplanorderbyworkplanid/' +
                this.SelectedWorkPlan.WorkPlanId;
        }
        else {
            url =
                this.ApiUrl +
                'workplan/getworkplanorderbyworkplanidforconsume/' +
                this.SelectedWorkPlan.WorkPlanId;
        }
        this.http.get<WorkPlanOrderModel[]>(url).subscribe(
            (res) => {
                // this.SelectedWorkPlanOrder = res.filter(x => x.SaleOrder.Status.toString() == "RawMaterialIssued");
                this.SelectedWorkPlanOrder = res;
                if (this.SaleOrderId > 0) {
                    this.SelectedOrderNo = this.SelectedWorkPlanOrder.filter(x => x.SaleOrder.SaleOrderId == this.SaleOrderId)[0].SaleOrder.SaleOrderId;
                    this.saleOrderbtnDisabled = true;
                    this.saleOrderbtnHidden = false;
                    this.OnWorkPlanOrderChange();
                }
                this.loader.hide();
            },
            (res) => {
                this.loader.hide();
            }
        );
    }

    OnWorkPlanOrderChange() {
        this.loader.show();
        //this.isTableLoading = true;
        this.StoreWiseStock = [];
        let url =
            this.ApiUrl +
            'consume/getallsaleorderproductstoconsume/' +
            this.SelectedOrderNo;
        this.http.get<IssueSaleOrderProductsStockModel[]>(url).subscribe(
            (res) => {

                try {
                    if (res.length > 0) {
                        this.StoreWiseStock = res;
                        let productIdsWithStock = new Set(this.StoreWiseStock.filter(x => x.Quantity > 0).map(item => item.ProductId));
                        let uniqueProductMixingCombinations = new Set();

                        this.StoreWiseStock.forEach(item => {
                            const combinationKey = `${item.ProductId}-${item.MixingName}`;
                            uniqueProductMixingCombinations.add(combinationKey);

                        });
                        this.TotalCountofProductToConsume = uniqueProductMixingCombinations.size;

                        console.log(this.TotalCountofProductToConsume)

                        // Filter for Out-of-Stock Products
                        this.OutofStockList = this.StoreWiseStock.filter(x => x.Quantity <= 0);

                        // Further refine OutofStockList to include only unique products that are not in productIdsWithStock
                        let seenProductIds = new Set();
                        this.OutofStockList = this.OutofStockList.filter(item => {
                            const isUniqueAndOutOfStock = !seenProductIds.has(item.ProductId) && !productIdsWithStock.has(item.ProductId);
                            if (isUniqueAndOutOfStock) {
                                seenProductIds.add(item.ProductId);
                                return true;
                            }
                            return false;
                        });
                        console.log('UniqueOOS', this.OutofStockList)
                        this.StoreWiseStock = this.StoreWiseStock.filter(
                            (x) => x.Quantity > 0
                        );
                        this.StoreWiseStock.forEach(x => x.ConsumptionQuantity = null);
                        this.exportProductionFields = [];
                        let count = this.totalItemsCount = 0;
                        this.StoreWiseStock.forEach((x) => {
                            count++;
                            this.fields = [];
                            this.fields.ProductType = x.ProductType;
                            this.fields.MixingName = x.MixingName;
                            this.fields.ProductName = x.ProductName;
                            this.fields.Batch = x.Batch;
                            this.fields.BarCode = x.BarCode;
                            this.fields.ActualQuantity = x.ActualQuantity ?? 0;
                            this.fields.AvailableQTY = x.Quantity + '/' + x.Unit;
                            this.exportProductionFields.push(this.fields);
                        });
                        this.totalItemsCount = count;
                        console.log(this.StoreWiseStock)
                        this.StoreWiseStockOriginal = this.StoreWiseStock
                        // this.ConsumedStockList = res.filter(
                        //     (x) => x.ConsumedQty > 0
                        // );
                        this.exportConsumedFields = []
                        // let consumedcount = this.ConsumedtotalItemsCount = 0;
                        // this.ConsumedStockList.forEach(x => {
                        //     consumedcount++;
                        //     x.SerialNo = consumedcount;

                        //     this.fields = [];
                        //     this.fields.ProductType = x.ProductType;
                        //     this.fields.MixingName = x.MixingName;
                        //     this.fields.ProductName = x.ProductName;
                        //     this.fields.Batch = x.Batch;
                        //     this.fields.BarCode = x.BarCode;
                        //     this.fields.RackName = x.RackName;
                        //     // this.fields.ActualQuantity = x.ActualQuantity ?? 0;
                        //     this.fields.ConsumedQty = x.ConsumedQty + '/' + x.Unit;
                        //     this.fields.ConsumedScQty = x.ConsumedScQty ?? 0;
                        //     this.fields.Damaged = x.Damaged;
                        //     this.fields.ConsumedBy = x.ConsumedBy;
                        //     this.fields.ConsumedDate = x.ConsumedDate;
                        //     this.exportConsumedFields.push(this.fields);
                        // });
                        // this.ConsumedtotalItemsCount = consumedcount;
                        // console.log(this.ConsumedStockList)

                        this.MixingStoreName = this.StoreWiseStock[0].StoreName;
                        if (this.consumedOrderReattempt) {
                            this.GetAllSaleOrderConsumedProducts();
                        }
                    }
                } catch (e) {
                    this.StoreWiseStock = [];
                    console.log(e)
                }
                //this.StockList.forEach(x => {
                //  x.AcceptQTY = x.Quantity;
                //  x.AvailableQTY = x.ProductStock.reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
                //})
                this.loader.hide();
            },
            (res) => {
                this.loader.hide();
            }
        );

        url = this.ApiUrl + "issueproduct/getstockforsaleorder/" + this.SelectedOrderNo
        this.http.get<SaleOrderProductionRawMaterialModel[]>(url).subscribe(res => {
            res.forEach(x => {
                x.ProductStock.forEach(y => {
                    y.MixingName = x.MixingName
                })
            })
            this.NewRawMaterialCategoryList = [...new Set(res.map(item => item.MixingName))];


            this.loader.hide();
        }, res => { });
    }
    GetAllSaleOrderConsumedProducts() {
        this.loader.show();
        let url = this.ApiUrl + "consume/getallsaleorderconsumedproducts/" + this.SelectedOrderNo;
        this.http.get<ConsumedSaleOrderProductsStockModel[]>(url).subscribe({
            next: (res) => {
                this.ConsumedStockList = res;
                this.exportConsumedFields = []
                let consumedcount = this.ConsumedtotalItemsCount = 0;
                this.ConsumedStockList.forEach(x => {
                    consumedcount++;
                    x.SerialNo = consumedcount;

                    this.fields = [];
                    this.fields.ProductType = x.ProductType;
                    this.fields.MaterialCategory = x.MaterialCategory;
                    this.fields.ProductName = x.ProductName;
                    this.fields.Batch = x.Batch;
                    this.fields.RackName = x.RackName;
                    // this.fields.ActualQuantity = x.ActualQuantity ?? 0;
                    this.fields.ConsumedQty = x.ConsumedQty + '/' + x.Unit;
                    this.fields.ConsumedScQty = x.ConsumedScQty ?? 0;
                    this.fields.Damaged = x.Damaged;
                    this.fields.ConsumedBy = x.ConsumedBy;
                    this.fields.ConsumedDate = x.ConsumedDate;
                    this.exportConsumedFields.push(this.fields);
                });
                this.ConsumedtotalItemsCount = consumedcount;
                console.log(this.ConsumedStockList)
                this.loader.hide();
            },
            error: (res) => {

            }
        });
    }
    openAddModel() {
        this.IsPopUpOpen = true
        this.GetAllProductCategory();
        this.GetAllProducts();
        this.GetAllRack();
        this.GetAllSupplier();
        this.GetStoreRackStock(this.StoreWiseStock[0].StoreId);
    }
    backToPendingOrderbtn() {
        this.router.navigate(['/home/consumption/pendingorders']);
    }
    resetOrdersBtn() {
        this.saleOrderbtnDisabled = false;
    }
    closeAddModel() {
        this.IsPopUpOpen = false
        this.IsRequiredQuantity = true;
        this.NewCategoryStoreId = 0;
        this.SelectedRack = 0;
        this.newCategory = false;
        this.SelectedRawMaterialCategory = 0;
        this.IsCategory = true;
        this.NewCatSelectedProductType = '';
        this.IsProductType = true;
        this.selectedproductId = 0;
        this.IsProduct = true;
        this.IsSupplier = true;
        this.IsBatch = true;
        this.IsBarcode = true;
        this.IsStore = true;
        this.IsRack = true;
        this.IsQuantity = true;
        this.StockQTY = 0;
        this.SelectedMaterialCategory = '';
    }
    addRawMaterial() {
        var item = this.SelectedRawDetails[0]
        item.MixingName = this.SelectedMaterialCategory == 'Other' ? this.OtherMaterialCategory : this.SelectedMaterialCategory;
        item.IsNewAdd = true;
        this.StoreWiseStock.push(item)
        this.closeAddModel();
        this.SelectedRawDetails = [];
        this.alertService.success("Additional Product added in consumption table below. Now enter QTY and SC QTY to consume.")
    }
    // GetAllMixing() {
    //     this.isTableLoading = true;
    //     let url = this.ApiUrl + "mixing/getallmixings";
    //     this.http.get<MixingModel[]>(url).subscribe(res => {
    //         this.MixingList = res;

    //     }, res => {
    //         this.count++;
    //         if (this.count < 2) { this.GetAllMixing(); }
    //     });
    // }
    GetAllProducts() {
        let url = this.ApiUrl + "product/getallproducts";
        this.http.get<ProductModel[]>(url).subscribe(res => {
            this.ProductList = res;
            this.ProductListOriginal = res;
            //this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");
        }, res => {
            this.count++;
            if (this.count < 2) {
                this.GetAllProducts();
            }
        });
    }
    OnRackDdlchange() {
        if (!this.newCategory) {
            this.RawDetails = this.RawDetailsOriginal.filter(x => x.RackId == this.SelectedRack);
            this.RawDetailsCategoryOriginal = this.RawDetails;
            let unique = [...new Set(this.RawDetails.filter(x => x.RackId == this.SelectedRack).map(item => item.ProductCategoryId))];

            this.ProductCategoryList = this.ProductCategoryListOriginal.filter(x => unique.includes(x.ProductCategoryId));
            this.IsCategory = false;
            this.SelectedRawMaterialCategory = 0;

            this.selectedproductId = 0;
            this.IsProduct = true;
            this.SelectedUnit = '-';
            this.SelectedSupplier = '';
            this.IsSupplier = true;
            this.SelectedBatch = '';
            this.IsBatch = true;
            this.SelectedBarcode = '';
            this.IsBarcode = true;
            this.IsStore = true;
            this.StockQTY = 0
        }
        else {
            this.RawDetails = this.RawDetailsOriginal.filter(x => x.RackId == this.SelectedRack);
            this.RawDetailsCategoryOriginal = this.RawDetails;
            let unique = [...new Set(this.RawDetails.filter(x => x.RackId == this.SelectedRack).map(item => item.ProductType))];
            this.NewCatProductTypeList = unique;
            this.NewCatSelectedProductType = '';
            this.IsProductType = false;

            this.IsCategory = true;
            this.SelectedRawMaterialCategory = 0;
            this.selectedproductId = 0;
            this.IsProduct = true;
            this.SelectedUnit = '-';
            this.SelectedSupplier = '';
            this.IsSupplier = true;
            this.SelectedBatch = '';
            this.IsBatch = true;
            this.SelectedBarcode = '';
            this.IsBarcode = true;
            this.IsStore = true;
            this.StockQTY = 0
        }
    }
    OnNewCatProductTypeChange() {
        this.RawDetails = this.RawDetailsOriginal.filter(x => x.RackId == this.SelectedRack);
        let unique = [...new Set(this.RawDetails.filter(x => x.RackId == this.SelectedRack && x.ProductType == this.NewCatSelectedProductType).map(item => item.ProductCategoryId))];
        this.ProductCategoryList = this.ProductCategoryListOriginal.filter(x => unique.includes(x.ProductCategoryId));

        this.IsCategory = false;
        this.SelectedRawMaterialCategory = 0;
        this.selectedproductId = 0;
        this.IsProduct = true;
        this.SelectedUnit = '-';
        this.SelectedSupplier = '';
        this.IsSupplier = true;
        this.SelectedBatch = '';
        this.IsBatch = true;
        this.SelectedBarcode = '';
        this.IsBarcode = true;
        this.IsStore = true;
        this.StockQTY = 0
    }
    OnCategoryDdlchange() {
        this.RawDetails = this.RawDetailsOriginal.filter(x => x.ProductCategoryId == this.SelectedRawMaterialCategory);
        let unique = [...new Set(this.RawDetails.filter(x => x.ProductCategoryId == this.SelectedRawMaterialCategory).map(item => item.ProductId))];
        this.ProductList = this.ProductListOriginal.filter(x => unique.includes(x.ProductId));
        this.selectedproductId = 0;
        this.IsProduct = false;
        this.SelectedUnit = '-';
        this.SelectedSupplier = '';
        this.IsSupplier = true;
        this.SelectedBatch = '';
        this.IsBatch = true;
        this.SelectedBarcode = '';
        this.IsBarcode = true;
        this.IsStore = true;
        this.StockQTY = 0
    }
    OnProductDdlchange() {
        this.RawDetails = this.RawDetailsOriginal.filter(x => x.ProductId == this.selectedproductId);
        this.RawDetailsProductOriginal = this.RawDetails;
        this.SelectedUnit = this.ProductList.filter(x => x.ProductId == this.selectedproductId)[0].Unit;
        let unique = [...new Set(this.RawDetails.filter(x => x.ProductId == this.selectedproductId).map(item => item.SupplierName))];
        this.FilteredSupplierList = this.SupplierList.filter(x => unique.includes(x.SupplierName));
        this.IsRequiredQuantity = false;
        this.IsSupplier = false;
        this.IsBatch = true;
        this.IsBarcode = true;
        this.IsStore = true;
        // this.IsRack = true;
        this.IsQuantity = true;
        this.StockQTY = 0
    }
    OnSupplierDdlchange() {
        this.RawDetails = this.RawDetailsOriginal.filter(x => x.SupplierName == this.SelectedSupplier);
        this.RawDetailsSupplierOriginal = this.RawDetails;
        this.BatchList = this.RawDetails.filter(x => x.SupplierName == this.SelectedSupplier && x.ProductId == this.selectedproductId && x.Unit == this.SelectedUnit).map(x => x.Batch);
        this.BatchList = [...new Set(this.BatchList.map(item => item))];

        this.SelectedBatch = '';
        this.SelectedStore = 0;
        this.SelectedBarcode = '';

        this.IsSupplier = false;
        this.IsBatch = false;
        this.IsBarcode = true;
        this.IsQuantity = true;
        this.StockQTY = 0

        // this.SelectedRawDetails = this.RawDetails.filter(x => x.BatchNumber == this.SelectedBatch)
        // this.SelectedStore = this.SelectedRawDetails[0].StoreId;
        // this.SelectedRack = this.SelectedRawDetails[0].RackId;
        // this.StockQTY = this.SelectedRawDetails[0].Quantity;
        // let unique = [...new Set(this.RawDetails.filter(x => x.SupplierId == this.SelectedSupplier).map(item => item.StoreId))];
        //this.StoreList = this.StoreList.filter(x => unique.includes(x.StoreId));
        //this.OnBatchDdlchange()
    }
    OnBatchDdlchange() {
        this.RawDetails = this.RawDetailsSupplierOriginal.filter(x => x.Batch == this.SelectedBatch);
        this.RawDetailsBatchOriginal = this.RawDetails;
        this.BarcodeList = this.RawDetails.filter(x => x.Batch == this.SelectedBatch).map(x => x.Barcode);
        this.BarcodeList = [...new Set(this.BarcodeList.map(item => item))];
        this.SelectedRawDetails = this.RawDetails.filter(x => x.Batch == this.SelectedBatch && x.ProductId == this.selectedproductId)
        this.SelectedBarcode = '';

        this.IsBarcode = false;

        this.IsQuantity = true;
        this.StockQTY = 0

    }

    OnBarCodeDdlchange() {
        //this.SelectedRawDetails = this.SelectedRawList.filter(x=> x.ra)
        //this.SelectedRawDetails = this.RawDetails.filter(x => x.Barcode == this.SelectedBarcode)
        var sumExistQty = this.SelectedRawList.filter(x => x.MixingName != this.ProductCategoryListOriginal.filter(x => x.ProductCategoryId == this.SelectedRawMaterialCategory)[0]?.ProductCategory && x.ProductId == this.selectedproductId).reduce((sum, current) => sum + parseFloat(current.Quantity?.toString()), 0) ?? 0
        var selectedRawDetails = this.SelectedRawDetails[0];
        if (sumExistQty == 0) {
            this.StockQTY = selectedRawDetails?.Quantity;
        }
        else
            this.StockQTY = selectedRawDetails.Quantity - sumExistQty;
        this.IsQuantity = false;
        if (this.StockQTY < 0) {
            this.StockQTY = 0;
        }

    }
    GetStoreRackStock(StoreId: number) {
        this.loader.show();
        let url = this.ApiUrl + "stock/getproductstockwithsupplierbystoreid/" + StoreId;
        this.http.get<IssueSaleOrderProductsStockModel[]>(url).subscribe(res => {
            this.RawDetailsOriginal = res;
            let unique = [...new Set(res.map(item => item.RackId))];
            this.RawDetailsRackOriginal = this.RackList.filter(z => unique.includes(z.RackId))
            if (this.RawDetailsRackOriginal.length > 0) {
                this.IsRack = false;
            }
            this.loader.hide();
            //this.ProductRawList = this.ProductList.filter(x => x.ProductType == "Raw");
        }, res => {
            this.loader.hide();
            this.count++;
            if (this.count < 2) {
                this.GetStoreRackStock(StoreId);
            }
        });
    }
    CalculateTotalProductsToConsume(CalculateOnSave?: boolean) {
        this.IsAllProductFilled = true;
        var consumeList: ConsumeStockProductModel[] = [];
        this.StoreWiseStock.forEach((x) => {
            var item = new ConsumeStockProductModel();
            item.ProductId = x.ProductId;
            item.MixingName = x.MixingName;
            item.Quantity = x.ConsumptionQuantity?.toString();
            item.SCQuantity = x.SCQuantity;
            item.IsDamaged = x.IsDamaged;
            if (x.ConsumptionQuantity > 0) {
                consumeList.push(item);
            }
        });
        let uniqueProductMixingCombinationsToConsume = new Set();
        consumeList.forEach(item => {
            const combinationKey = `${item.ProductId}-${item.MixingName}`;
            uniqueProductMixingCombinationsToConsume.add(combinationKey);

        });
        this.TotalProductsQuantityFilled = uniqueProductMixingCombinationsToConsume.size;
        this.TotalRemainingProductToFillQuantity = this.TotalCountofProductToConsume - this.TotalProductsQuantityFilled;
        console.log(this.TotalRemainingProductToFillQuantity)
        if (this.ConsumedStockList.length == 0 && this.TotalProductsQuantityFilled != this.TotalCountofProductToConsume && CalculateOnSave) {
            this.IsAllProductFilled = false
            return;
        }
    }
    DisableSaveBtn() {
        if (this.Type == 'Store') {
            return false;
        }
        else if (this.consumedOrderReattempt) {
            return false;
        }
        else if (this.Type == 'Production' && (this.OutofStockList.length > 0 || this.TotalRemainingProductToFillQuantity <= 0)) {
            return false;
        }
        else {
            return true;
        }
    }
    FilterConsumedProduct() {
        if (this.IsMaterialFiltered == 'true') {
            this.StoreWiseStock = this.getFilteredItems();
        }
        else {
            this.StoreWiseStock = [...this.StoreWiseStockOriginal];
        }
    }
    getFilteredItems(): IssueSaleOrderProductsStockModel[] {
        try {
            // Step 1: Filter items first
            const itemsWithLabel = this.StoreWiseStock.filter(item => item.StockLabelId > 0);

            // Group and filter items without StockLabelId by MixingName-ProductName
            const filteredNonLabelItems = this.StoreWiseStock
                .filter(item => !item.StockLabelId)
                .reduce<{ [key: string]: IssueSaleOrderProductsStockModel }>((acc, item) => {
                    const key = `${item.MixingName}-${item.ProductName}`;
                    if (!acc[key] || item.Quantity > acc[key].Quantity) {
                        acc[key] = item;
                    }
                    return acc;
                }, {});

            // Combine filtered items
            const filteredItems = [
                ...itemsWithLabel,
                ...Object.values(filteredNonLabelItems)
            ];

            // Step 2: Create hierarchical structure from filtered items
            const groupedMap = new Map<string, Map<string, IssueSaleOrderProductsStockModel[]>>();

            // Group filtered items by MixingName and ProductName
            filteredItems.forEach(item => {
                const mixingName = item.MixingName || '';
                const productName = item.ProductName || '';

                if (!groupedMap.has(mixingName)) {
                    groupedMap.set(mixingName, new Map());
                }
                const productMap = groupedMap.get(mixingName)!;

                if (!productMap.has(productName)) {
                    productMap.set(productName, []);
                }
                productMap.get(productName)!.push(item);
            });

            // Step 3: Convert to sorted array
            const result: IssueSaleOrderProductsStockModel[] = [];

            // Sort by MixingName
            const sortedMixingNames = Array.from(groupedMap.keys()).sort();

            sortedMixingNames.forEach(mixingName => {
                const productMap = groupedMap.get(mixingName)!;
                // Sort by ProductName
                const sortedProductNames = Array.from(productMap.keys()).sort();

                sortedProductNames.forEach(productName => {
                    const items = productMap.get(productName)!;
                    // Sort by BatchNo
                    const sortedItems = items.sort((a, b) =>
                        (a.Batch || '').localeCompare(b.Batch || '')
                    );

                    result.push(...sortedItems);
                });
            });

            return result;

        } catch (error) {
            console.error('Error in getFilteredItems:', error);
            return [];
        }
    }
    GetAllStoreNewCategory(categoryname: string) {
        this.newCategory = this.SelectedMaterialCategory == 'Other' || this.SelectedMaterialCategory == 'Packaging' ? true : false;
        if (this.newCategory) {
            let url = this.ApiUrl + "store/getallstores";
            this.http.get<StoreModel[]>(url).subscribe(res => {
                this.NewCategoryStoreList = res;
                this.SelectedRack = 0;
                this.IsRack = true;
            }, res => {
                this.count++;
                if (this.count < 2) { this.GetAllStoreNewCategory(categoryname); }
            });
        }
        else {
            this.IsRack = false;
        }
    }
    onSelectedStoreChange(StoreId: number) {
        this.GetStoreRackStock(StoreId);
        this.SelectedRack = 0;
        this.IsRack = false;

        this.NewCatSelectedProductType = '';
        this.IsProductType = true;
        this.IsCategory = true;
        this.SelectedRawMaterialCategory = 0;
        this.selectedproductId = 0;
        this.IsProduct = true;
        this.SelectedUnit = '-';
        this.SelectedSupplier = '';
        this.IsSupplier = true;
        this.SelectedBatch = '';
        this.IsBatch = true;
        this.SelectedBarcode = '';
        this.IsBarcode = true;
        this.IsStore = true;
        this.StockQTY = 0
    }
}
