import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AlertMessageService } from 'src/PmsUIApp/Services/AlertMessageService';
import { LoadingService } from '../../../Services/loadingService';
import { SaleOrderPriceHistoryModel } from 'src/PmsUIApp/Models/SalesOrderModel';
import { SaleOrderPriceHistoryService } from '../services/SaleOrderPriceHistory';
import { SoDrawerService } from 'src/PmsUIApp/Services/SoDrawerService';

@Component({
    selector: 'app-sale-order-price-history',
    templateUrl: './SaleOrderPriceHistory.component.html',
    styleUrls: ['./SaleOrderPriceHistory.component.css']
})
export class SaleOrderPriceHistoryComponent implements OnInit {
    isPriceHistoryVisible = false;
    PriceHistoryList: SaleOrderPriceHistoryModel[] = [];
    selectedNumberOfRecords = "3";
    isLoading = false;
    
    // Store the current request parameters
    private currentRequest = {
        SaleFormulationCodeId: 0,
        CustomerId: 0,
        Thick: 0
    };

    constructor(
        private priceHistoryService: SaleOrderPriceHistoryService,
        private alertService: AlertMessageService,
        private loader: LoadingService,
        private SoDrawerService: SoDrawerService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.priceHistoryService.isPriceHistoryVisible$.subscribe(
            visible => {
                this.isPriceHistoryVisible = visible;
                this.cdr.detectChanges();
            }
        );
    }

    // Initial call from Sale Order Add page
    GetPriceHistory(SaleFormulationCodeId: number, CustomerId: number, Thick: number, numberOfRecords: number): void {
        // Store the parameters for later use
        this.currentRequest = {
            SaleFormulationCodeId,
            CustomerId,
            Thick
        };
        
        this.fetchPriceHistory(numberOfRecords);
    }

    // Handle dropdown change
    onNumberOfRecordsChange(numberOfRecords: string): void {
        this.fetchPriceHistory(parseInt(numberOfRecords));
    }

    // Common method to fetch price history
    private fetchPriceHistory(numberOfRecords: number): void {
        try {
            this.loader.show();
            this.isLoading = true;
            
            this.priceHistoryService.getPriceHistory(
                this.currentRequest.SaleFormulationCodeId,
                this.currentRequest.Thick,
                this.currentRequest.CustomerId,
                numberOfRecords
            ).subscribe({
                next: (response) => {

                    this.PriceHistoryList = response.ResponseBody;
                    this.loader.hide();
                    this.isLoading = false;
                    this.cdr.detectChanges();
                },
                error: (error) => {
                    this.alertService.error(error.error);
                    this.loader.hide();
                    this.isLoading = false;
                    this.cdr.detectChanges();
                }
            });
        } catch (error) {
            this.alertService.error('An error occurred while fetching price history.');
            this.loader.hide();
            this.isLoading = false;
            this.cdr.detectChanges();
        }
    }

    handlePriceHistoryCancel(): void {
        this.priceHistoryService.hidePriceHistory();
    }

    OpenSODetails(SaleOrderId: number): void {
        this.SoDrawerService.SaleOrderId = SaleOrderId.toString();
        this.SoDrawerService.show();
    }
}

