import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import moment from "moment";
import { NzModalService } from "ng-zorro-antd/modal";
import { Modules, Responsibility } from "src/PmsUIApp/Models/Enums";
import {
  AddWhatsAppTemplate,
  AddWhatsAppTemplateParameter,
  NotificationGroups,
  WhatsAppTemplateExport,
  WhatsAppTemplateList,
} from "src/PmsUIApp/Models/Notification";
import { AlertMessageService } from "src/PmsUIApp/Services/AlertMessageService";
import { AuthService } from "src/PmsUIApp/Services/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-whats-app-template-list",
  templateUrl: "./whats-app-template-list.component.html",
  styleUrls: ["./whats-app-template-list.component.css"],
})
export class WhatsAppTemplateListComponent {
  constructor(
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private auth: AuthService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) { }

  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false,
  };

  ApiUrl = environment.Api_Url;
  validateForm!: UntypedFormGroup;
  validateFormParameter!: UntypedFormGroup;
  PopUpTitle: string = "Add New Notification Group";
  PopUpTitleParameter: string = "Add New Parameter";
  searchValue = "";
  IsSearching = false;
  isTableLoading: boolean = false;
  isVisible = false;
  isVisibleParameter = false;
  isLoading: boolean = false;
  isLoadingParameter: boolean = false;
  isShownEdit: boolean = false;
  IsEditParameter: boolean = false;
  IsCreateCopy: boolean = false;
  WhatsAppTemplateTitle: string = "";

  WhatsAppTemplateList: WhatsAppTemplateList[] = [];
  WhatsAppTemplateListOriginal: WhatsAppTemplateList[] = [];

  AddWhatsAppTemplate: AddWhatsAppTemplate = new AddWhatsAppTemplate();
  AddWhatsAppTemplateParameter: AddWhatsAppTemplateParameter = new AddWhatsAppTemplateParameter();
  WhatsAppTemplateListExport: WhatsAppTemplateExport[] = [];
  exportfields: any[] = [];
  exportoptions = {
    headers: [
      "Provider Name",
      "Provider Template Name",
      "Provider Template Description",
      "Added By",
      "Added Date",
    ],
  };

  fields: WhatsAppTemplateExport = new WhatsAppTemplateExport();
  count: number;

  ProviderNameList: any[] = [
    { id: 1, name: "Brevo", value: "Brevo" }
  ];

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(
      Modules.NotificationWhatsappTemplate,
      Responsibility.View
    );
    this.permission.Add = this.auth.CheckResponsibility(
      Modules.NotificationWhatsappTemplate,
      Responsibility.Add
    );
    this.permission.Edit = this.auth.CheckResponsibility(
      Modules.NotificationWhatsappTemplate,
      Responsibility.Edit
    );
    this.permission.Delete = this.auth.CheckResponsibility(
      Modules.NotificationWhatsappTemplate,
      Responsibility.Delete
    );

    if (this.permission.View != true) {
      this.router.navigate(["/home/unauthorized"]);
    }

    this.validateForm = this.fb.group({
      ProviderName: ["", [Validators.required]],
      ProviderTemplateId: [],
      ProviderTemplateName: ["", [Validators.required]],
      ProviderTemplateDescription: ["", [Validators.required]],
    });

    this.validateFormParameter = this.fb.group({
      ParameterName: ["", [Validators.required]],
      ParameterType: ["", [Validators.required]],
      IsRequired: [false, [Validators.required]],
      DefaultValue: ["", [Validators.required]],
    });

    this.GetAllWhatsAppTemplateList();
  }

  GetAllWhatsAppTemplateList() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "notificationconfig/getwhatsapptemplate";

    this.http.get<WhatsAppTemplateList[]>(url).subscribe(
      (res) => {
        res.forEach((item, index) => {
          item.SerialNumber = index + 1;
          item.ExpandRecord = false;
        });

        this.WhatsAppTemplateList = res;
        this.WhatsAppTemplateListOriginal = res;
        this.isTableLoading = false;
        this.WhatsAppTemplateListExport = res;
        this.exportfields = [];

        this.WhatsAppTemplateListExport.forEach((x) => {
          this.fields = new WhatsAppTemplateExport();

          this.fields.ProviderName = x.ProviderName;
          this.fields.ProviderTemplateName = x.ProviderTemplateName;
          this.fields.ProviderTemplateDescription =
            x.ProviderTemplateDescription;
          this.fields.AddedBy =
            x.AddedBy;
          this.fields.AddedDate =
            x.AddedDate;

          this.exportfields.push(this.fields);
        });
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllWhatsAppTemplateList();
        }
      }
    );
  }

  onKeydown(event: any) {
    if (
      (event.target.selectionStart === 0 && event.code === "Space") ||
      (event.key === "Enter" && event.keyCode === 13)
    ) {
      event.preventDefault();
      event = this.search();
    }
  }
  ValidateText() {
    this.searchValue.trim();
    this.searchValue = this.searchValue.trim();
  }

  search() {
    var res = this.WhatsAppTemplateListOriginal;
    this.WhatsAppTemplateList = res.filter((item: WhatsAppTemplateList) => {
      if (
        item?.ProviderName?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      } else if (
        item?.ProviderTemplateDescription?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.ProviderTemplateName?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      else if (
        item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  }

  reset(): void {
    this.searchValue = "";
    this.search();
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        "whatsAppTemplate" + exportdate,
        this.exportoptions
      );
  }

  handleDelete(data: AddWhatsAppTemplate) {
    const modal = this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Are you sure to delete this Tempate?",
      nzOkDanger: true,
      nzOkText: "Yes",
      nzOnOk: () => this.DeleteWhatsApp(data),
    });
    setTimeout(() => modal.destroy(), 5000);
  }

  DeleteWhatsApp(data: AddWhatsAppTemplate) {
    let url = this.ApiUrl + "notificationconfig/whatsapptemplateupdate";
    data.Disabled = true;
    this.http.post<any>(url, data).subscribe({
      next: res => {
        this.alertService.success("Deleted Successful"); this.isLoading = false;
        this.GetAllWhatsAppTemplateList();
      },
      error: res => {
        if (res.StatusCode == 400) {
          this.alertService.warning(res.error.ResponseBody)
        }
        else {
          this.alertService.error(res.error.ResponseBody); this.isLoading = false;
        }
      },
    });
  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveWhatsAppTemplate();
  }

  handleOkParameter(): void {
    this.isLoadingParameter = true;
    this.SaveWhatsAppTemplateParameter();
  }

  SaveWhatsAppTemplate() {
    this.AddWhatsAppTemplate.ProviderName =
      this.validateForm.get("ProviderName").value;
    this.AddWhatsAppTemplate.ProviderTemplateId =
      this.validateForm.get("ProviderTemplateId").value;
    this.AddWhatsAppTemplate.ProviderTemplateName = this.validateForm.get(
      "ProviderTemplateName"
    ).value;
    this.AddWhatsAppTemplate.ProviderTemplateDescription =
      this.validateForm.get("ProviderTemplateDescription").value;

    if (!this.Validate(this.AddWhatsAppTemplate)) {
      this.isLoading = false;
      return;
    }

    if (this.isShownEdit) {
      let url = this.ApiUrl + "notificationconfig/whatsapptemplateupdate";
      this.http
        .post<any>(url, this.AddWhatsAppTemplate)
        .subscribe({
          next: (res) => {
            this.alertService.success(res.ResponseBody);
            this.isLoading = this.isVisible = false;
            this.GetAllWhatsAppTemplateList();
          },
          error: (res) => {
            this.alertService.error(res.error);
            this.isLoading = this.isVisible = false;
          },
        });
    } else if (this.IsCreateCopy) {
      let url = this.ApiUrl + "notificationconfig/copywhatsappparameter";
      this.http
        .post<any>(url, this.AddWhatsAppTemplate)
        .subscribe({
          next: (res) => {
            this.alertService.success(res.ResponseBody);
            this.isLoading = this.isVisible = false;
            this.GetAllWhatsAppTemplateList();
          },
          error: (res) => {
            this.alertService.error(res.error.ResponseBody);
            this.isLoading = this.isVisible = false;
          },
        });
    } else {
      let url = this.ApiUrl + "notificationconfig/addwhatsapptemplate";
      this.http
        .post<any>(url, this.AddWhatsAppTemplate)
        .subscribe({
          next: (res) => {
            this.alertService.success(res.ResponseBody);
            this.isLoading = this.isVisible = false;
            this.GetAllWhatsAppTemplateList();
          },
          error: (res) => {
            this.alertService.error(res.error);
            this.isLoading = this.isVisible = false;
          },
        });
    }
  }

  ShowAddWhatsAppTemplateParameter(data: WhatsAppTemplateList) {
    this.AddWhatsAppTemplateParameter.TemplateMasterId = data.WhatsAppTemplateMasterId;
    this.showModalParameter();
  }

  showModalParameter() {
    this.PopUpTitleParameter = "Add New Parameter";
    this.isVisibleParameter = true;
    this.IsEditParameter = false;
  }

  SaveWhatsAppTemplateParameter() {
    if (this.validateFormParameter.invalid) {
      Object.values(this.validateFormParameter.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoadingParameter = false
      return;
    }

    this.AddWhatsAppTemplateParameter.ParameterName = this.validateFormParameter.get("ParameterName").value;
    this.AddWhatsAppTemplateParameter.ParameterType = this.validateFormParameter.get("ParameterType").value;
    this.AddWhatsAppTemplateParameter.IsRequired = this.validateFormParameter.get("IsRequired").value;
    this.AddWhatsAppTemplateParameter.DefaultValue = this.validateFormParameter.get("DefaultValue").value;

    let url = this.ApiUrl + "notificationconfig/notificationtemplateparameteraddupdate";
    this.http.post<any>(url, this.AddWhatsAppTemplateParameter).subscribe({
      next: (res) => {
        this.alertService.success(res.ResponseBody);
        this.isLoadingParameter = this.isVisibleParameter = false;
        this.GetAllWhatsAppTemplateList();
      },
      error: (res) => {
        this.alertService.error(res.error);
        this.isLoadingParameter = this.isVisibleParameter = false;
      },
    });
  }

  copyTemplateWithParameters(data: WhatsAppTemplateList) {
    this.AddWhatsAppTemplate = data;
    this.IsCreateCopy = true;
    this.validateForm.setValue({
      ProviderName: data.ProviderName,
      ProviderTemplateId: data.ProviderTemplateId,
      ProviderTemplateName: data.ProviderTemplateName,
      ProviderTemplateDescription: data.ProviderTemplateDescription,
    });
    this.showModal();
  }

  Validate(model: any) {
    var Isvalid: boolean = true;
    if (model.ProviderName == "0" || model.ProviderName == "") {
      this.alertService.error("Select Provider Name");
      Isvalid = false;
    } else if (model.ProviderTemplateName == "") {
      this.alertService.error("Select Provider Temaplate Name");
      Isvalid = false;
    }
    return Isvalid;
  }

  showModal(): void {
    this.PopUpTitle = this.IsCreateCopy ? "Create Copy" : "Add WhatsApp Template";
    this.WhatsAppTemplateTitle = this.IsCreateCopy ? "Create Copy" : "Add New WhatsApp Template";
    this.isVisible = true;
    this.isShownEdit = false;
  }

  editTemplate(value) {
    if (value.WhatsAppTemplateMasterId) {
      this.PopUpTitle = "Edit WhatsApp Template";
      this.WhatsAppTemplateTitle = "Edit WhatsApp Template";
      this.isVisible = true;

      this.validateForm.setValue({
        ProviderName: value.ProviderName,
        ProviderTemplateId: value.ProviderTemplateId,
        ProviderTemplateName: value.ProviderTemplateName,
        ProviderTemplateDescription: value.ProviderTemplateDescription,
      });

      this.AddWhatsAppTemplate = new AddWhatsAppTemplate();
      this.AddWhatsAppTemplate.WhatsAppTemplateMasterId;

      this.isShownEdit = true;
    }
  }

  handleCancel(): void {
    this.isVisible = false;
    this.validateForm.reset();
  }

  handleCancelParameter(): void {
    this.isVisibleParameter = false;
    this.validateFormParameter.reset();
  }

  OpenEditPopParameter(parameter: AddWhatsAppTemplateParameter) {
    this.isVisibleParameter = true;
    this.IsEditParameter = true;
    this.AddWhatsAppTemplateParameter = parameter;
    this.validateFormParameter.setValue({
      ParameterName: parameter.ParameterName,
      ParameterType: parameter.ParameterType,
      IsRequired: parameter.IsRequired,
      DefaultValue: parameter.DefaultValue,
    });
  }

  handleDeleteParameter(parameter: AddWhatsAppTemplateParameter) {
    this.isLoadingParameter = true;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzOkDanger: true,
      nzOkText: 'Yes',
      nzContent: 'Are you sure to delete parameter: ' + parameter.ParameterName + '?',
      nzOnOk: () => this.DeleteWhatsAppParameter(parameter.ParameterId),
    });

    setTimeout(() => modal.destroy(), 5000);
  }

  DeleteWhatsAppParameter(parameterId: number) {
    let url = this.ApiUrl + "notificationconfig/notificationtemplateparameterdelete";
    this.http.post<any>(url, parameterId).subscribe({
      next: res => {
        this.alertService.success("Deleted Successful"); this.isLoadingParameter = false;
        this.GetAllWhatsAppTemplateList();
      },
    });
  }
}
