import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, Observer } from 'rxjs';
import { Modules, Responsibility } from 'src/PmsUIApp/Models/Enums';
import { AuthService } from 'src/PmsUIApp/Services/auth.service';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../Authentication/UserInfo';
import { PackagingTypeModel } from '../../Models/MeasureUnitModel';
import { AlertMessageService } from '../../Services/AlertMessageService';
import * as moment from 'moment';
@Component({
  selector: 'app-packaging-type',
  templateUrl: './packagingtype.component.html',
  styleUrls: ['./packagingtype.component.css']
})
export class PackagingTypeComponent implements OnInit {
  validateForm!: UntypedFormGroup;
  ApiUrl = environment.Api_Url;
  PackagingTypeList: PackagingTypeModel[] = [];
  PackagingTypeListOriginal: PackagingTypeModel[] = [];
  NewPackagingType: PackagingTypeModel = new PackagingTypeModel;
  isVisible = false;
  isLoading: boolean = false;
  isTableLoading: boolean = false;
  PopUpTitle: string = "Add New Packaging Type";
  PackagingTypeNameError = 'Enter Packaging Type Name';
  PackagingTypeCodeError = 'Enter Packaging Type Code';
  myDateValue: Date | undefined;
  toDate: Date | undefined;
  isValidDate: any
  searchValue = ''
  options = {
    headers: ["Packaging Type Name", "Packaging Type Code", "Added Date", "Added By"]
  };
  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false
  }
  count: 0;
  constructor(private fb: UntypedFormBuilder, private auth: AuthService, private router: Router,
    public http: HttpClient, private alertService: AlertMessageService, private modalService: NzModalService) {
  }

  ngOnInit() {
    this.permission.View = this.auth.CheckResponsibility(Modules.PackagingType, Responsibility.View);
    this.permission.Add = this.auth.CheckResponsibility(Modules.PackagingType, Responsibility.Add);
    this.permission.Edit = this.auth.CheckResponsibility(Modules.PackagingType, Responsibility.Edit);
    this.permission.Delete = this.auth.CheckResponsibility(Modules.PackagingType, Responsibility.Delete);
    if (this.permission.View != true) {
      this.router.navigate(['/home/unauthorized']);
    }

    this.validateForm = this.fb.group({
      PackagingTypeName: [null, [Validators.required, Validators.minLength(3)], [this.PackagingTypeNameAsyncValidator]],
      PackagingTypeCode: [null]

    });
    this.GetAllPackagingType();
  }

  reset(): void {
    this.searchValue = '';
    this.search();
    this.GetAllPackagingType();
    this.myDateValue = undefined;
    this.toDate = undefined;
  }
  reverseAndTimeStamp(dateString: any) {
    const reverse = new Date(dateString.split('-').reverse().join('-'));
    return reverse.getTime();
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {
      this.alertService.error("Start date and end date are required.");
      this.isValidDate = false;
    }
    const isReversed = moment(sDate.split('-').reverse().join('-')
    ).isAfter(eDate.split('-').reverse().join('-')
    )
    if ((sDate != null && eDate != null) && isReversed) {
      this.alertService.error("End date should be grater then start date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }

  search() {
    var res = this.PackagingTypeListOriginal;
    var res2 = this.PackagingTypeListOriginal
    let fromdate = moment(this.myDateValue).format('DD-MM-YYYY');
    let todate = moment(this.toDate).format('DD-MM-YYYY');
    this.isValidDate = this.validateDates(fromdate, todate);
    if (this.myDateValue && this.toDate) {
      const selectepolist = res.filter((m: PackagingTypeModel) => {
        return (
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) >=
          this.reverseAndTimeStamp(fromdate) &&
          this.reverseAndTimeStamp(moment(m.AddedDate).format('DD-MM-YYYY')) <=
          this.reverseAndTimeStamp(todate)
        );
      });
      this.PackagingTypeList = selectepolist;
    }
    else if (!this.myDateValue && !this.toDate) {
      this.PackagingTypeList = res.filter(
        (item: PackagingTypeModel) =>
          item?.PackagingTypeName?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.PackagingTypeCode?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.Email?.toLowerCase().includes(this.searchValue?.toLowerCase()) ||
          item?.AddedBy?.Name?.toLowerCase().includes(this.searchValue?.toLowerCase())
      )
    }

  }


  onKeydown(event: any) {
    if (event.target.selectionStart === 0 && event.code === "Space" || event.key === "Enter" && event.keyCode === 13) {

      event.preventDefault();
      event = this.search()
    }
  }
  ValidateText() {
    this.searchValue.trim()
    this.searchValue = this.searchValue.trim();

  }

  get f() { return this.validateForm.controls; }

  CheckUserAddPermission(UserName: string) {
    if (this.permission.Delete) {
      return true;
    }
    else if (UserInfo.EmailID == UserName && this.permission.Add) {
      return true;
    } else {
      return false;
    }
  }

  GetAllPackagingType() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "data/getallpackagingtypes";
    this.http.get<any>(url).subscribe(res => {
      this.PackagingTypeList = res;
      this.PackagingTypeListOriginal = res;

      this.isTableLoading = false;
    }, res => {
      this.count++;
      if (this.count < 2) {
        this.GetAllPackagingType();
      }
    });
  }
  SavePackagingType() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });

        }
      });
      this.isLoading = false
      return;
    }

    this.NewPackagingType.PackagingTypeName = this.f['PackagingTypeName'].value;
    this.NewPackagingType.PackagingTypeCode = this.f['PackagingTypeCode'].value;
    let url = this.ApiUrl + "data/addupdatepackagingtype";
    this.http.post<PackagingTypeModel>(url, this.NewPackagingType).subscribe({

      next: res => { this.alertService.success(res); this.isLoading = this.isVisible = false; this.GetAllPackagingType(); },
      error: res => { this.alertService.error(res.error); this.isLoading = this.isVisible = false; },

    });

  }

  OpenEditPop(data: PackagingTypeModel) {
    this.PopUpTitle = "Modify Packaging Type Details";
    this.validateForm.setValue(
      {
        PackagingTypeName: data.PackagingTypeName,
        PackagingTypeCode: data.PackagingTypeCode
      }
    )
    this.NewPackagingType = new PackagingTypeModel();
    this.NewPackagingType.PackagingTypeId = data.PackagingTypeId;

    this.isVisible = true;
  }

  showModal(): void {
    this.PopUpTitle = "Add New Packaging Type";
    this.validateForm.reset();
    this.NewPackagingType = new PackagingTypeModel();
    this.isVisible = true;
  }

  handleOk(): void {

    this.isLoading = true;
    this.SavePackagingType();

  }

  handleCancel(): void {
    this.isVisible = false;
  }
  DeletePackagingType() {
    let url = this.ApiUrl + "data/disablepackagingtype";
    this.http.post<any>(url, this.NewPackagingType.PackagingTypeId).subscribe({

      next: res => {

        this.alertService.success(res); this.isLoading = this.isVisible = false;
        this.GetAllPackagingType();

      },
      error: res => { this.alertService.error(res.error.ResponseBody); this.isLoading = false; },

    });

  }
  handleDelete(data: PackagingTypeModel) {
    this.NewPackagingType = data;
    const modal = this.modalService.confirm({
      nzTitle: 'Confirm',
      nzContent: 'Are you sure to delete this Packaging Type?',
      nzOnOk: () => this.DeletePackagingType(),
    });

    setTimeout(() => modal.destroy(), 5000);

  }

  PackagingTypeNameAsyncValidator = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        var res = this.PackagingTypeList.filter(x => x.PackagingTypeId != this.NewPackagingType.PackagingTypeId);
        var nre = res.filter(x => x.PackagingTypeName.toLowerCase() == control.value.toLowerCase());
        this.PackagingTypeNameError = '';

        if (control.value == "") {
          this.PackagingTypeNameError = "Enter Packaging Type Name";
          observer.next({ error: true, duplicated: true });
        }
        else if (nre.length > 0) {
          this.PackagingTypeNameError = "Packaging Type already exist";
          observer.next({ error: true, duplicated: true });
        } else {
          this.PackagingTypeNameError = "Enter Packaging Type Name";
          observer.next(null);
        }
        observer.complete();
      }, 1);
    });

}
