<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>Notification Group List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your notifications here</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button nz-button nzType="primary" (click)="showModal()" *ngIf="permission.Add">
          Add New
        </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>

    <div style="padding: 8px; float: right">
      <input type="text" style="width: 250px; margin-right: 8px" nz-input placeholder="Search notification type"
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />

      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px">
        Search
      </button>

      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()">
        <i nz-icon nzType="export" nzTheme="outline"></i> Export
      </button>
    </div>

    <nz-table [nzPageSize]="100" style="width: 100%" nzBordered [nzScroll]="{ x: '1200px', y: '515px' }" nzSize="small"
      #basicTable [nzData]="this.NotificationGroupList" [nzLoading]="isTableLoading">
      <thead>
        <tr>
          <th nzWidth="50px" nzLeft>S.No</th>
          <th nzWidth="130px">Notification Type</th>
          <th nzWidth="100px">User Type</th>
          <th nzWidth="160px">Name</th>
          <th nzWidth="100px">Email</th>
          <th nzWidth="100px">Mobile</th>
          <th nzWidth="110px">WhatsApp Notification</th>
          <th nzWidth="100px">Enabled To Email</th>
          <th nzWidth="100px">Enabled CC Email</th>
          <th nzWidth="100px">Enabled BCC Email</th>
          <th nzWidth="100px" nzRight style="text-align: center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of NotificationGroupList; index as i">
          <td nzLeft>{{ data.SerialNumber }}</td>
          <td>{{ data.NotificationType }}</td>
          <td>{{ data.UserType }}</td>
          <td>{{ data.Name }}</td>
          <td>{{ data.Email }}</td>
          <td>{{ data.MobileNumber }}</td>
          <td>{{ data.IsWhatsAppNotificationEnabled ? "Yes" : "No" }}</td>
          <td>{{ data.EnableToEmail ? "Yes" : "No" }}</td>
          <td>{{ data.EnableCcemail ? "Yes" : "No" }}</td>
          <td>{{ data.EnableBccemail ? "Yes" : "No" }}</td>

          <td nzRight style="text-align: center">
            <button class="btn btn-sm btn-light-primary" (click)="editNotificationGroup(data)" *ngIf="permission.Edit">
              Edit
            </button>
            &nbsp;
            <button class="btn btn-sm btn-light-danger" (click)="handleDelete(data)" *ngIf="permission.Delete">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <nz-modal [(nzVisible)]="isVisible" [nzStyle]="{ top: '20px' }" nzWidth="700px" [nzTitle]="modalTitle"
      [nzContent]="modalContent" [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{ NotificationTitle }}</ng-template>
      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm">
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Notification Type</nz-form-label>
            <nz-form-control [nzSpan]="14" nzValidateStatus="success">
              <nz-select name="select-error1" nzShowSearch formControlName="NotificationType"
                [(ngModel)]="SelectNotificationType" nzPlaceHolder="Select Notification Type"
                (ngModelChange)="SetReminderScheduleAllowed($event)">
                <nz-option *ngFor="let notification of NotificationTypeList" [nzValue]="notification"
                  [nzLabel]="notification.name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngIf="this.AddNotificationGroups.IsReminderScheduleAllowed">
            <nz-form-label [nzSpan]="8" [nzRequired]="this.AddNotificationGroups.IsReminderScheduleRequired">CRON
              Schedule Expression</nz-form-label>
            <nz-form-control [nzSpan]="14" [nzErrorTip]="cronErrorTpl">
              <input nz-input formControlName="CronScheduleExpression" name="CronScheduleExpression"
                placeholder="Enter cron expression (e.g., 0 0 * * *)" />
              <ng-template #cronErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">
                  Please input cron expression
                </ng-container>
                <ng-container *ngIf="control.hasError('invalidCron')">
                  Invalid cron expression
                </ng-container>
              </ng-template>

              <!-- Schedule Description -->
              <div *ngIf="cronScheduleDescription" class="mt-2 text-success">
                <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
                {{ cronScheduleDescription }}
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngIf="this.AddNotificationGroups.IsReminderScheduleAllowed">
            <nz-form-control [nzSpan]="12" [nzOffset]="7">
              <div class="text-muted">
                <small>
                  Examples:<br>
                  "0 0 * * *" - Every day at midnight<br>
                  "0 0 * * MON" - Every Monday at midnight<br>
                  "0 */4 * * *" - Every 4 hours
                </small>
              </div>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>User Type</nz-form-label>
            <nz-form-control [nzSpan]="14" nzValidateStatus="success">
              <nz-select name="select-error2" nzShowSearch formControlName="UserType"
                (ngModelChange)="onUserTypeChange($event)" nzPlaceHolder="Select User Type">
                <nz-option *ngFor="let user of UserTypeList" [nzValue]="user.value" [nzLabel]="user.name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <!-- Show User List -->
          <nz-form-item *ngIf="showInternalUser">
            <nz-form-label [nzSpan]="8" nzRequired>Users</nz-form-label>
            <nz-form-control [nzSpan]="14" nzValidateStatus="success">
              <nz-select name="select-error3" nzShowSearch formControlName="UserMasterId" nzPlaceHolder="Select User">
                <nz-option *ngFor="let s of UserList" [nzValue]="s.UserId" [nzLabel]="s.Name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <!-- Show Customer List -->
          <nz-form-item *ngIf="showCustomerList">
            <nz-form-label [nzSpan]="8" nzRequired>Customers</nz-form-label>
            <nz-form-control [nzSpan]="14" nzValidateStatus="success">
              <nz-select name="select-error3" nzShowSearch formControlName="UserMasterId"
                nzPlaceHolder="Select Customer">
                <nz-option *ngFor="let s of CustomerList" [nzValue]="s.CustomerId"
                  [nzLabel]="s.CustomerName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item *ngIf="isUserTypeExternal">
            <nz-form-label [nzSpan]="8" nzRequired>Name</nz-form-label>
            <nz-form-control [nzSpan]="14">
              <input nz-input formControlName="Name" name="Name" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item *ngIf="isUserTypeExternal">
            <nz-form-label [nzSpan]="8" nzRequired>Email</nz-form-label>
            <nz-form-control [nzSpan]="14">
              <input nz-input formControlName="Email" name="Email" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item *ngIf="isUserTypeExternal">
            <nz-form-label [nzSpan]="8" nzRequired>Mobile Number</nz-form-label>
            <nz-form-control [nzSpan]="14">
              <input nz-input formControlName="MobileNumber" name="MobileNumber" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>WhatsApp Notification</nz-form-label>
            <nz-form-control [nzSpan]="14">
              <input type="checkbox" (change)="setWhatsAppNotification($event)"
                formControlName="IsWhatsAppNotificationEnabled" name="IsWhatsAppNotificationEnabled" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngIf="this.AddNotificationGroups.IsWhatsAppNotificationEnabled">
            <nz-form-label [nzSpan]="8" nzRequired>WhatsApp Template Name</nz-form-label>
            <nz-form-control [nzSpan]="14">
              <nz-select name="select-error1" nzShowSearch formControlName="WhatsAppTemplateMasterId"
                nzPlaceHolder="Select Template">
                <nz-option *ngFor="let s of WhatsAppTemplateList" [nzValue]="s.WhatsAppTemplateMasterId"
                  [nzLabel]="s.ProviderTemplateName"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Keep In To Email</nz-form-label>
            <nz-form-control [nzSpan]="14">
              <input type="checkbox" (change)="setEnableToEmail($event)" formControlName="EnableToEmail"
                name="EnableToEmail" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Keep In CC Email</nz-form-label>
            <nz-form-control [nzSpan]="14">
              <input type="checkbox" (change)="setEnableCcemail($event)" formControlName="EnableCcemail"
                name="EnableCcemail" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Keep In BCC Email</nz-form-label>
            <nz-form-control [nzSpan]="14">
              <input type="checkbox" (change)="setEnableBccemail($event)" formControlName="EnableBccemail"
                name="EnableBccemail" />
            </nz-form-control>
          </nz-form-item>
        </form>
      </ng-template>

      <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="handleCancel()">
          Cancel
        </button>
        <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isLoading" *ngIf="permission.Add || permission.Edit">
          Save Notification Group
        </button>
      </ng-template>
    </nz-modal>
  </div>
</div>