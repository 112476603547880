import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import moment from "moment";
import { NzModalService } from "ng-zorro-antd/modal";
import { Modules, Responsibility } from "src/PmsUIApp/Models/Enums";
import {
  AddWhatsAppConfig,
  StagesTableModel,
  WhatsAppConfigExport,
  WhatsAppConfigList,
} from "src/PmsUIApp/Models/Notification";
import { AlertMessageService } from "src/PmsUIApp/Services/AlertMessageService";
import { AuthService } from "src/PmsUIApp/Services/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-whatsapp-config",
  templateUrl: "./whatsapp-config.component.html",
  styleUrls: ["./whatsapp-config.component.css"],
})
export class WhatsappConfigComponent {
  constructor(
    public http: HttpClient,
    private alertService: AlertMessageService,
    private modalService: NzModalService,
    private auth: AuthService,
    private router: Router,
    private fb: UntypedFormBuilder
  ) { }

  permission = {
    View: false,
    Add: false,
    Edit: false,
    Delete: false,
  };

  ApiUrl = environment.Api_Url;
  validateForm!: UntypedFormGroup;

  PopUpTitle: string = "Add New Notification Group";
  searchValue = "";
  IsSearching = false;
  isTableLoading: boolean = false;
  isVisible = false;
  isLoading: boolean = false;
  isShownEdit: boolean = false;
  WhatsAppConfigTitle: string = "";

  WhatsAppConfigList: WhatsAppConfigList[] = [];
  WhatsAppConfigListOriginal: WhatsAppConfigList[] = [];

  AddWhatsAppConfig: AddWhatsAppConfig = new AddWhatsAppConfig();

  WhatsAppConfigListExport: WhatsAppConfigExport[] = [];
  exportfields: any[] = [];
  exportoptions = {
    headers: [
      "ConfigName",
      "ProviderName",
      "RegisteredSenderNumber",
      "ProviderKey",
      "Added By",
      "Added Date",
    ],
  };

  fields: WhatsAppConfigExport = new WhatsAppConfigExport();
  count: number;

  ProviderNameList: any[] = [
    { id: 1, name: "Brevo", value: "Brevo" },
    { id: 2, name: "Facebook", value: "Facebook" },
  ];

  ConfigNameList: any[] = [
    { id: 1, name: "Primary", value: "Primary" },
    { id: 2, name: "Secondary", value: "Secondary" },
  ];

  ngOnInit(): void {
    this.permission.View = this.auth.CheckResponsibility(
      Modules.NotificationWhatsappConfiguration,
      Responsibility.View
    );
    this.permission.Add = this.auth.CheckResponsibility(
      Modules.NotificationWhatsappConfiguration,
      Responsibility.Add
    );
    this.permission.Edit = this.auth.CheckResponsibility(
      Modules.NotificationWhatsappConfiguration,
      Responsibility.Edit
    );
    this.permission.Delete = this.auth.CheckResponsibility(
      Modules.NotificationWhatsappConfiguration,
      Responsibility.Delete
    );

    if (this.permission.View != true) {
      this.router.navigate(["/home/unauthorized"]);
    }

    this.validateForm = this.fb.group({
      ConfigName: ["", [Validators.required]],
      ProviderName: ["", [Validators.required]],
      RegisteredSenderNumber: ["", [Validators.required]],
      ProviderKey: ["", [Validators.required]],
      MaxDailyMessages: [0, [Validators.required]],
      MaxMonthlyMessages: [0, [Validators.required]],
      ApiEndpoint: ["", [Validators.required]],
      WebhookUrl: [""],
    });

    this.GetAllWhatsAppConfigList();
  }

  GetAllWhatsAppConfigList() {
    this.isTableLoading = true;
    let url = this.ApiUrl + "notificationconfig/getwhatsappconfig";

    this.http.get<WhatsAppConfigList[]>(url).subscribe(
      (res) => {
        res.forEach((item, index) => {
          item.SerialNumber = index + 1;
        });

        this.WhatsAppConfigList = res;
        this.WhatsAppConfigListOriginal = res;
        this.isTableLoading = false;
        this.WhatsAppConfigListExport = res;
        this.exportfields = [];

        this.WhatsAppConfigListExport.forEach((x) => {
          this.fields = new WhatsAppConfigExport();

          this.fields.ConfigName = x.ConfigName;
          this.fields.ProviderName = x.ProviderName;
          this.fields.RegisteredSenderNumber = x.RegisteredSenderNumber;
          this.fields.ProviderKey = x.ProviderKey;
          this.fields.AddedBy = x.AddedBy;
          this.fields.AddedDate = x.AddedDate;
          this.fields.ProviderKey = x.ProviderKey;
          this.exportfields.push(this.fields);
        });
      },
      (res) => {
        this.count++;
        if (this.count < 2) {
          this.GetAllWhatsAppConfigList();
        }
      }
    );
  }

  onKeydown(event: any) {
    if (
      (event.target.selectionStart === 0 && event.code === "Space") ||
      (event.key === "Enter" && event.keyCode === 13)
    ) {
      event.preventDefault();
      event = this.search();
    }
  }
  ValidateText() {
    this.searchValue.trim();
    this.searchValue = this.searchValue.trim();
  }

  search() {
    var res = this.WhatsAppConfigListOriginal;
    this.WhatsAppConfigList = res.filter((item: WhatsAppConfigList) => {
      if (
        item?.ConfigName?.toLowerCase().includes(
          this.searchValue?.toLowerCase()
        )
      ) {
        return true;
      } else if (
        item?.ProviderName?.toLowerCase().includes(
          this.searchValue?.toLowerCase()
        )
      ) {
        return true;
      } else if (
        item?.RegisteredSenderNumber?.toLowerCase().includes(
          this.searchValue?.toLowerCase()
        )
      ) {
        return true;
      } else if (
        item?.ProviderKey?.toLowerCase().includes(
          this.searchValue?.toLowerCase()
        )
      ) {
        return true;
      } else if (
        item?.AddedBy?.toLowerCase().includes(this.searchValue?.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
  }

  reset(): void {
    this.searchValue = "";
    this.search();
  }

  export() {
    var exportdate = moment(new Date()).format("-DDMMYYYY-hhmmss");
    if (this.exportfields.length > 0)
      new AngularCsv(
        this.exportfields,
        "whatsAppConfig" + exportdate,
        this.exportoptions
      );
  }

  handleDelete(data: AddWhatsAppConfig) {
    const modal = this.modalService.confirm({
      nzTitle: "Confirm",
      nzContent: "Are you sure to delete this Config?",
      nzOkDanger: true,
      nzOkText: "Yes",
      nzOnOk: () => this.DeleteConfig(data),
    });
    setTimeout(() => modal.destroy(), 5000);
  }

  DeleteConfig(data: AddWhatsAppConfig) {
    let url = this.ApiUrl + "notificationconfig/whatsappconfigedit";
    data.Disabled = true;
    this.http.post<any>(url, data).subscribe({
      next: (res) => {
        this.alertService.success("Deleted Successfully");
        this.isLoading = false;
        this.GetAllWhatsAppConfigList();
      },
      error: (res) => {
        if (res.StatusCode == 400) {
          this.alertService.warning(res.error.ResponseBody);
        } else {
          this.alertService.error(res.error.ResponseBody);
          this.isLoading = false;
        }
      },
    });
  }

  handleOk(): void {
    this.isLoading = true;
    this.SaveWhatsAppTemplate();
  }

  SaveWhatsAppTemplate() {
    if (this.validateForm.invalid) {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      this.isLoading = false
      return;
    }
    this.AddWhatsAppConfig.ConfigName =
      this.validateForm.get("ConfigName").value;
    this.AddWhatsAppConfig.ProviderName =
      this.validateForm.get("ProviderName").value;
    this.AddWhatsAppConfig.RegisteredSenderNumber = this.validateForm.get(
      "RegisteredSenderNumber"
    ).value;
    this.AddWhatsAppConfig.ProviderKey =
      this.validateForm.get("ProviderKey").value;
    this.AddWhatsAppConfig.MaxDailyMessages = this.validateForm.get(
      "MaxDailyMessages"
    ).value;
    this.AddWhatsAppConfig.MaxMonthlyMessages = this.validateForm.get(
      "MaxMonthlyMessages"
    ).value;
    this.AddWhatsAppConfig.ApiEndpoint = this.validateForm.get(
      "ApiEndpoint"
    ).value;
    this.AddWhatsAppConfig.WebhookUrl = this.validateForm.get(
      "WebhookUrl"
    ).value;

    if (!this.Validate(this.AddWhatsAppConfig)) {
      this.isLoading = false;
      return;
    }

    if (this.isShownEdit) {
      let url = this.ApiUrl + "notificationconfig/whatsappconfigedit";
      this.http
        .post<WhatsAppConfigList>(url, this.AddWhatsAppConfig)
        .subscribe({
          next: (res) => {
            this.alertService.success("WhatsApp Config Updated Successfully");
            this.isLoading = this.isVisible = false;
            this.GetAllWhatsAppConfigList();
          },
          error: (res) => {
            this.alertService.error(
              "An error has been occured. Please try again"
            );
            this.isLoading = this.isVisible = false;
          },
        });
    } else {
      let url = this.ApiUrl + "notificationconfig/addwhatsappconfig";
      this.http.post<AddWhatsAppConfig>(url, this.AddWhatsAppConfig).subscribe({
        next: (res) => {
          this.alertService.success("WhatsApp Config Added Successfully");
          this.isLoading = this.isVisible = false;
          this.GetAllWhatsAppConfigList();
        },
        error: (res) => {
          this.alertService.error(
            "An error has been occured. Please try again"
          );
          this.isLoading = this.isVisible = false;
        },
      });
    }
  }

  Validate(model: any) {
    var Isvalid: boolean = true;
    if (model.ConfigName == "") {
      this.alertService.error("Select Config Name");
      Isvalid = false;
    } else if (model.ProviderName == "") {
      this.alertService.error("Select Provider Name");
      Isvalid = false;
    }

    return Isvalid;
  }

  showModal(): void {
    this.PopUpTitle = "Add WhatsApp Config";
    this.WhatsAppConfigTitle = "Add New WhatsApp Config";
    this.isVisible = true;
    this.isShownEdit = false;
  }

  editConfig(value) {
    if (value.WhatsAppConfigId) {
      this.PopUpTitle = "Edit WhatsApp Config";
      this.WhatsAppConfigTitle = "Edit WhatsApp Config";
      this.isVisible = true;

      this.validateForm.patchValue({
        ConfigName: value.ConfigName,
        ProviderName: value.ProviderName,
        RegisteredSenderNumber: value.RegisteredSenderNumber,
        ProviderKey: value.ProviderKey,
        MaxDailyMessages: value.MaxDailyMessages,
        MaxMonthlyMessages: value.MaxMonthlyMessages,
        ApiEndpoint: value.ApiEndpoint,
        WebhookUrl: value.WebhookUrl,
      });

      this.AddWhatsAppConfig = new WhatsAppConfigList();
      this.AddWhatsAppConfig.WhatsAppConfigId = value.WhatsAppConfigId;

      this.isShownEdit = true;
    }
  }

  handleCancel(): void {
    this.isVisible = false;
    this.validateForm.reset();
  }
}
