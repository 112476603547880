import { DemandModel } from "./DemandModel";
import { ESalesOrderStatus } from "./Enums";
import { KnittingDivisionStockModel } from "./KnittingDivisionStockModel";
import { SaleOrderCompleteProductionModel } from "./SalesOrderModel";
import { UserModel } from "./UserModel";
import { JumboInspectionModel } from "./WorkPlanModel";

export class ProductWiseStockWithSupplierReportModel {
  ProductId: number = 0;
  StockProductId: number = 0;
  ProductName: string = '';
  ProductType: string = '';
  ProductCategory: string = '';
  ProductFirstSubCategory: string = '';
  ProductSecSubCategory: string = '';
  ProductCode: string = '';
  Quantity: string = '';
  RecievedQuantity: string = '';
  Barcode: string = '';
  ManufacturedDate: string = '';
  ExpiryDate: string = '';
  PricePerUnit: string = '';
  Unit: string = '';
  Batch: string = '';
  ProductQuality: string = '';
  StoreId: number = 0;
  StoreName: string = '';
  StoreCode: string = '';
  RackId: number = 0;
  RackName: string = '';
  RackCode: string = '';
  ReceivedDate: string = '';
  ReceivedBy: string = '';
  SupplierName: string = '';
  MinimumQuantity: number = 0;
  IsBarcodeLabelExist: boolean = false;
}

export class ProductWiseStockWithSupplierReportParentModel {
  ProductName: string = '';
  ProductType: string = '';
  ProductCode: string = '';
  ProductCategory: string = '';
  ProductFirstCategory: string = '';
  ProductSecondCategory: string = '';
  Unit: string = '';
  TotalQty: number = 0;
  MinimumQuantity: number = 0;
  RecievedQuantity: number = 0;
  TotalAmount: string = '';
  ChildList: ProductWiseStockWithSupplierReportChildModel[] = []
}
export class ProductWiseStockWithSupplierReportChildModel {
  SupplierName: string = '';
  Quantity: string = '';
  RecievedQuantity: string = '';
  Barcode: string = '';
  ManufacturedDate: string = '';
  ExpiryDate: string = '';
  PricePerUnit: string = '';
  TotalAmount: string = '';

  Batch: string = '';
  StoreId: number = 0;
  StoreName: string = '';
  StoreCode: string = '';
  RackId: number = 0;
  RackName: string = '';
  RackCode: string = '';
  ReceivedDate: string = '';
  ReceivedBy: string = '';
  StockProductId: number = 0;
  IsBarcodeLabelExist: boolean = false;
  ProductQuality: string = '';
}
export class ProductWiseStockWithSupplierReportExportModel {
  ProductType: string = '';
  ProductCategory: string = '';
  ProductFirstSubCategory: string = '';
  ProductSecSubCategory: string = '';
  ProductName: string = '';
  ProductCode: string = '';
  Batch: string = '';
  ProductQuality: string = '';
  StoreName: string = '';
  RackName: string = '';
  SupplierName: string = '';
  Quantity: string = '';
  MinimumQuantity: number = 0;
  RecievedQuantity: string = '';
  Barcode: string = '';
  ManufacturedDate: string = '';
  ExpiryDate: string = '';
  PricePerUnit: string = '';
  Unit: string = '';
  TotalAmount: string = '';
  ReceivedDate: string = '';
  ReceivedBy: string = '';
}


export class SearchParamsProductStockReportModel {
  ProductId: number = 0;
  SupplierId: number = 0;
  FromDate!: Date;
  ToDate !: Date;

}

export class SearchParamsProductCategoryReportModel {
  ProductType: string = '';
  Unit: string = null;
  ProductCategoryId: number = 0;
  ProductCategory: string = '';
  ProductFirstSubCategoryId: number = 0;
  ProductFirstSubCategory: string = '';
  ProductSecSubCategoryId: number = 0;
  ProductSecSubCategory: string = '';
  ProductId: number = 0;
  BatchNo: string = '';
  SupplierId: number = 0;
  StoreId: number = 0;
  RackId: number = 0;
  ReceivedBy: string = '';
  isMinumQuantityCheck: boolean = false;
  IncludeWIPStore: string = 'true';
  ProductQuality: string = '';
}

export class ProductCategoryReportParentModel {
  ProductName: string = '';
  ProductType: string = '';
  ProductCode: string = '';
  ProductCategory: string = '';
  ProductFirstSubCategory: string = '';
  ProductSecSubCategory: string = '';
  TotalQty: number = 0;
  Unit: string = '';
  TotalAmount: number = 0;
  ChildList: ProductCategoryReportChildModel[] = []
}

export class ProductCategoryReportChildModel {
  Qty: number = 0;
  Rate: number = 0;
  Amount: number = 0;
  Unit: string = '';
}


export class WorkPlanJumboReportModel {
  WorkPlanJumboMasterId: number;
  WorkPlanOrdersId: number | null;
  WorkPlanId: number | null;
  OrderId: number | null;
  JumboRollDate: Date | null;
  JumboRollStartTime: Date | null;
  JumboRollEndTime: Date | null;
  JumboNo: string;
  SaleOrderNumber: string;
  SaleOrderId: number | null;
  SaleOrderStatus: ESalesOrderStatus;
  CustomerId: number | null;
  CustomerName: string;
  Rate: number | null;
  Amount: number | null;
  JumboRolQty: number | null;
  ActualQuantity: number | null;
  Weight: number | null;
  RackId: number | null;
  RackCode: string;
  RackName: string;
  StoreId: number | null;
  StoreCode: string;
  StoreName: string;
  Remark: string;
  Yield: number | null;
  AddedBy: string;
  AddedDate: Date | null;
  WastagePrint: number | null;
  WastageEmbossing: number | null;
  WastageLacquer: number | null;
  WastageVacuum: number | null;
  WastageTumbling: number | null;
  IsInspectionCompleted: boolean | null;
  FabricName: string;
  FirstGrade: number | null;
  AGrade: number | null;
  CUTPCGrade: number | null;
  FILMGrade: number | null;
  LOTGrade: number | null;
  NSGrade: number | null;
  WASTEGrade: number | null;
  SampleQuantity: number | null;
  JumboInspection: JumboInspectionModel[];
  SaleOrderCode !: string;
}

export class ProductionPlanningReportModel {
  OrderDate: string;
  SaleOrderNo: string;
  WorkPlanNo: string;
  ItemName: string;
  PCode: string;
  Grain: string;
  Colours: string;
  Width: string;
  Fabric: string;
  Thick: string;
  Finish: string;
  OrderQty: number | null;
  MFGQty: number | null;
  PendingQty: number | null;
  PrdLineSpeed: number | null;
  PreSkin: number | null;
  Skin: number | null;
  Foam: number | null;
  Adhesive: number | null;
  FabricGsm: number | null;
  PartyName: string;
  Remarks: string;
  ProductionCompletionRemarks: string;
  ProductionCompletedBy: string;
  ProductionCompletedDate: string;
  Status: string;
}
export class ProductionPlanningReportExportModel {
  OrderDate: string;
  SaleOrderNo: string;
  WorkPlanNo: string;
  ItemName: string;
  PCode: string;
  Grain: string;
  Colours: string;
  Width: string;
  Fabric: string;
  Thick: string;
  Finish: string;
  OrderQty: number | 0;
  PreSkin: number | 0;
  Skin: number | 0;
  Foam: number | 0;
  Adhesive: number | 0;
  FinalGSM: number | 0;
  FabricGsm: number | 0;
  PartyName: string;
  Remarks: string;
}
export class ProductionStatusReportExportModel {
  OrderDate: string;
  SaleOrderNo: string;
  WorkPlanNo: string;
  ItemName: string;
  PCode: string;
  Colours: string;
  OrderQty: number | 0;
  MFGQty: number | 0;
  ProductionCompletionRemarks: string;
  FinalGSM: number | 0
  PartyName: string;
  Status: string;
}
export class SalesReportModel {
  SerialNo: number;
  OrderDate: string;
  SaleOrderNo: string;
  WorkPlanNo: string;
  ItemName: string;
  PCode: string;
  Grain: string;
  Colours: string;
  Width: string;
  Fabric: string;
  Thick: string;
  Finish: string;
  OrderQty: number | null;
  MFGQty: number | null;
  PreSkin: number | null;
  Skin: number | null;
  Foam: number | null;
  Adhesive: number | null;
  FabricGsm: number | null;
  PartyName: string;
  Remarks: string;
  ProductionCompletionRemarks: string;
  Status: string;
  OrderStatus: string;
  CreatedDate: string;
  CreatedBy: string;
}
export class SalesReportExportModel {
  OrderDate: string;
  SaleOrderNo: string;
  ItemName: string;
  PCode: string;
  Grain: string;
  Colours: string;
  Width: string;
  Fabric: string;
  Thick: string;
  Finish: string;
  OrderQty: number | null;
  PreSkin: number | null;
  Skin: number | null;
  Foam: number | null;
  Adhesive: number | null;
  FabricGsm: number | null;
  PartyName: string;
  Remarks: string;
  Status: string;
  OrderStatus: string;
  CreatedDate: string;
  CreatedBy: string;
}
export class DateTypeModel {
  Text: string;
  Value: string;
}
export class PostProcessReportModel {
  SaleOrderId: string;
  SaleOrderType: string;
  SaleOrderNumber: string;
  SaleOrderStatus: string;
  SaleOrderCode: string;
  SaleOrderDate: Date | null;
  PrintAcceptedDate: Date | null;
  EmbossAcceptedDate: Date | null;
  VaccumAcceptedDate: Date | null;
  FinishCode: string;
  GrainName: string;
  ColorName: string;
  OrderQuantity: number | null;
  PrintQuantity: number | null;
  EmbossQuantity: number | null;
  VacuumQuantity: number | null;
  Remarks: string;
  PrintRemark: string;
  EmbossingRemark: string;
  VacuumRemark: string;
  CustomerName: string;
}
export class PostProcessReportPrintModel {
  Sno: number;
  Date: string;
  SaleOrderNumber: string;
  PartyName: string;
  SaleOrderCode: string;
  GrainName: string;
  ColorName: string;
  FinishCode: string;
  QTY: number | null;
  PrintQuantity: string;;
  EmbossQuantity: string;
  VacuumQuantity: string;
  Remarks: string;

}

export class YieldReportModel {
  SerialNo: number;
  SaleOrderNumber: string;
  SaleOrderId: number | null;
  JumboCount: number | null;
  Amount: number | null;
  ActualQuantity: number | null;
  Yield: number | null;
  AddedDate: Date | null;
  FabricName: string;
  FirstGrade: number | null;
  AGrade: number | null;
  CUTPCGrade: number | null;
  FILMGrade: number | null;
  LOTGrade: number | null;
  NSGrade: number | null;
  WASTEGrade: number | null;
  SampleQuantity: number | null;
  FirstGradeCount: number | null;
  AGradeCount: number | null;
  CUTPCGradeCount: number | null;
  FILMGradeCount: number | null;
  LOTGradeCount: number | null;
  NSGradeCount: number | null;
  WASTEGradeCount: number | null;
  SampleCount: number | null;
  SaleOrderCode !: string;
  JumboRolQty: number | null;
  WastageEmbossing: number | null;
  WastageLacquer: number | null;
  WastagePrint: number | null;
  WastageTumbling: number | null;
  WastageVacuum: number | null;
  IsShow: boolean = true;
  ManufacturingQuantity: number | null;
  SaleOrderQuantity: number;
}

export class WastageReportModel {
  SaleOrderNumber: string;
  ActualQuantity: number | null;
  SaleOrderCode !: string;
  JumboRolQty: number | null;
  WastageEmbossing: number | null;
  WastageLacquer: number | null;
  WastagePrint: number | null;
  WastageTumbling: number | null;
  WastageVacuum: number | null;
  IsShow: boolean = true;
  WorkPlanNo: string;
  JumboNo: string;
}

export class ProductStockSummaryForJumboGradeModel {
  SerialNo: number;
  SaleOrderId: number;
  SaleOrderNumber: string;
  JumboNo: string;
  SaleOrderStatus: string;
  SaleOrderCode: string;
  GrainName: string;
  GrainCode: string;
  ArticleName: string;
  Grade: string;
  Quantity: number | null;
  Roll: number | null;
  ColorName: string;
  CustomerName: string;
}
export class ProductStockSummaryExportModel {
  SerialNo: number;
  SaleOrderNumber: string;
  SaleOrderCode: string;
  GrainName: string;
  GrainCode: string;
  ColorName: string;
  ArticleName: string;
  CustomerName: string;
  Grade: string;
  Roll: number | null;
  Quantity: number | null;
  Status: string;
}

export class PurchaseOrderReportModel {
  SerialNo: number = 0;
  Poid: number = 0;
  Ponumber: string = '';
  SupplierId: number = 0;
  SupplierName: string = '';
  SupplierEmail: string = '';
  DeliveryDate: string = '';
  DeliveryTermId: number = 0;
  DeliveryTerm: string = '';
  PaymentTermId: number = 0;
  PaymentTerm: string = '';
  TransportId: number = 0;
  TransportCompanyName: any
  ProductName: string = '';
  PototalAmount: string = '';
  PototalTax: number = 0;
  Pograndtotal: string = '';
  PocreationDate: string = '';
  Grn: string = '';
  Reference: string = '';
  AddedDate: string = '';
  AddedBy!: UserModel;
  ActionPersonName: string = '';
  ApprovedPersonName: string = '';
  ActionPersonEmail: string = '';
  ApprovedPersonEmail: string = '';
  ActionBy!: UserModel;
  Status: string = '';
  IsPocomplete: boolean = false;
  IsInvoiceAttached: boolean = false;
  Remarks: string = '';
  ContactPersonUserId: number = 0;
  PurchaseOrderProduct: PurchaseOrderProductReportModel[] = [];
  Demand: DemandModel[] = [];
  FabricStock: KnittingDivisionStockModel[] = [];
  UserDetails!: UserModel;
  ApprovedBy!: UserModel;
  ApprovedDate: string = "";
  POHighValue: string = "";
  DepartmentId: number = 0;
  DepartmentName: string = '';
  POType: string = '';
}
export class PurchaseOrderProductReportModel {
  PoproductId: number = 0;
  Poid: number = 0;
  ProductId: number = 0;
  ProductName: string = '';
  Unit: string = '';
  Rate: number = 0;
  ProductQuality: string = '';
  ImportCode: string = '';
  Quantity: number = 0;
  RecievedQuantity: number = 0;
  Amount: number = 0;
  Grade: string = '';
  Currency: string = '';
  Igst: number = 0;
  IgstTotal: number = 0;
  Total: number = 0;
  StockRecievedbyUserDetails!: UserModel;
}
export class TopSellingProducts {
  ChartData: TopSellingProductsChartData[];
  TableData: TopSellingProductsTableData[];
}
export class TopSellingProductsChartData {
  XAxis: string;
  YAxis: number;
}
export class TopSellingProductsTableData {
  SerialNo: number;
  FormulationCode: string = '';
  TotalOrderQty: number = 0;
  Thickness: string = '';
}
export class ReceivedOrdersModel {
  ChartData: ReceivedOrdersChartDataModel[];
  TableData: ReceivedOrdersTableDataModel[];
}
export class ReceivedOrdersTableDataModel {
  SerialNo: number;
  ReceivedDate: string;
  TotalOrderQty: number;
}
export class ReceivedOrdersChartDataModel {
  XAxis: number;
  YAxis: number;
}
export class SearchParamsReportModel {
  DataType: string = '';
  DateFilterType: string = '';
  DateFrom: string = '';
  DateTo: string = '';
  WorkShift: string = '';
  ProductionLineNo: number = 0;
}
export class SearchParamsStockConsumptionReportModel {
  DateFilterType: string = '';
  ConsumedDateFrom: string;
  ConsumedDateTo: string;
  ProductType: string = '';
  Unit: string = null;
  ProductCategoryId: number = 0;
  ProductCategory: string = '';
  ProductFirstSubCategoryId: number = 0;
  ProductFirstSubCategory: string = '';
  ProductSecSubCategoryId: number = 0;
  ProductSecSubCategory: string = '';
  ProductId: number = 0;
  BatchNo: string = '';
  SaleOrderNo: string = '';
  SupplierId: number = 0;
  StoreId: number = 0;
  RackId: number = 0;
  Consumedby: string = '';
}
export class ConsumeStockProductReportModel {
  SerialNo: number = 0;
  ConsumeStockProductId: number = 0;
  RackId?: number = 0;
  RackName: string = ''
  RackCode: string = ''
  StoreId?: number = 0;
  BatchNo: string = '';
  PricePerUnit: string = '';
  StoreName: string = ''
  StoreCode: string = ''
  ProductId: number = 0;
  ProductName: string = ''
  ProductCode: string = ''
  Quantity: number = 0;
  SCQuantity?: number = 0;
  Purpose: string = '';
  Unit: string = ''
  ConsumedDate?: string = ''
  AddedBy: string = ''
  AddedDate: string = ''
  SaleOrderId?: number = 0;
  SaleOrderNumber: string = ''
  StockId?: number = 0;
  StockProductId?: number = 0;
  IsDamaged?: boolean = false;
}
export class PasteConsumptionReportModel {
  ProductionCompletedDate: string;
  SaleOrderNo: string;
  WorkPlanNo: string;
  ItemName: string;
  PCode: string;
  Grain: string;
  Colours: string;
  Width: string;
  Fabric: string;
  Thick: string;
  Finish: string;
  OrderQty: number = 0;
  ExtraProduction: number = 0;
  LMConstant: number = 0;
  MFGQty: number = 0;
  FabricGsm: number = 0;
  PartyName: string;
  Remarks: string;
  ProductionCompletionRemarks: string;
  Status: string;
  StdPreSkinGsm: number = 0;
  StdSkinGsm: number = 0;
  StdFoamGsm: number = 0;
  StdAdhesiveGsm: number = 0;
  AfterProductionPreSkinGsm: number = 0;
  AfterProductionSkinGsm: number = 0;
  AfterProductionFoamGsm: number = 0
  AfterProductionAdhesiveGsm: number = 0;
  ExtraPreSkinGsm: number = 0;
  ExtraSkinGsm: number = 0;
  ExtraFoamGsm: number = 0;
  ExtraAdhesiveGsm: number = 0;
  StdPreSkinWeight: number = 0;
  StdSkinWeight: number = 0;
  StdFoamWeight: number = 0;
  StdAdhesiveWeight: number = 0;
  RemainingPreSkinWeight: number = 0;
  RemainingSkinWeight: number = 0;
  RemainingFoamWeight: number = 0;
  RemainingAdhesiveWeight: number = 0;
  FinalPreSkinWeight: number = 0;
  FinalSkinWeight: number = 0;
  FinalFoamWeight: number = 0;
  FinalAdhesiveWeight: number = 0;
  SaleOrderCompleteProduction: SaleOrderCompleteProductionModel;
}
export class PasteConsumptionReportExportModel {
  ProductionCompletedDate: string;
  SaleOrderNo: string;
  WorkPlanNo: string;
  ItemName: string;
  PCode: string;
  Grain: string;
  Colours: string;
  Width: string;
  Fabric: string;
  Thick: string;
  Finish: string;
  OrderQty: number = 0;
  MFGQty: number = 0;
  FabricGsm: number = 0;
  PartyName: string;
  Remarks: string;
  ProductionCompletionRemarks: string;
  Status: string;
  StdPreSkinGsm: number = 0;
  StdSkinGsm: number = 0;
  StdFoamGsm: number = 0;
  StdAdhesiveGsm: number = 0;
  ExtraProduction: number = 0;
  LMConstant: number = 0;
  AfterProductionPreSkinGsm: number = 0;
  AfterProductionSkinGsm: number = 0;
  AfterProductionFoamGsm: number = 0
  AfterProductionAdhesiveGsm: number = 0;
  ExtraPreSkinGsm: number = 0;
  ExtraSkinGsm: number = 0;
  ExtraFoamGsm: number = 0;
  ExtraAdhesiveGsm: number = 0;
  StdPreSkinWeight: number = 0;
  StdSkinWeight: number = 0;
  StdFoamWeight: number = 0;
  StdAdhesiveWeight: number = 0;
  RemainingPreSkinWeight: number = 0;
  RemainingSkinWeight: number = 0;
  RemainingFoamWeight: number = 0;
  RemainingAdhesiveWeight: number = 0;
  FinalPreSkinWeight: number = 0;
  FinalSkinWeight: number = 0;
  FinalFoamWeight: number = 0;
  FinalAdhesiveWeight: number = 0;
}

export class MfgHearbeatReport {
  ChartData: MfgHeartbeatChartData[];
  TableData: MfgHeartbeatTableData[];
}
export class MfgHeartbeatChartData {
  startTime: string;
  endTime: string;
  status: string;
}
export class MfgHeartbeatTableData {
  SerialNo: number;
  JumboRollStartTime: Date;
  JumboRollEndTime: Date;
  JumboRollDate: string;
  AddedDate: string;
  JumboNo: string;
  SaleOrderId: number;
  SaleOrderNumber: string;
  ProductionLineNo: number;
  WorkShift: string;
  ShiftSupervisorName: string;
  JumboRolQty: number;
}