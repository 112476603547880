<div class="card card-flush h-xl-100">
  <div class="card-body pt-5">
    <nz-page-header [nzGhost]="false">
      <nz-page-header-title>WhatsApp Template List</nz-page-header-title>
      <nz-page-header-subtitle>Manage your WhatsApp template here</nz-page-header-subtitle>
      <nz-page-header-extra>
        <button nz-button nzType="primary" (click)="showModal()" *ngIf="permission.Add">
          Add New
        </button>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-divider></nz-divider>

    <div style="padding: 8px; float: right">
      <input type="text" style="width: 250px; margin-right: 8px" nz-input placeholder="Search Provider Name"
        [(ngModel)]="searchValue" (keydown)="onKeydown($event)" (ngModelChange)="ValidateText()" />

      <button nz-button nzSize="small" nzType="primary" (click)="search()" style="margin-right: 8px">
        Search
      </button>

      <button nz-button nzSize="small" (click)="reset()">Reset</button>
      <button nz-button nzSize="small" (click)="export()">
        <i nz-icon nzType="export" nzTheme="outline"></i> Export
      </button>
    </div>

    <nz-table [nzPageSize]="100" style="width: 100%" nzBordered [nzScroll]="{ x: '1200px', y: '515px' }" nzSize="small"
      #basicTable [nzData]="this.WhatsAppTemplateList" [nzLoading]="isTableLoading">
      <thead>
        <tr>
          <th nzWidth="30px" nzLeft></th>
          <th nzWidth="50px">S.No</th>
          <th nzWidth="120px">Provider Name</th>
          <th nzWidth="80px">Template ID</th>
          <th nzWidth="100px">Template Name</th>
          <th nzWidth="250px">Template Description</th>
          <th nzWidth="100px">Added By</th>
          <th nzWidth="100px">Added Date</th>
          <th nzWidth="100px" nzRight style="text-align: center">Action</th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-data [ngForOf]="basicTable.data">
          <tr>
            <td nzLeft [(nzExpand)]="data.ExpandRecord"></td>
            <td>{{ data.SerialNumber }}</td>
            <td>{{ data.ProviderName }}</td>
            <td>{{ data.ProviderTemplateId }}</td>
            <td>{{ data.ProviderTemplateName }}</td>
            <td>{{ data.ProviderTemplateDescription }}</td>
            <td>{{ data.AddedBy }}</td>
            <td>{{ data.AddedDate | DatetimeConverter }}</td>
            <td nzRight style="text-align: center">
              <div>
                <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
                  class="btn btn-sm btn-light-primary" style="line-height:0">
                  Action
                  <span nz-icon nzType="down"></span>
                </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li style="width: 120px;" nz-menu-item (click)="ShowAddWhatsAppTemplateParameter(data)">Add
                      Parameters</li>
                    <li style="width: 120px;" nz-menu-item *ngIf="permission.Edit" (click)="editTemplate(data)">Edit
                    </li>
                    <li style="width: 120px;" nz-menu-item *ngIf="permission.Delete" (click)="handleDelete(data)">
                      Delete</li>
                    <li style="width: 120px;" nz-menu-item *ngIf="permission.Add"
                      (click)="copyTemplateWithParameters(data)">Create Copy with Parameters</li>
                  </ul>
                </nz-dropdown-menu>
              </div>
            </td>
          </tr>
          <tr [nzExpand]="data.ExpandRecord">
            <td nzLeft></td>
            <table nzSize="small" style="width: 80%; background-color: white;" nzBordered>
              <thead class="ant-table-thead ng-star-inserted">
                <tr class="ant-table-row ng-star-inserted">
                  <th style="background: aliceblue; width: 20%; padding-left: 20px;" class="ant-table-cell">Parameter
                    Name
                  </th>
                  <th style="background: aliceblue;  width: 20%;" class="ant-table-cell">Parameter Type</th>
                  <th style="background: aliceblue;  width: 20%;" class="ant-table-cell">Default Value</th>
                  <th style="background: aliceblue;  width: 20%;" class="ant-table-cell">Is Required</th>
                  <th style="background: aliceblue;  width: 20%;" class="ant-table-cell">Action</th>

                </tr>
              </thead>
              <tbody class="ant-table-tbody ng-star-inserted">

                <tr *ngFor="let parameter of data.Parameters" class="ant-table-row ng-star-inserted">
                  <td class="ant-table-cell" style="padding-left: 20px;">{{parameter.ParameterName}}</td>
                  <td class="ant-table-cell">{{parameter.ParameterType}}</td>
                  <td class="ant-table-cell">{{parameter.DefaultValue}}</td>
                  <td class="ant-table-cell">{{parameter.IsRequired ? 'Yes' : 'No'}}</td>
                  <td>

                    <button class="btn btn-sm btn-light-primary" (click)="OpenEditPopParameter(parameter)">Edit</button>
                    &nbsp;
                    <button class="btn btn-sm btn-light-danger" [disabled]="!this.permission.Delete"
                      (click)="handleDeleteParameter(parameter)">Delete</button>

                  </td>
                </tr>
              </tbody>
            </table>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>

    <nz-modal [(nzVisible)]="isVisible" [nzStyle]="{ top: '20px' }" [nzTitle]="modalTitle" [nzContent]="modalContent"
      [nzFooter]="modalFooter" (nzOnCancel)="handleCancel()">
      <ng-template #modalTitle>{{ WhatsAppTemplateTitle }}</ng-template>
      <ng-template #modalContent>
        <form nz-form [formGroup]="validateForm">
          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Provider Name</nz-form-label>
            <nz-form-control [nzSpan]="12">
              <nz-select name="select-error1" nzShowSearch formControlName="ProviderName"
                nzPlaceHolder="Select Provider" nzErrorTip="Select Provider">
                <nz-option *ngFor="let provider of ProviderNameList" [nzValue]="provider.name"
                  [nzLabel]="provider.name"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Provider Template Id</nz-form-label>
            <nz-form-control [nzSpan]="12">
              <input nz-input formControlName="ProviderTemplateId" 
                placeholder="Enter unique template ID"
                [disabled]="isShownEdit" nzErrorTip="Enter unique template ID" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Template Name</nz-form-label>
            <nz-form-control [nzSpan]="12">
              <input nz-input formControlName="ProviderTemplateName" 
                placeholder="Enter unique template name" nzErrorTip="Enter unique template name" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="10" nzRequired>Template Description</nz-form-label>
            <nz-form-control [nzSpan]="12">
              <nz-textarea-count [nzMaxCharacterCount]="500">
                <textarea nz-input formControlName="ProviderTemplateDescription" 
                placeholder="Enter template description"
                [nzAutosize]="{ minRows: 3, maxRows: 5 }" nzErrorTip="Enter template description"></textarea>
              </nz-textarea-count>
            </nz-form-control>
          </nz-form-item>
        </form>
      </ng-template>

      <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="handleCancel()">
          Cancel
        </button>
        <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isLoading"
          *ngIf="permission.Add || permission.Edit">
          Save WhatsApp Template
        </button>
      </ng-template>
    </nz-modal>

    <nz-modal [nzWidth]="500" [nzStyle]="{ top: '20px' }" [(nzVisible)]="isVisibleParameter"
      [nzTitle]="modalTitleParameter" [nzContent]="modalContentParameter" [nzFooter]=null
      (nzOnCancel)="handleCancelParameter()">
      <ng-template #modalTitleParameter>{{PopUpTitleParameter}}</ng-template>

      <ng-template #modalContentParameter>
        <form nz-form [formGroup]="validateFormParameter" (ngSubmit)="handleOkParameter()">
          <nz-form-item>

            <nz-form-label [nzSpan]="8" nzRequired>Parameter Name</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Parameter Name">
              <input nz-input #ParameterName formControlName="ParameterName" name="ParameterName" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Parameter Type</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Parameter Type">
              <input nz-input formControlName="ParameterType" name="ParameterType" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Default Value</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Default Value">
              <input nz-input formControlName="DefaultValue" name="DefaultValue" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="8" nzRequired>Is Required</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="Enter valid Is Required">
              <nz-switch formControlName="IsRequired" name="IsRequired" />
            </nz-form-control>
          </nz-form-item>
          <div class="text-center">
            <button nz-button nzType="primary" [nzLoading]="isLoadingParameter">Save Parameter</button>
          </div>

        </form>
      </ng-template>


    </nz-modal>
  </div>
</div>