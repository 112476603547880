import { UserModel } from "./UserModel";

export class MeasureUnitModel {
  Id: number = 0;
  Unit: string = '';
  UnitName: string = '';
  UnitType: string = '';
  AddedDate: string = '';
  AddedBy: string = '';
}

export class PackagingTypeModel {
  PackagingTypeId: number = 0;
  PackagingTypeName: string = '';
  PackagingTypeCode: string = '';
  AddedBy: UserModel = new UserModel();
  AddedDate: string = '';
}